import { useEffect, useRef, useState } from 'react';
import default_img from '../assets/images/image.png';
import $ from 'jquery';
import Tagify from '../assets/js/Tagify/tagify';
import '../assets/js/bootstrap-datepicker.min.js';
import Api from "../Api";
import * as constants from '../Constants'
import { Link } from 'react-router-dom';

export default function Dashboard(params) {

	window.document.title = params.title;
	let deleteModal = useRef(null);

	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [itemEdit, setItemEdit] = useState(false);
	let [button_spinner, setButtonSpinner] = useState(false);
	let [item_detail, setItemDetail] = useState({});
	let [txtItemTags, setItemTags] = useState(null);
	let [checkedItem, setCheckedItem] = useState([]);
	let [selectAll, setSelectAll] = useState(false);
	

	let [is_extra_filter, setIsExtraFilter] = useState(false);

	let [txtDataTableSearchById, settxtDataTableSearchById] = useState({});
	let [txtDataTableSearchByName, settxtDataTableSearchByName] = useState({});
	let [txtDataTableSearchByMinPrice, settxtDataTableSearchByMinPrice] = useState({});
	let [txtDataTableSearchByMaxPrice, settxtDataTableSearchByMaxPrice] = useState({});
	let [txtDataTableSearchByTag, settxtDataTableSearchByTag] = useState(null);
	let [drpDataTableSearchByCountry, setdrpDataTableSearchByCountry] = useState();
	let [drpDataTableSearchByDuplicateSKU, setdrpDataTableSearchByDuplicateSKU] = useState();
	let [drpDataTableSearchByAvlVariation, setdrpDataTableSearchByAvlVariation] = useState();
	let [drpDataTableSearchByOrder, setdrpDataTableSearchByOrder] = useState();
	let [drpDataTableSortBy, setdrpDataTableSortBy] = useState();

	const [current_user, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem('current_user')));
	let[tagify, setTagify] = useState(null);
	
	const tagOptions = {
		delimiters: null,
		editTags: false,
		enforceWhitelist : true,
		//whitelist: current_user.admin_tags,
		dropdown: {
			enabled: 0,         // show the dropdown immediately on focus
			maxItems: 8,         // place the dropdown near the typed text
			closeOnSelect: false,     // keep the dropdown open after selecting a suggestion
			highlightFirst: true,
			searchKeys: ["value"],
			width: '100%',
		},
		templates: {
			dropdownItem: function (tagData) {
				return `<li ${this.getAttributes(tagData)} class='tagify__dropdown__item list-group-item ${tagData.class ? tagData.class : ""}' >
					<span>${tagData.value}</span>
				</div>`
			}
		},
	}
	useEffect(() => {
		var input = document.querySelector('#txtDataTableSearchByTag');
		tagify = new Tagify(input, tagOptions);

		if (current_user != null) {
			tagify.whitelist = current_user.user_tags;
			setTagify(tagify);
		}
	}, [current_user, sessionStorage])

	const prevPage = () => {
		if (cur_page > 1) {
			setCurPage(--cur_page)
		} else {
			setCurPage(1);
		}
	}
	const nextPage = () => {
		if (cur_page < max_page) {
			setCurPage(++cur_page)
		} else {
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() : constants.DEFAULT_RECORD_LIMIT;

		let data = {
			start: (cur_page - 1) * page_length,
			length: page_length,
			search_by_id: txtDataTableSearchById,
			search_by_name: txtDataTableSearchByName,
			search_by_min_price: txtDataTableSearchByMinPrice,
			search_by_max_price: txtDataTableSearchByMaxPrice,
			search_by_tag: txtDataTableSearchByTag,
			search_by_country: drpDataTableSearchByCountry,
			search_by_d_sku: drpDataTableSearchByDuplicateSKU,
			search_by_avl_variation: drpDataTableSearchByAvlVariation,
			search_by_order: drpDataTableSearchByOrder,
			sort_by: drpDataTableSortBy,
		}
		Api('dashboard', constants.prepareData('POST', data)).then((res) => {

			if (res.status !== 401) {
				if (res.hasOwnProperty('list')) {
					setResult(res);
					setMaxPage(res.list.total_pages);
					if(res.is_import == true){
						import_ebay_product()
					}
					setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			} else {
				localStorage.removeItem('auth');
				sessionStorage.removeItem('current_user');
				setIsLoggedIn(false);
			}
		});
	}


	useEffect(() => {
		loadTable();
	}, [cur_page, txtDataTableSearchById, txtDataTableSearchByName, txtDataTableSearchByMinPrice, txtDataTableSearchByMaxPrice, txtDataTableSearchByTag, drpDataTableSearchByCountry, drpDataTableSearchByDuplicateSKU, drpDataTableSearchByAvlVariation,drpDataTableSearchByOrder, drpDataTableSortBy])

	const saveItemFomo = (body, btnSpinner, dialogRef) => {

		body.itemId = item_detail.item_id;
		//body.itemVariationId = item_detail.variation_id;
		btnSpinner(true);

		Api('save-fomo', constants.prepareData('POST', body)).then(resp => {

			if (resp.status == 200) {
				dialogRef.current.click();
				btnSpinner(false);
				if (resp.hasOwnProperty('reloadTable')) {
					resp.reloadTable();
				}
				loadTable();
			}
		})

	}

	const saveItemTags = (body, tagSpinner, dialogRef) => {
		body.itemId = item_detail.item_id;

		Api('dashboard/save-tags', constants.prepareData('POST', body)).then(res => {
			
			tagSpinner(false);

			if(res.status !== 401){
				if(res.status == 200){
					current_user.user_tags = res.user_tags;
					tagify.whitelist = res.user_tags;
					setTagify(tagify);
					setCurrentUser(current_user);
					sessionStorage.setItem('current_user', JSON.stringify(current_user));
					dialogRef.current.click();
					loadTable();
				}
			}else{
				localStorage.removeItem('auth');
				sessionStorage.removeItem('current_user');
				setIsLoggedIn(false);
			}

		});
	}

	const import_ebay_product = () => {
		
		Api('dashboard/import-from-ebay', constants.prepareData('POST')).then(resp => {
			//importSpinner(false);
			console.log(resp);
			if (resp.status == 200) {
				let msg = '<div class="alert alert-info"><span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Importing items from your eBay account. Please wait while sync in progress..</div>'

				$(constants.BG_PROCESS).html(msg);
				checkImportProgress(resp.task);
				
			}
		})
	}

	const checkImportProgress = (task_id) => {
		let body = {
			task_id
		};
		Api('dashboard/check-import-progress', constants.prepareData('POST', body)).then((res) => {
			if(res.status == 200 && res.in_progress){
				checkImportProgress(task_id);
			}else{
				loadTable();
				$(constants.BG_PROCESS).html('');
			}
		})
	}


	const saveEditItems = (body, btnSpinner, dialogRef) => {
		body.itemId = item_detail.item_id;
		Api('save-item-details', constants.prepareData('POST', body)).then(resp => {
			btnSpinner(false);
			if (resp.status == 200) {
				dialogRef.current.click();
				loadTable();
			}
		})
	}
	const restoreSelectedItems = (btnSpinner, dialogRef) => {
		btnSpinner(true);
		let data = {
			itemId : checkedItem,
			isAvailable : 1,
		}
		Api("item/restore-items", constants.prepareData("POST", data)).then((res) => {
			btnSpinner(false);
			if (res.status == 200) {
				dialogRef.current.click();
				loadTable()
			}

		})

	}
	const overrideSelectedItems = (btnSpinner, dialogRef) => {
		let data = {
			itemId : checkedItem,
			isAvailable : 2,
		}
		Api("item/restore-items", constants.prepareData("POST", data)).then((res) => {
			btnSpinner(false);
			if (res.status == 200) {
				dialogRef.current.click();
				loadTable()
			}

		})
	}

	const deleteItem = (btnSpinner, dialogRef) => {
		//console.log("delete Items",item_detail);
		btnSpinner(true);
		let data = {
			itemId: item_detail.item_id,
		}
		Api("item/delete", constants.prepareData("POST", data)).then((res) => {
			btnSpinner(false);
			if (res.status == 200) {
				dialogRef.current.click();
				loadTable()
			}

		})
	}

	const handleSelectAll = (event) => {
		setCheckedItem([]);
		setSelectAll(event.target.checked);
		if (event.target.checked) {
			if (result.hasOwnProperty('list') && result.list.total_records > 0) {
				result.list.data.map((item, i) => {
					checkedItem[i] = item.item_id
				})

				setCheckedItem(checkedItem);
			}
		}
	}

	const handleItemCheckbox = (event) => {
		if (event.target.checked) {
			if (checkedItem.findIndex((data) => data == event.target.value) === -1) {
				setCheckedItem([...checkedItem, event.target.value])
			}
		} else {
			setCheckedItem((checkedItem) =>
				checkedItem.filter((item) => item !== event.target.value)
			)
		}
	}

	const fillTableRow = (item, key) => {
		//console.log("items", item);

		var add_tag = <>Add Tag</>
		var tag_icon = <i className="bi bi-plus-circle-fill text-primary pe-1"></i>
		if (item.item_tag != null && item.item_tag != '') {
			tag_icon = <i className="bi bi-tag-fill text-primary pe-1"></i>
			//console.log(cust.tags);
			add_tag = item.tags.map((val, i) =>
				<>
					<span className="badge bg-light-default mx-1">{val.value}</span>
				</>
			)
		}

		return <>
			<tr key={key}>
				<td>
					<constants.CheckBox
						value={item.item_id}
						className="form-check-input"
						handleEvent={handleItemCheckbox}
						isChecked={(checkedItem.findIndex((data) => data == item.item_id) != -1)}
					/>

				</td>
				<td>
					<div className="row">
						<div className="col-12 col-md-4">
							<div className='item'>
								{item.total_variations > 0 && <div className='image-badge bg-info fw-small'>Variation: {item.total_variations}</div>}
								{
									<constants.Image src={item.item_image} />
								}
							</div>
						</div>
						<div className="col-12 col-md-8">
							<div className="item-title">{item.item_title}</div>
							<div>
								<span className="text-secondary">ID: </span>
								<span className="sub-promo">{item.item_id}</span>
							</div>
							<div>
								<span className="radius-10 badge bg-promo sub-promo"><i className="bi bot-global"></i>costco.com.au</span>
							</div>

							<a className='btn btn-sm' data-bs-target="#itemDetails" data-bs-toggle="modal" onClick={() => setItemDetail(item)}><span className="item-detail mx-1" >View Details </span></a>
							{
								item.total_variations > 0 &&
								<Link to={"/item-variations/" + item.item_id} className='btn btn-sm'><span className="item-variation text-info mx-1">View Variation</span></Link>
							}
						</div>
					</div>
				</td>
				<td>
					<button className="btn px-0" data-bs-toggle="modal" data-bs-target="#userItemTags" onClick={() => {
						setItemTags(JSON.stringify(item.tags));
						setItemDetail(item)
					}}>
						{tag_icon} {add_tag}
					</button>
				</td>
				<td className="">
					<div className="item-price">{
						//item.item_cost + "/ " + item.item_price
						item.item_price
					}</div>
					<div className="m-1 radius-10 badge bg-light-success d-none">
						<i className="bi bot-dollar-circle text-success"></i>
						<span className="align-text-top">Profit: $0.00</span>
					</div>
					{
						item.in_stock == 0 &&
						<div className="ms-2">
							<span className="radius-10 badge bg-light-danger font-12 ">Out of stock</span>
						</div>
					}
					{
						item.is_available == 0 &&
						<div className="ms-2">
							<span className="radius-10 badge bg-warning font-12 ">on Hold</span>
						</div>
					}
				</td>
				<td>{item.fomo_qty}</td>
				<td>{item.item_qty}</td>
				<td width="15%">
					<button className={`btn m-1 badge font-12 d-grid ${(item.fomo_qty > 0) ? "bg-light-warning" : "bg-light text-secondary"}`} data-bs-toggle="modal" data-bs-target="#fomoItem" onClick={() => {
						setItemDetail(item);
					}}>FOMO</button>
					<div className='d-none'>
						<button className="btn m-1 badge bg-light-purple font-12 d-grid">Item Cost and Repricing</button>
						<button className="btn m-1 badge bg-light-green font-12 d-grid">Strategy</button>
						<button className="btn m-1 badge bg-light-default font-12 d-grid">Competitors</button>
						<button className="btn m-1 badge bg-light-primary font-12 d-grid">Item Source</button>
					</div>
				</td>
				<td>
					<div className="btn-group btn-group-sm me-2" role="group">
						<button type="button" className="btn btn-outline-primary" data-bs-target="#itemDetails" data-bs-toggle="modal" onClick={() => {
							setItemDetail(item)
							setItemEdit(true);
						}
						}><i className='bi bot-edit-fill text-primary'></i></button>
						<button type="button" className="btn btn-outline-primary dropdown-toggle hideDefaultDrpArrow" data-bs-toggle="dropdown" aria-expanded="false"><i className='bi bi-caret-down-fill text-primary'></i></button>
						<ul className=" item-action dropdown-menu" aria-labelledby="btnGroupDrop1">
							{/*
							<li><a className="dropdown-item text-primary" href="#">Log</a></li>
							<li className="dropdown-divider"></li>
							<li><a className="dropdown-item text-primary" href="#">Reprice Now</a></li>
							<li className="dropdown-divider"></li>
							<li><a className="dropdown-item text-primary" href="#">Problems</a></li>
							<li className="dropdown-divider"></li>
							*/
							}
							<li><a className="dropdown-item text-primary" href="#" data-bs-toggle="modal" data-bs-target="#delete_item" onClick={() => { setItemDetail(item) }}>Delete</a></li>
						</ul>
					</div>
				</td>
			</tr>
		</>
	}

	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-12 col-md-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" placeholder="Search by Id" id="txtDataTableSearchById" name="txtDataTableSearchById" onKeyUp={
								(e) => settxtDataTableSearchById(e.target.value)} />
							<div className="form-control-icon">
								<i className="bi bi-search f-color"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" placeholder="Search by Name" id="txtDataTableSearchByName" name="txtDataTableSearchByName" onKeyUp={
								(e) => settxtDataTableSearchByName(e.target.value)} />
							<div className="form-control-icon">
								<i className="bi bi-search f-color"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="d-none d-md-block col-md-2"></div>
				<div className="col-6 col-md-2">
					<div className="d-grid d-none">
						<Link to={"/add-listing"} className="btn btn-primary" ><i className="bi bi-plus-circle-fill pe-1"></i> Add Listing</Link>
					</div>
				</div>
				<div className="col-6 col-md-2">
					<div className="d-grid">
						<Link to={"/orders"} className="btn btn-info"> Orders</Link>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 col-md-8">
					<h5 className="page-title my-2">My Items</h5>
				</div>
				<div className="col-6 col-md-2 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-sort text-info"></i>
							</div>
							<select className="form-select" aria-label="Sort by" defaultValue={''} id="drpDataTableSortBy" name="drpDataTableSortBy" onChange={(e) => setdrpDataTableSortBy(e.target.value)}>
								<option value="" hidden>Sort by</option>
								<option value="">Default</option>
								{/*
									<option value="1">Last repriced</option>
									<option value="3">Profit % high to low</option>
									<option value="2">Profit % low to high</option>
									<option value="4">No. competitors low to high</option>
									<option value="5">No. competitors high to low</option>
								*/
								}
								<option value="6">eBay price low to high</option>
								<option value="7">eBay price high to low</option>
								{
									//<option value="8">Source product unavailable</option>
								}
								<option value="9">Sold 30 days low to high</option>
								<option value="10">Sold 30 days high to low</option>
								<option value="11">Recently added</option>
								{
									//<option value="12">Out of stock date</option>
									//<option value="13">Out of stock date, recent first</option>
								}
							</select>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-2">
					<div className="form-group has-icon-left d-grid">
						<button className={`btn ${is_extra_filter ? 'btn-info-light' : 'btn-light'} border d-flex justify-content-between`}
							onClick={() =>
								setIsExtraFilter(!is_extra_filter)
							}>
							<i className="bi bot-filter text-info"></i>
							<span aria-hidden="true" className="text-start">Filter By</span>
							<i className={`bi ${is_extra_filter ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} text-end`
							}></i>
						</button>
					</div>
				</div>
			</div>
			<div className={(is_extra_filter) ? "row" : "row d-none"}>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-reprice-status text-info"></i>
							</div>
							<select className="form-select" aria-label="Reprice Status">
								<option value="" hidden>Reprice Status</option>
								<option value="1">Any</option>
								<option value="2">Reprice active</option>
								<option value="3">Reprice inactive</option>
							</select>

						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-competitor text-info"></i>
							</div>
							<select className="form-select" aria-label="Competitor">
								<option value="" hidden>Competitors</option>
								<option value="">Any</option>
								<option value="1">No competitors</option>
								<option value="4">5 or less competitors</option>
								<option value="2">1 or more competitors</option>
								<option value="3">3 or more competitors</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-position text-info"></i>
							</div>
							<select className="form-select" aria-label="Position">
								<option value="" hidden>Position</option>
								<option value="">Any</option>
								<option value="1">#1 lowest price</option>
								<option value="2">#1 best match</option>
								<option value="3">Not #1 lowest price</option>
								<option value="4">Not #1 best match</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-sold-30-days text-info"></i>
							</div>
							<select className="form-select" aria-label="Sold 30 days" defaultValue={''} id="drpDataTableSearchByOrder" name="drpDataTableSearchByOrder" onChange={(e) => setdrpDataTableSearchByOrder(e.target.value)}>
								<option value="" hidden>Sold 30 days</option>
								<option value="">Any</option>
								<option value="1">1 or more sold</option>
								<option value="2">None sold</option>
								{/*
								<option value="3">More than or equal to competitors</option>
								<option value="4">Less than competitors</option>
								*/
								}
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-1">
					<input type="text" className="form-control bg-textbox" placeholder="$0" id="txtDataTableSearchByMinPrice" name="txtDataTableSearchByMinPrice" onKeyUp={(e) => settxtDataTableSearchByMinPrice(e.target.value)} />
				</div>
				<div className="col-12 col-md-1">
					<input type="text" className="form-control bg-textbox" placeholder="$99,9999" id="txtDataTableSearchByMaxPrice" name="txtDataTableSearchByMaxPrice" onKeyUp={(e) => settxtDataTableSearchByMaxPrice(e.target.value)} />
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-source text-info"></i>
							</div>
							<select className="form-select" aria-label="Source">
								<option value="" hidden>Source</option>
								<option value="">Any</option>
								<option value="1">Available</option>
								<option value="2">Unavailable</option>
								<option value="3">Has source product</option>
								<option value="4">No source product</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-source-type text-info"></i>
							</div>
							<select className="form-select" aria-label="Source type">
								<option value="" hidden>Source type</option>
								<option value="">Any</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-reprice-type text-info"></i>
							</div>
							<select className="form-select" aria-label="Reprice type">
								<option value="" hidden>Reprice type</option>
								<option value="">Any</option>
								<option value="0">Rules</option>
								<option value="1">Elastic</option>
								<option value="2">Source product based</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid d-none">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-strategy text-info"></i>
							</div>
							<select className="form-select" aria-label="Strategy">
								<option value="" hidden>Strategy</option>
								<option value="">Any</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-ebay-site text-info"></i>
							</div>
							<select className="form-select" defaultValue={''} id="drpDataTableSearchByCountry" name="drpDataTableSearchByCountry" onChange={(e) => setdrpDataTableSearchByCountry(e.target.value)}>
								<option value="" hidden>Ebay site</option>
								<option value="">Any</option>
								<option value="AU">Australia</option>
								<option value="US">US</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-duplicate-sku text-info"></i>
							</div>
							<select className="form-select" defaultValue={''} id="drpDataTableSearchByDuplicateSKU" name="drpDataTableSearchByDuplicateSKU" onChange={(e) => setdrpDataTableSearchByDuplicateSKU(e.target.value)}>
								<option value="" hidden>Duplicate SKUs</option>
								<option value="">Any</option>
								<option value="1">No duplicate SKU</option>
								<option value="2">Has duplicate SKU</option>
							</select>
						</div>
					</div>
				</div>
				{/*
				<div className="col-12 col-md-2 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-problem text-info"></i>
							</div>
							<select className="form-select" aria-label="Problem">
								<option value="" hidden>Problem</option>
								<option value="">Any</option>
								<option value="2">No Problems</option>
								<option value="1">Has Problems</option>
                            </select>
						</div>
					</div>
				</div>
				*/
				}
				<div className="col-12 col-md-4 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative searchPanel">
							<input type="text" className="form-control bg-textbox" placeholder="Search by tag" id="txtDataTableSearchByTag" name="txtDataTableSearchByTag" value={txtDataTableSearchByTag} onChange={(e) => settxtDataTableSearchByTag(e.target.value)} />
							<div className="form-control-icon">
								<i className="bi bot-tag text-info"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-item-with-variation text-info"></i>
							</div>
							<select className="form-select" defaultValue={''} id="drpDataTableSearchByAvlVariation" name="drpDataTableSearchByAvlVariation" onChange={(e) => setdrpDataTableSearchByAvlVariation(e.target.value)}>
								<option value="" hidden>Items with variations</option>
								<option value="">Any</option>
								<option value="1">No Variation</option>
								<option value="2">Has Variation</option>
							</select>
						</div>
					</div>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="text-secondary">Total Items</span>
							<div className="">
								<span className="revenue-amount mb-2">{(result.hasOwnProperty('list')) ? result.list.total_items : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="text-secondary">Out of stock</span>
							<div className="">
								<span className="text-danger revenue-amount mb-2">{(result.hasOwnProperty('list')) ? result.list.total_outof_stock : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="text-secondary">Price Alert</span>
							<div className="">
								<span className="revenue-amount mb-2">{(result.hasOwnProperty('price_alert')) ? result.list.price_alert : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="text-secondary">Updated</span>
							<div className="">
								<span className="revenue-amount mb-2">05.12.2022</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={(checkedItem.length > 0) ? "row" : "row d-none"} >
				<div className="col-md-3 mb-2 d-grid  d-none">
					<button className='btn btn-outline-primary'>Enable Repricing</button>
				</div>
				<div className="col-md-3 mb-2 d-grid  d-none">
					<button className='btn btn-outline-danger'>Disable Repricing</button>
				</div>
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target="#restore_available_item">Restore to available</button>
				</div>
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-danger'  data-bs-toggle="modal" data-bs-target="#override_unavailable_item">Override to unavailable</button>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 table-responsive mb-2'>
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th><input type="checkbox" name="chkAllitem" value="1" className="form-check-input" onChange={handleSelectAll} /></th>
								<th width="30%">Item</th>
								<th>Tag</th>
								<th>Price</th>
								<th width="8%">Quantity</th>
								<th width="8%">Stock</th>
								<th>Actions</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								spinner === true ?
									<><tr><td colSpan={8} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
									:
									(spinner === false && (result.hasOwnProperty('list') && result.list.total_records > 0)) ?
										result.list.data.map((sub, idx) =>
											fillTableRow(sub, idx)
										)
										: <><tr><td colSpan={8} className="text-center"> No data found</td></tr></>
							}
						</tbody>
					</table>
				</div>
				{
					result.hasOwnProperty('list') &&
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
				}
			</div>
		</section>

		{/*
			constants.confirmDialog({
				id: "delete_item",
				title : "Delete Item", 
				action : "Delete",
				msg : "Are you sure to delete this item?",
				callback : deleteItem,
				cancelConfirm : deleteModal,
				color : "danger"
			})
		*/}

		<constants.ConfirmDialog
			id="delete_item"
			title="Delete Item"
			action="Delete"
			msg="Are you sure to delete this item?"
			callback={deleteItem}
			color="danger"
		/>

		
		<constants.ConfirmDialog
			id="restore_available_item"
			title="Restore to available"
			action="Confirm"
			msg="Your items will no longer be on hold and will be brought back into stock where possible."
			callback={restoreSelectedItems}
			color="success"
		/>

		<constants.ConfirmDialog
			id="override_unavailable_item"
			title="Override to unavailable"
			action="Confirm"
			msg="Your items will be set to 0 quantity available. Your items will be put on hold but retain all settings in Price Guard. No further repricing or updates will occur until you set these items back to available."
			callback={overrideSelectedItems}
			color="success"
		/>

		<constants.ItemDetails
			id="itemDetails"
			isEdit={itemEdit}
			setIsEdit={setItemEdit}
			action={saveEditItems}
			defaultValue={item_detail}
			setChanged={setItemDetail}
		/>

		<constants.FOMO
			id="fomoItem"
			action={saveItemFomo}
			defaultValue={item_detail}
			setChanged={setItemDetail}

		/>

		<constants.AddTag
			id="userItemTags"
			action={saveItemTags}
			defaultValue={txtItemTags}
			setChanged={setItemTags}
			label={item_detail?.item_title}
			tags={current_user?.user_tags}
		/>
	</>
}