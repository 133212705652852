import $ from 'jquery';
import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Api from '../Api';
import 'jquery-validation';
import * as constants from '../Constants';


export default function Login(params){

	window.document.title = params.title;
	
	const [isSpinner, setSpinner] = useState(false);
	const [remember, setRemember] = useState(false);
	const navigate = useNavigate();
	
	$(function(){
		$("#form_signin").validate({
			rules: {
				txtUsername : {
					required: true,
					empty : true
				},
				txtPassword : {
					required: true,
					empty : true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	});

	const adminLogin = () => {
		if($("#form_signin").valid()){
			setSpinner(true);
			let data = {
				username : $('#txtUsername').val(),
				password : $('#txtPassword').val(),
				remember
			}
			Api('auth', constants.prepareData('POST', data)).then((res) => {
				if(res.hasOwnProperty('access_token')){
					let auth = {
						token : res.access_token,
						expires : res.expires,
						type : res.type
					};
					localStorage.setItem("admin_auth", JSON.stringify(auth));
					navigate('./dashboard')
				}
				setSpinner(false);
			});
		}
	};
	const button = () => {
		if(isSpinner){
			return <button type="button" className="btn btn-primary px-5 py-1" disabled>
				<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
				<span className="action">Log In</span>
			</button>
		}else{
			return <button type="button" className="btn btn-primary px-5 py-1" onClick={ adminLogin }>
				<span className="action">Log In</span>
			</button>
		}
	}

	return <main className="form-signin">
		<form method="post" acceptCharset="utf-8" id="form_signin">
				<div className="text-center my-5">
					<img src={params.logo} alt="BotPricer" width="200px"/>
				</div>
				<div className="card">
					<div className="card-header position-relative">
						<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
							<h5 className="page-title">Log in</h5>
						</div>	
					</div>
					<div className="card-body px-4 py-5">
						<div className="mb-3">
							<label htmlFor="txtUsername" className="form-label">Username or Email</label>
							<input type="text" className="form-control" id="txtUsername" name="txtUsername" placeholder="Username"/>
						</div>
						<div className="mb-3">
							<label htmlFor="txtPassword" className="form-label">Password</label>
							<input type="password" className="form-control" id="txtPassword" name="txtPassword" placeholder="Password"/>
						</div>

						<div className="mb-3">
							<input className="form-check-input" type="checkbox" value="1" id="chkRemember" name="chkRemember" onChange={() => setRemember(!remember)}/>
							<label className="form-check-label" htmlFor="chkRemember">
								Remember me
							</label>
						</div>
						{ button() }
					</div>
				</div>
			</form>
	</main>
}
