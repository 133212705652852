import $ from 'jquery';
import 'jquery-validation';
import {useEffect, useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Api from '../Api';
import * as constants from "../Constants";

export default function NewPassword(params){
	
	window.document.title = params.title;

	
	let [searchParams] = useSearchParams();

	let navigate = useNavigate();
	
	let [button_spinner, setButtonSpinner] = useState(false);

	let [txtNewPassword, settxtNewPassword] = useState('');
	let [txtConfirmPassword, settxtConfirmPassword] = useState('');
	
	const saveNewPassword = (e) => {
		if($("#form_set_new_password").valid()){
			setButtonSpinner(true)
			let data = {
				txtNewPassword, txtConfirmPassword, hdnKey : searchParams.get('key')
			}

			Api("reset-password/save", constants.prepareData("POST", data)).then((res) => {
				setButtonSpinner(false);
				if(res.success){
					settxtNewPassword('');
					settxtConfirmPassword('');
					navigate('/signin');
				}
			});
		}
	}

	$(() => {
		$("#form_set_new_password").validate({
			rules: {
				txtNewPassword : {
					required : true,
					minlength:8,
					pwcheck:true,
				},
				txtConfirmPassword : {
					required: true,
					minlength:8,
					equalTo : '#txtNewPassword'
				}
			},
		
			errorElement : 'div',
			errorPlacement: function(error, element) {
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},

			errorClass: "is-invalid",
		})
	});
	// this function is to accept Strong password
	$.validator.addMethod("pwcheck", function(value) {
		return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*\?])(?=.{8,})/.test(value)
		},
		function(param, element) {
			return 'Use minimum 8 characters with at least one number, one uppercase, one lowercase and only special character from [!@#$%^&*?].';
		}
	);

	return <>
		<div className="form-signup my-5">
			<div className="card card-border">
				<div className="card-header card-title position-relative">
					<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
						<h5 className="page-title">&nbsp; Set New Password</h5>
					</div>
				</div>
				<div className="card-body card-bg-gray px-4 py-5">					
					<form autoComplete="off" id="form_set_new_password" method="post" acceptCharset="utf-8">
						<div className="mb-3">
							<input type="hidden" name="hdnKey" id="hdnKey" value={searchParams.get('key')}/>
							<label htmlFor="txtNewPassword" className="form-label">New Password</label>
							<input type="password" className="form-control " id="txtNewPassword" name="txtNewPassword" placeholder="New Password"  defaultValue={txtNewPassword} onChange={(e)=>settxtNewPassword(e.target.value)} />
						</div>
						<div className="mb-3">
							<label htmlFor="txtConfirmPassword" className="form-label">Confirm Password</label>
							<input type="password" className="form-control " id="txtConfirmPassword" name="txtConfirmPassword" placeholder="Confirm Password" defaultValue={txtConfirmPassword} onChange={(e)=>settxtConfirmPassword(e.target.value)} />
						</div>
						<button type="button" className="btn btn-primary px-5 py-1" onClick={(e) => saveNewPassword(e)} disabled={button_spinner ? "disabled" : ""}>
							{
								button_spinner && 
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
							}
							Save
						</button>
					</form>
				</div>
			</div>
		</div>
	</>
}