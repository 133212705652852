//css
import './assets/css/bootstrap.min.css';
import './assets/css/bootstrap-icons.css';
import './assets/css/bootstrap-datepicker3.css';

//import './assets/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { useLocation, useNavigate } from 'react-router-dom';

import UserApp from './UserApp';
import FrontApp from './FrontApp';
import AdminApp from './Admin/AdminApp';
import { useEffect, useState } from 'react';
import $ from 'jquery'
import Api from './Api';
import { prepareData, STATIC_CONTENT } from './Constants';


function App() {
	const location = useLocation();
	const navigate = useNavigate();
	
	const menu = [
		'/pricing', '/pricing/success', '/pricing/cancel', '/signup', '/signin', '/ebay/verify-oauth', '/register', '/forgot-password', '/reset-password', '/contact'
	];
	
	let [site_logo, setSiteLogo] = useState('/assets/images/logo/site_logo.png');
	let [preferences, setPreferences] = useState(localStorage.hasOwnProperty('preferences') ? JSON.parse(localStorage.getItem('preferences')) : null);
	useEffect(() => {

		sitePreference()
		
	}, [preferences])

	const sitePreference = () => {
		
		if(preferences == null){

			let data = {preference : true};

			Api('', prepareData("POST", data)).then(resp => {
				localStorage.setItem('preferences', JSON.stringify(resp.preference));
				setPreferences(resp.preference);
			})
		}else{
			setSiteLogo( STATIC_CONTENT + preferences.logo);
		}
	}
	
	useEffect(() => {
		$(window).on('storage', function(e){

			let location = window.location.pathname;
			let pattern = /^\/admin\//g;
			
			if(e.key == 'preferences'){
				
				setPreferences(localStorage.hasOwnProperty('preferences') ? JSON.parse(localStorage.getItem('preferences')) : null)
				sitePreference();
			}

			if(pattern.test(location)){

				if(e.key === "admin_auth"){
					if(!localStorage.hasOwnProperty('admin_auth')){
						sessionStorage.removeItem("current_admin");
						navigate("/admin/")
					}else{
						navigate("/admin/dashboard");
					}
				}

			}else{

				if(e.key === 'auth'){
					if(!localStorage.hasOwnProperty('auth')){
						sessionStorage.removeItem("current_user");
						navigate("/signin");
					}else{
						navigate("/dashboard");
					}
				}
			}
			
			$(window).trigger('resize');
			$(window).on('resize', function(){
				//console.log("Height - " + $(this).height())
				//console.log("width - " + $(this).width())
				if($(this).width() < 992){
					$("#sidebar").removeClass('active')
					$('.nav-tabs li').width('auto')
				}else{
					$("#sidebar").addClass('active');
					$('.nav-tabs li').width(100 / $(".nav-tabs li").length + '%');;
				}
			})
		});

	}, [localStorage, sessionStorage]);

	function loadView(){
		let current_path = location.pathname;
		let is_front = current_path === '/' ? true : false;
		let is_admin = false;
		menu.map((path, idx) => {
			if(path === current_path ){
				is_front = true;
			}
			else if(is_front === false && current_path.indexOf(path) === 0 ){
				is_front = true;
			}
		});

		if(!is_front){
			let current_path = location.pathname;
			let pattern = /^\/admin\//g;
			is_admin = pattern.test(current_path);
		}

		if(!is_front){
			if(is_admin){
				return <AdminApp logo={site_logo} />
			}else{
				return <UserApp logo={site_logo}/>
			}
		}else {
			return <FrontApp logo={site_logo}/>
		}
	}
	
	
	return <>
		{ loadView() }
	</>
	
}

export default App;
