import { Route, Routes } from 'react-router-dom';
import Login from './Login';
import Sidebar from './Common/Sidebar';
import Dashboard from './Pages/Dashboard';
import Subscriptions from './Pages/Subscriptions';
import SystemError from './Pages/SystemError';
import $ from 'jquery';
import Customers from './Pages/Customers';
import MasterSettings from './Pages/MasterSettings';
import UserSettings from './Pages/UserSettings';
import Staff from './Pages/Staff';
import Api from '../Api';
import * as constants from '../Constants';
import { useEffect } from 'react';



function AdminApp(params) {
	
	let is_loggedin = false;
	
	if(localStorage.hasOwnProperty('admin_auth')){
		let admin_auth = JSON.parse(localStorage.getItem('admin_auth'));
		let time_now = Math.round((new Date()).getTime()/1000);
		if(admin_auth.expires > time_now){
			is_loggedin = true;
		}
	}
	
	function toggleButton(event){
		$("#sidebar").toggleClass('active');
		$(".burger-btn").toggleClass('click');
	}

	/*
	useEffect(() => {
		if(is_loggedin){
			Api("current-user", constants.prepareData("POST")).then((res) => {
				var current_admin = {}
				current_admin.user_tags = res.user_tags;
				current_admin.preference = res.preference;
				sessionStorage.setItem("current_admin", JSON.stringify(current_admin))
			})
		}else{
			sessionStorage.removeItem("current_admin")
		}
	}, [is_loggedin]);
	*/
	if(!is_loggedin){
		return <>
			<Routes>
				<Route path="/admin" element={<Login logo={params.logo} title={"RepriceBot Admin "}/>}>
					<Route path="*" element={<Login logo={params.logo} title={"RepriceBot Admin"}/>} />
				</Route>
			</Routes>
		</>
	}else{
		return <>
			<Sidebar logo={params.logo} />
			<div id="main">
				<header className="mb-5 d-block d-lg-none">
					<button className="burger-btn btn btn-sm btn-light" onClick={(e) => toggleButton(e)}>
						<i className="bi bi-list fs-3"></i>
					</button>
				</header>
				<Routes>
					<Route path="/admin">
						<Route path="customers" element={<Customers title={"Manage Customers"}/>} />
						<Route path="subscriptions" element={<Subscriptions title={"Manage Subscriptions"}/>} />
						<Route path="system-error" element={<SystemError title={"Manage System Errors"}/>} />
						<Route path="master-settings" element={<MasterSettings title={"Admin Master Settings"}/>} />
						<Route path="user-settings" element={<UserSettings title={"Admin Preference"}/>} />
						<Route path="dashboard" element={<Dashboard title={"Admin Dashboard"} />} />
						<Route path="staff" element={<Staff title={"Staff"}/>} />
						<Route path="" element={<Dashboard title={"Admin Dashboard"} />} />
					</Route>
				</Routes>
			</div>
		</>
	}
	
}

export default AdminApp;
