import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Api from "../Api";
import Notify from "../Notify";
import * as constants from '../Constants';


export default function EbayVerify(){
	var navigate = useNavigate();
	const [searchParams] = useSearchParams();
	
	useEffect(()=> {
		
		let code = searchParams.get('code');
		let expires_in = searchParams.get('expires_in');
	
		if(code != null && expires_in > 0){
			let data = {code, expires_in}
			
			Api('ebay/verify', constants.prepareData('POST', data)).then((res) => {
				if(res.status == 200){
					if(res.hasOwnProperty('is_allow_register')){
						sessionStorage.setItem("is_allow_register", JSON.stringify(res.is_allow_register));
						sessionStorage.setItem("ebay_auth", JSON.stringify(res.oauth));
						navigate('/register');
					}
				}else if(res.hasOwnProperty('already_linked')){
					navigate('/signin');
				} else{
					navigate('/signup')
					//navigate('/signin')
				}
			});
		}else{
			Notify("Invalid Access");
			navigate("/signup");
		}
	}, []);
}