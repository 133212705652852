import {Link, useNavigate} from 'react-router-dom';
import $ from 'jquery';
import 'jquery-validation';
import Api from '../Api';
import {useState, useEffect} from 'react';
import * as constants from '../Constants'

export default function Signin(params){
	
	window.document.title = params.title;

	const navigate = useNavigate();

	useEffect(() => {
		const auth = localStorage.hasOwnProperty('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
		if(auth != null && auth.hasOwnProperty('token')){
			navigate('/dashboard')
		}
	}, [navigate])
	$(function(){
		$("#form_signin").validate({
			rules: {
				txtUsername : {
					required: true,
					empty : true
				},
				txtPassword : {
					required: true,
					empty : true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	});


	const [isSpinner, setSpinner] = useState(false);
	const userLogin = () => {
		if($("#form_signin").valid()){
			setSpinner(true);
			var remember = $("#chkRemember").is(":checked") ? true : false;
			let data = {
				username : $('#txtUsername').val(),
				password : $('#txtPassword').val(),
				remember,
			}
			Api('auth', constants.prepareData('POST', data)).then((res) => {
				if(res.hasOwnProperty('access_token')){
					let auth = {
						token : res.access_token,
						expires : res.expires,
						type : res.type
					};
					localStorage.setItem("auth", JSON.stringify(auth));
					//localStorage.setItem("current_user", JSON.stringify(res.user));
					navigate('/dashboard')
				}
				setSpinner(false);
			});
		}
	};
	
	const button = () => {
		if(isSpinner){
			return <button type="button" className="btn btn-primary px-5 py-1" disabled>
				<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
				<span className="action">Log In</span>
			</button>
		}else{
			return <button type="button" className="btn btn-primary px-5 py-1" onClick={ userLogin }>
				<span className="action">Log In</span>
			</button>
		}
	}

	return <>
		<div className="form-signup my-5">
			<div className="card card-border">
				<div className="card-header card-title position-relative">
					<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
						<h5 className="page-title"><Link to={-1}><i className="bi bot-back"></i></Link>&nbsp; Log In</h5>
					</div>
				</div>
				<div className="card-body card-bg-gray px-4 py-5">
					<div className="text-14">Don't have an account?</div>
					<div className="mb-4">
						<Link className="card-link" to={"/signup"}>Create an account</Link>
					</div>
					<form autoComplete="off" id="form_signin" method="post" acceptCharset="utf-8">
						<div className="mb-3">
							<label htmlFor="txtUsername" className="form-label">Username or Email</label>
							<input type="text" className="form-control" id="txtUsername" name="txtUsername" placeholder="Username"/>
						</div>
						<div className="mb-3">
							<label htmlFor="txtPassword" className="form-label">Password</label>
							<Link className="card-link float-end" tabIndex={'-1'} to={'/forgot-password'}>Forgot Password?</Link>
							<input type="password" className="form-control" id="txtPassword" name="txtPassword" placeholder="Password"/>
						</div>
						<div className="mb-3">
							<input className="form-check-input" type="checkbox" value="1" id="chkRemember" name="chkRemember"/>
							<label className="form-check-label" htmlFor="chkRemember"> Remember me</label>
						</div>
						{ button() }
					</form>
				</div>
			</div>
		</div>
	</>
}