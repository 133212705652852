import { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import Api from './Api';
import Tagify from './assets/js/Tagify/tagify';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import default_img from './assets/images/image.png';

/*export const API_URL = 'https://dev.repricebot.com/api/v1/';
export const ADMIN_API_URL = API_URL + 'admin/';
export const STATIC_CONTENT = 'https://dev.repricebot.com/api/';*/
export const API_URL = 'https://botadmin.tocusinfotech.com/v1/';
export const ADMIN_API_URL = API_URL + 'admin/';
export const STATIC_CONTENT = 'https://botadmin.tocusinfotech.com/';
export const SITE_PATH = window.location.protocol + "//" + window.location.host;
export const ADMIN_PATH = "/admin/" ;
export const DEFAULT_RECORD_LIMIT = 10 ;
export const RECORD_LENGTH = [10, 25, 50, 100];
export const BG_PROCESS = '#process_bg';

export const formatDate = (str_date, format = 'dd-mm-yyyy') => {
	
	let input = document.createElement("input");
	var d = new Date(str_date + ' UTC ');
	let dt = $(input).datepicker({ format });
	dt.datepicker('update', d);
	
	return dt.datepicker('getFormattedDate')
}

export const Resize = () => {
		
	$(window).trigger('resize');
	
	$(window).on('resize', function(){
		//console.log("Height - " + $(window).height())
		//console.log("width - " + $(this).width())
		if($(this).width() < 992){
			$("#sidebar").removeClass('active')
			$('.nav-tabs li').width('auto')
		}else{
			$("#sidebar").addClass('active');
			$('.nav-tabs li').width( (100 / $(".nav-tabs li").length) + '%');
		}
	})
	
}

export const prepareData = (method = 'GET', data = null, is_multipart = false) => {
	
	let current_path = window.location.pathname;
	let pattern = /^\/admin\//g;
	let headers = {}
	var access = null;

	if(pattern.test(current_path)){
		var access = JSON.parse(localStorage.getItem('admin_auth'));
	}else{
		var access = JSON.parse(localStorage.getItem('auth'));
	}
	if(is_multipart){
		/*headers = {
			'Content-Type' : 'multipart/form-data'
		}*/
	}else{
		headers = {
			'Content-Type' : 'application/json'
		}
	}
	
	if(access != null && access.hasOwnProperty('token')){
		headers['X-BOT-ACCESS-TOKEN'] =  access.token
	}
	let requestOptions = {method, headers};
	if(data != null){
		requestOptions.body = (is_multipart) ? data : JSON.stringify(data)
	}
	return requestOptions;
}

export const pagination = (page, cur_page, nextPage, prevPage, loadTable,tableName='') => {
	
	return <>
		
			<div className="col-12 col-md-2 pt-2">
				Showing { page.start } - {page.end} out of {page.total_records}
			</div>
			<div className="col-6 col-md-8 text-center">
				<button className={(cur_page <= 1) ? "btn btn-sm disabled" : "btn btn-sm"} onClick={prevPage}><i className="bi bot-page-previous text-primary mx-2"></i></button>
					{ (page.start == 0) ? 0 : cur_page }
				<button className={(cur_page === page.total_pages) ? "btn btn-sm disabled" : "btn btn-sm"} onClick={nextPage}><i className="bi bot-page-next text-primary mx-2"></i></button>
			</div>
			<div className="col-6 col-md-2 pt-2 text-end">
				<select id="page_length" className={'page_length_'+tableName} onChange={loadTable}>
					{
						RECORD_LENGTH.map((len, idx) =>
							<option key={idx} value={len} > {len} per page</option>
						)
					}
				</select>
			</div>
		
	</>
}

export const confirmDialog = (params) => {
	//Are you sure you want to delete this?

	return <>
		<div className="modal fade"  key={params.id} id={params.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={params.title} aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{params.title}</h5>
					</div>
					<div className="modal-body">
						<div className="modal-form">
							<label className="mx-4 mb-5">{params.msg}</label>
						</div>
						<button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-info ms-5 mb-4 px-5" ref={params.cancelConfirm}>Cancel</button>
						{
							<button type="button" className={`btn ${ params.colors == "success" ? 'btn-success' : 'btn-danger'} ms-3 mb-4 px-5`} onClick={() => params.callback()}>{params.action}</button>
						}
					</div>
				</div>
			</div>
		</div>
	</>
}

export const ConfirmDialog = (params) => {
	//Are you sure you want to delete this?
	const [button_spinner, setButtonSpinner] = useState(false);
	const dialogRef = useRef();

	return <>
		<div className="modal fade"  key={params.id} id={params.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={params.title} aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{params.title}</h5>
					</div>
					<div className="modal-body">
						<div className="modal-form">
							<label className="mx-4 mb-5">{params.msg}</label>
						</div>
						<button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-info ms-5 mb-4 px-5" ref={dialogRef}>Cancel</button>
						{
							button_spinner ? 
							<button type="button" className={`btn ${ params.colors == "success" ? 'btn-success' : 'btn-danger'} ms-3 mb-4 px-5 disabled`}>
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								{params.action}
							</button> :
							<button type="button" className={`btn ${ params.colors == "success" ? 'btn-success' : 'btn-danger'} ms-3 mb-4 px-5`} onClick={() => params.callback(setButtonSpinner, dialogRef)}>{params.action}</button>
						}
					</div>
				</div>
			</div>
		</div>
	</>
}

export const ItemDetails = (props) => {

	let [button_spinner, setButtonSpinner] = useState(false);
	const itemEditModal = useRef();

	const resetForm = (form_element = '') => {
		props.setIsEdit(false);
		props.setChanged({})
		//console.log("called",props);
		//this.refs.form.reset();
		//document.getElementById("create-course-form").reset();
		if(form_element.length > 0){
			var validator = $(form_element).validate();
			document.getElementById(form_element.replace(/#/g,'')).reset();
			validator.resetForm();
		}
	}

	
	$(() => {
		$("#form_item_details").validate({
			rules: {
				txtItemTitle : {
					required : true,
				},
				txtItemPrice : {
					required : true,
					number:true
				},
				txtItemStock : {
					required : true,
					number:true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element.parent('div'));
				
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	})
	

	const saveDetails = () => {
		setButtonSpinner(true);
		if($("#form_item_details").valid()){
			let data = {
				txtItemTitle : props.defaultValue.item_title,
				txtItemPrice : props.defaultValue.price,
				txtItemStock : props.defaultValue.item_qty,
			}
			props.action(data, setButtonSpinner, itemEditModal);
		}
	}
	
	return <>
		<div className="modal fade"  key={props.id} id={props.id} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Item Details" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Item Details</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={itemEditModal} onClick={() => resetForm()}><i className="bi bi-x-circle-fill" ></i></button>
					</div>
					<div className="modal-body">
						<form autoComplete="off" id="form_item_details" method="post" acceptCharset="utf-8" onSubmit={(e) => {
							e.preventDefault()
							saveDetails()
						}}>
							<div className="modal-form">
								{
									!props.isEdit && 
									<div className="d-sm float-end">
										<button className='btn' type="button" onClick={() => {
											props.setIsEdit(true);
										}}>
											<i className='bi bot-edit-fill text-primary'></i>
										</button>
									</div>
								}
								<div className='row'>
									<div className="col-3 col-md-3">
										{ <Image src={props.defaultValue?.item_image}/>}
									</div>
									<div className="col-9 col-md-9">
										<div className="item-title">
										{
											props.isEdit ?
											<input type="text" id="txtItemTitle" name="txtItemTitle" className='form-control' onChange={(e) => props.setChanged({...props.defaultValue, item_title: e.target.value})} value={props.defaultValue?.item_title} />
											:
											<strong>{props.defaultValue?.item_title}</strong>
										}
										</div>
										<div className="item-title row mt-2">
											<div className='col-4'>
												<label htmlFor="itemPrice" className='col-form-label'>Current Price: </label> 
											</div>
											<div className="col-5">
											{
												props.isEdit ?
													<input type="text" className="form-control" placeholder="Current Price" id="txtItemPrice" name="txtItemPrice" value={props.defaultValue?.price} onChange={(e) => {
														if(numericInput(e, props.setChanged, true)){
															props.setChanged({...props.defaultValue, price : e.target.value})
														}
													}}/>
												:
													<label className='col-form-label font-bold'>{props.defaultValue?.item_price}</label>
											}
											</div>
										</div>
									</div>
								</div>
								<div className='row mt-3'>
									<div className="col-9 col-md-9">
										<div className="item-title"><strong>Ebay Fees</strong></div>
										<div className="item-title">Percentage Fee: <strong>18.04%</strong></div>
										<div className="item-title">Flat Rate: <strong>$0.30</strong></div>
									</div>
								</div>
								<div className='row mt-3'>
									<div className="col-12 table-responsive tbl-item-details">
										<table className='table table-stripped table-bordered table-responsive'>
											<thead>
												<tr>
													<th width="20%">Cost Price</th>
													<th>Other Cost</th>
													<th>Profit</th>
													<th>SKU</th>
													<th width="15%">Available</th>
													<th>Sold</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>$36</td>
													<td>$36</td>
													<td>$36</td>
													<td>{props.defaultValue.item_sku}</td>
													<td>
													{
														props.isEdit ?
														<input type="text" name="txtItemStock" id="txtItemStock" className='form-control form-control-sm' value={props.defaultValue?.item_qty} onChange={(e) => {
															if(numericInput(e, props.setChanged, true)){
																props.setChanged({...props.defaultValue, item_qty : e.target.value})
															}
														}} />
														: 
														props.defaultValue?.item_qty
													}
													</td>
													<td>0</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<div className='d-grid col-4 mx-3'>
						{
							(props.isEdit) && 
							(
								button_spinner ? 

								<button type="button" className="btn btn-primary disabled">
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Save
								</button>

								: <button type="button" className="btn btn-primary" onClick={() => saveDetails()}>Save</button>
							)
						}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const FOMO = (props) => {
	
	let [button_spinner, setButtonSpinner] = useState(false);
	let fomoRef = useRef();

	const resetForm = (form_element) => {
		//settxtFomoQty('');
		props.setChanged({});
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();
		//setButtonSpinner(false);		
	}
	
	$(() => {
		$("#form_fomo").validate({
			rules: {
				txtFomoQty : {
					required : true,
					number:true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element.parent('div'));
				
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	})
	

	const saveFomo = () => {
		if($("#form_fomo").valid()){
			setButtonSpinner(true);
			let data = {
				txtFomoQty : props.defaultValue.fomo_qty
			}
			props.action(data, setButtonSpinner, fomoRef);
		}
	}
	
	return <>
		<div className="modal fade"  key={props.id} id={props.id} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Item Details" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Fear Of Missing Out</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={fomoRef} onClick={() => resetForm("#form_fomo")}><i className="bi bi-x-circle-fill"></i></button>
					</div>
					<div className="modal-body">
						<form autoComplete="off" id="form_fomo" method="post" acceptCharset="utf-8" onSubmit={(e) => {
							e.preventDefault()
							saveFomo()
						}}>
							<div className="modal-form">
								<div className='row mx-1'>
									<div className="col-12">
										<div className="form-group input-group">
											<span className='input-group-text input-group-span'>Show available on Ebay</span>
											<input type="text" id="txtFomoQty" name="txtFomoQty" className="form-control input-group-control" placeholder="0" defaultValue={''} value={props.defaultValue?.fomo_qty}  onChange={(e) => {
												if(numericInput(e, props.setChanged, true)){
													props.setChanged({...props.defaultValue, fomo_qty : e.target.value})
												}
											}} />
										</div>
										<label className="label-info">If set to 0 FOMO will be off</label>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<div className='d-grid col-4 mx-3'>
							{
								button_spinner ? 
								<button type="button" className="btn btn-primary disabled">
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Save
								</button>
								: 
								<button type="button" className="btn btn-primary " onClick={() => saveFomo()}>Save</button>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

const tagOptions = {
	delimiters	: null,
	editTags : false,
	//whitelist: current_user.admin_tags,
	dropdown : {
		enabled       : 0,         // show the dropdown immediately on focus
		maxItems      : 8,         // place the dropdown near the typed text
		closeOnSelect : false,     // keep the dropdown open after selecting a suggestion
		highlightFirst: true,
		searchKeys: ["value"],
		width : '100%',
	},
	templates : {
		dropdownItem: function(tagData){
			return `<li ${this.getAttributes(tagData)} class='tagify__dropdown__item list-group-item ${tagData.class ? tagData.class : ""}' >
				<span>${tagData.value}</span>
			</div>`
		}
	},
}

export const AddTag = (props) => {
	
	let [button_spinner, setButtonSpinner] = useState(false);
	
	
	const dialogRef = useRef();

	const resetForm = (form_element) => {
		props.setChanged('');
		setButtonSpinner(false);
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();
	}
	
	useEffect(() => {
		var input = document.querySelector("#txtTags");
		var tagify = new Tagify(input, tagOptions);
		
		if(props.tags != undefined){
			tagify.whitelist = props.tags
		}
	}, [props.tags])
	
	const saveTags = () => {
		setButtonSpinner(true);
		let data = {
			txtTags : (props.defaultValue).length > 0 ? JSON.parse(props.defaultValue) : null,
		}
		props.action(data, setButtonSpinner, dialogRef);
	}

	
	return <>
		<div className="modal fade"  key={props.id} id={props.id} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Tags Details" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add tag for {props.data?.label}</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" ref={dialogRef} aria-label="Close" onClick={() => resetForm("#form_user_tags")}><i className="bi bi-x-circle-fill"></i></button>
					</div>
					<div className="modal-body">
						<form autoComplete="off" id="form_user_tags" method="post" acceptCharset="utf-8" onSubmit={(event) => {
							event.preventDefault();
							saveTags()
						}}>
							<div className="modal-form">
								<div className='row mx-1'>
									<div className="col-11">
										<label htmlFor="txtTags" className='col-form-label'>Name your tag</label> 
										<div className="form-group" >
											<input type="text" id="txtTags" name="txtTags" className="form-control input-control" placeholder="Tag's name" value={props.defaultValue || "" } onChange={(e) => props.setChanged(e.target.value)} />
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<div className='d-grid col-4 mx-3'>
							{
								button_spinner ? 
								<button type="button" className="btn btn-primary disabled">
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Add Tag
								</button>
								: 
								<button type="button" className="btn btn-primary " onClick={() => saveTags()}>Add Tag</button>
							} 
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}

export const CheckBox = (props) => {
	return <>
			<input
			{...props}
			key={props.id}
			onChange={props.handleEvent}
			type="checkbox"
			checked={props.isChecked}
			value={props.value}
			id={props.id}
			className={props.className}
		/><label htmlFor={props.id} className="form-check-label">{props?.label}</label>
  </>
}

export const InputDateRange = (props) => {

	const [date_state, setDateState] = useState({
		start: moment().subtract(6, 'days'),
		end: moment(),
	});
	const { start, end } = date_state;
	//props?.setChange({start_date : start.format("YYYY-MM-DD"), end_date : end.format("YYYY-MM-DD")});
	const handleCallback = (start, end) => {
		setDateState({ start, end });
	};
	const label = start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY');
	
	useEffect(() => {
		props.setChange({start_date : start.format("YYYY-MM-DD"), end_date : end.format("YYYY-MM-DD")});
	}, [date_state])

	return <>
		<DateRangePicker
			initialSettings={{
				startDate: start.toDate(),
				endDate: end.toDate(),
				alwaysShowCalendars:true,
				applyButtonClasses : "btn-info",
				showDropdowns : true,
				opens: props?.position,
				ranges: {
					Today: [moment().toDate(), moment().toDate()],
					Yesterday: [
					moment().subtract(1, 'days').toDate(),
					moment().subtract(1, 'days').toDate(),
					],
					'Last 7 Days': [
					moment().subtract(6, 'days').toDate(),
					moment().toDate(),
					],
					'Last 30 Days': [
					moment().subtract(29, 'days').toDate(),
					moment().toDate(),
					],
					'This Month': [
					moment().startOf('month').toDate(),
					moment().endOf('month').toDate(),
					],
					'Last Month': [
					moment().subtract(1, 'month').startOf('month').toDate(),
					moment().subtract(1, 'month').endOf('month').toDate(),
					],
				},
			}}
			onCallback={handleCallback}
		>
			<div id={props.id} className={props.className}>
				<span>{label}</span>
			</div>
		</DateRangePicker>
	</>;
}

export const numericInput = (event, setState, isReturn = false) => {
	const re = /^\d{1,}(\.\d{0,4})?$/;
	
	if(isReturn){
		return (event.target.value === '' || re.test(event.target.value))
	}else if (event.target.value === '' || re.test(event.target.value)) {
		setState(event.target.value);
	}
}

/*export const EditOrder = (props) => {

	let [button_spinner, setButtonSpinner] = useState(false);
	const orderEditModal = useRef();

	console.log("Orders: ", props);
	const resetForm = (form_element = '') => {
		props.setIsEdit(false);
		props.setChanged({})
		
		if(form_element.length > 0){
			var validator = $(form_element).validate();
			document.getElementById(form_element.replace(/#/g,'')).reset();
			validator.resetForm();
		}
	}

	$(() => {
		$("#form_order_details").validate({
			rules: {
				txtItemTitle : {
					required : true,
				},
				txtItemPrice : {
					required : true,
					number:true
				},
				txtItemStock : {
					required : true,
					number:true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element.parent('div'));
				
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	})
	
	const saveOrderDetails = () => {
		setButtonSpinner(true);
		if($("#form_order_details").valid()){
			let data = {
				
			}
			props.action(data, setButtonSpinner, orderEditModal);
		}
	}

	useEffect(() => {
		if(props?.defaultValue.hasOwnProperty('is_order_from_source')){
			setchkIsSource(props.defaultValue.is_order_from_source == 0 ? false : true)
		}else{
			setchkIsSource(false);
		}

	}, [props?.defaultValue])
	
	console.log(chkIsSource);
	return <>
		<div className="modal fade"  key={props.id} id={props.id} data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Order Details" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Order</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={props.closeDialog} onClick={() => resetForm()}><i className="bi bi-x-circle-fill" ></i></button>
					</div>
					<div className="modal-body">
						<form autoComplete="off" id="form_order_details" method="post" acceptCharset="utf-8" onSubmit={(e) => {
							e.preventDefault()
							saveOrderDetails()
						}}>
							<div className="modal-form">
								<div className="container mb-2">
									<div className="form-check">
										<CheckBox id="chkIsSource" className="form-check-input"
										checked={chkIsSource}
										value={props?.defaultValue?.id}
										name="source_product"
										label="Source product ordered"
										onChange={setchkIsSource}
										/>
									</div>
								</div>
								{
									chkIsSource &&
									<div className="container mb-3">
										<label for="txtReference" className="form-label">Order URL or reference</label>
										{
											props.isEdit ?
											<input type="text" className="form-control" id="txtReference" name="txtReference" onChange={(e) => props.setChanged({...props.defaultValue, source_product_ref: e.target.value})} value={props.defaultValue?.source_product_ref}/>
											:
											<input type="text" className="form-control" id="txtReference" name="txtReference" />
										}
										<label className="form-check-label text-info">
											Eg. https://www.url.com.au/gp/.../orderID=123 or 000001012023 <br/>
											NOTE: this is just internal for your own reference.</label>							
									</div>	
								}											
								<div className="container mb-3">
									<div className="form-check">
										<input className="form-check-input" type="checkbox" id="chkShipped" name="chkShipped"/>
										<label className="form-check-label text-info" for="chkShipped">
											Shipped
										</label>
									</div>
								</div>
								{
									chkIsShiped &&	
									<>
									<div className="row">
										<label className="form-check-label text-dark fw-bold">Tracking</label>
										<div className="input-group mb-1">
											<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">Carrier</button>
											<ul className="dropdown-menu">
												<li><a className="dropdown-item" href="#">Action</a></li>
												<li><a className="dropdown-item" href="#">Another action</a></li>
												<li><a className="dropdown-item" href="#">Something else here</a></li>
												<li><a className="dropdown-item" href="#">Separated link</a></li>
											</ul>
											{
												props.isEdit ?
												<input type="text" className="form-control" id="txtTrackingNumber" name="txtTrackingNumber" onChange={(e) => props.setChanged({...props.defaultValue, ebay_tracking_number: e.target.value})} value={props.defaultValue?.ebay_tracking_number}/>
												:
												<input type="text" className="form-control" id="txtTrackingNumber" name="txtTrackingNumber" />
											}
										</div>

									</div>
									<label className="form-check-label text-info">Mark as shipped and save to eBay</label>
									</>
								}							
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<div className='d-grid col-4 mx-3'>
						{
							(props.isEdit) && 
							(
								button_spinner ? 

								<button type="button" className="btn btn-primary disabled">
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Save
								</button>

								: <button type="button" className="btn btn-primary" onClick={() => saveOrderDetails()}>Save</button>
							)
						}
						</div>
					</div>
				</div>
			</div>
		</div>
	</>
}*/

export const Image = (props) => {
	
	return <>
		<img {...props} onError={({ currentTarget }) => {
			currentTarget.src = default_img;
		}} className="img-thumbnail"/>
	</>

}