import {  useEffect, useState,useRef } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import Api from "../Api";
import * as constants from '../Constants';
import eBay_img from '../assets/images/logo/ebay_logo.png'

export default function Preferences(params){
	
	window.document.title = params.title;

	let navigate = useNavigate();
	
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [button_spinner, setButtonSpinner] = useState(false);

	//Account
	let  paymentHistoryModal = useRef(null);
	let [txtAlertEmails, settxtAlertEmails] = useState('');
	let [switchRepriceAlert, setswitchRepriceAlert] = useState(false);
	let [switchCompetitorAlert, setswitchCompetitorAlert] = useState(false);
	let [switchAnotherAlert, setswitchAnotherAlert] = useState(false);
	let [switcheBayManagePayment, setswitcheBayManagePayment] = useState(false);

	//Stock
	let [switchMntQty, setswitchMntQty] = useState(false);
	let [txtRestoreQty, settxtRestoreQty] = useState('');
	let [switchLowQty, setswitchLowQty] = useState(false);
	let [txtLowItemStock, settxtLowItemStock] = useState('');
	let [rdNotification, setrdNotification] = useState();
	let [switchExtOutOfStock, setswitchExtOutOfStock] = useState(false);
	let [switchOffListing, setswitchOffListing] = useState(false);

	//Model Payment History
	let [history_cur_page, setHistoryCurPage] = useState(1);
	let [history_max_page, setHistoryMaxPage] = useState(1);
	let [history_spinner, setHistorySpinner] = useState(false);
	let [history_result, setHistoryResult] = useState({});

	const setDefault = (res) => {
		
		//console.log("result ",res)
		//Account
		settxtAlertEmails(res.user_preference.alert_emails);
		setswitchRepriceAlert(res.user_preference.reprice_alert == 0 ? false: true);
		setswitchCompetitorAlert(res.user_preference.competitor_alert == 0 ? false: true);
		setswitchAnotherAlert(res.user_preference.another_alert == 0 ? false: true);
		setswitcheBayManagePayment(res.user_preference.ebay_manage_payment == 0 ? false: true);

		//Stock
		setswitchMntQty(res.user_preference.stock_maintain_quantity == 0 ? false: true);
		settxtRestoreQty(res.user_preference.restore_avl_quantity);
		setswitchLowQty(res.user_preference.low_quantity_alert == 0 ? false: true);
		settxtLowItemStock(res.user_preference.low_item_stock);
		setrdNotification(res.user_preference.stock_notification);
		setswitchOffListing(res.user_preference.turn_off_listing == 0 ? false: true);
		setswitchExtOutOfStock(res.user_preference.extended_outofstock_alert == 0 ? false: true);
		
	}
	
	const loadUserPreference = () => {
		Api('user-preference', constants.prepareData('POST')).then((res) => {
			if(res.status !== 401){
				if(res.hasOwnProperty('user_preference')){
					setDefault(res)
				}
			}else{
				setIsLoggedIn(false);
			}
		});
	}
	useEffect(() => {
		if(is_logged_in === false){
			navigate('/');
		}
	}, [is_logged_in, navigate])

	 useEffect(() => {
		loadUserPreference();
	}, [])
 

	function setRestoreQty (action){		
		if(action == '+'){
			settxtRestoreQty(++txtRestoreQty);
		}
		if(action == '-'){
			if(txtRestoreQty > 0){
				settxtRestoreQty(--txtRestoreQty);
			}
		}	
	}
	function setLowItemStock(action){
		if(action == '+'){		
			settxtLowItemStock(++txtLowItemStock);
		}
		if(action == '-'){
			if(txtLowItemStock > 0){
				settxtLowItemStock(--txtLowItemStock);
			}
		}
	}
	const addeBayAccount = (e) => {
	}
	const savePreferences = (e) => {
		//if($("#form_preference").valid()){

			setButtonSpinner(true)
			const formData = new FormData();
			//console.log(formData);
			//Account
			formData.append("txtAlertEmails", txtAlertEmails);
			formData.append("switchRepriceAlert", switchRepriceAlert);
			formData.append("switchCompetitorAlert", switchCompetitorAlert);
			formData.append("switchAnotherAlert", switchAnotherAlert);
			formData.append("switcheBayManagePayment", switcheBayManagePayment);

			//stock
			formData.append("switchMntQty", switchMntQty)
			formData.append("txtRestoreQty", txtRestoreQty)
			formData.append("switchLowQty", switchLowQty)
			formData.append("txtLowItemStock", txtLowItemStock)
			formData.append("rdNotification", rdNotification)
			formData.append("switchExtOutOfStock", switchExtOutOfStock)
			formData.append("switchOffListing", switchOffListing)

			Api("user-preference/save", constants.prepareData("POST", formData, true)).then((res) => {
				setButtonSpinner(false);

				if(res.status !== 401){
					if(res.hasOwnProperty('preference')){
						setDefault(res)
						localStorage.removeItem("preferences");
					}
				}else{
					localStorage.removeItem('auth');
					sessionStorage.removeItem('current_user');
					setIsLoggedIn(false);
				}
			});
		//}
	}
	const prevHistoryPage = () =>{
		if(history_cur_page > 1){
			setHistoryCurPage(--history_cur_page)
		}else{ 
			setHistoryCurPage(1);
		}
	}
	const nextHistoryPage = () =>{
		if(history_cur_page < history_max_page){
			setHistoryCurPage(++history_cur_page)
		}else{
			setHistoryCurPage(history_max_page)
		}
	}
	const viewPaymentHistory = () => {
		
			setHistorySpinner(true);
			
			let page_length = $(".page_length_historytable").val() != undefined ?  $(".page_length_historytable").val() :  constants.DEFAULT_RECORD_LIMIT;
			
			let data = {
				start : (history_cur_page-1) * page_length,
				length : page_length,
				
			}
			
			Api("payment-history", constants.prepareData('POST', data)).then((res)=>{
				if(res.status !== 401){
					if(res.hasOwnProperty('list')){
						setHistoryResult(res);
						setHistoryMaxPage(res.list.total_pages);
						//setHistoryCurPage((res.list.total_pages > history_cur_page ) ? res.list.total_pages : history_cur_page);
						setHistorySpinner(false);
					}
				}else{
					localStorage.removeItem('auth');
					sessionStorage.removeItem('current_user');
					setIsLoggedIn(false);
				}
			});
		
	}
	useEffect(() => {
		viewPaymentHistory();
	}, [history_cur_page])
	const fillHistoryTableRow = (hist, idx) => {
		
		return <>
		<tr key={hist.payment_history_id }>
			<td>{history_result.list.start+idx}</td>
			<td>{hist.user_order_id} </td>
			<td>{constants.formatDate(hist.payment_date)} </td>
			<td>{hist.payment_mode} </td>
			<td>{hist.currency}{hist.transaction_fee} </td>
			<td>{hist.currency}{hist.transaction_amount} </td>
			<td>{hist.currency}{hist.subscription_amount} </td>
			<td>{hist.subscription_name} </td>
			<td>{hist.currency}{hist.offer_type > 0 ? hist.discount_amount : '0.00'} </td>
			<td>{hist.currency}{hist.total_amount} </td>
			<td>{hist.paypal_subscription_id} </td>
			<td>{hist.stripe_subscription_id} </td>
			<td>{hist.payment_state} </td>

		</tr>
	</>
}
	/*$(() => {
		$("#form_preference").validate({
			rules: {
				txtRestoreQty : {
					required: true,
					digit:true,
				},
				txtLowItemStock : {
					required: true,
					digit:true,
				},
				
			},
		
			errorElement : 'div',
			errorPlacement: function(error, element) {
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},

			errorClass: "is-invalid",
		})
	});*/
	return <>
	<section className="section">
		<div className="row mb-3">
			<div className="col-12 col-sm-6 col-md-9 col-lg-10">
				<h5 className="page-title">Preferences</h5>
			</div>
			<div className="col-12 col-sm-6 col-md-3 col-lg-2">
				<div className="row">
					<div className="col-6 d-grid">
						<button className="btn btn-light" type="button">Cancel</button>
					</div>
					<div className="col-6 d-grid">
						<button className="btn btn-primary" id="btnSave" type="button" onClick={(e) => savePreferences(e)}>
							{
								button_spinner &&
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
							} Save</button>
					</div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12">
				<ul className="nav nav-tabs text-center" role="tablist">
					<li className="nav-item" role="presentation">
						<a className="nav-link active" id="account-tab" data-bs-toggle="tab" data-bs-target="#account" type="button" role="tab" aria-controls="Account" aria-selected="true">Account</a>
					</li>
					<li className="nav-item" role="presentation">
						<a className="nav-link" id="stock-tab" data-bs-toggle="tab" data-bs-target="#stock" type="button" role="tab" aria-controls="stock" aria-selected="false">Stock</a>
					</li>
				</ul>
				<form autoComplete="off" id="form_preference" method="post" acceptCharset="utf-8">
					<div className="tab-content">
						<div className="tab-pane active" id="account" role="tabpanel" aria-labelledby="account-tab">
							<h5 className="tab-title  mx-5 mt-4">Membership Plan</h5>
							<h5 className="mx-5">Business</h5>
							<div className="modal-form  mx-5">
								<div className="row">
									<div className="col-md-2 d-grid">
										<button type="button" className="btn btn-info" onClick={()=>
										window.location.href="/pricing"
										}>Upgrade </button>
									</div>
									<div className="col-md-2 d-grid">
										<button type="button" className="btn btn-danger">Cancel Subscription</button>
									</div>
									<div className="col-md-2 d-grid">
										<button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#customers_payment_history" onClick={() => {							
							viewPaymentHistory()
						}
					} >Payment History</button>
									</div>
								</div>
							</div>
							<hr/>
							<div className="modal-form mx-5">
								<h5 className="tab-title mt-4">Notifications</h5>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="">
											<label htmlFor="txtAlertEmails" className="col-form-label text-info">Alert Email(s)</label>
											<input className="form-control " type="text" name="txtAlertEmails" id="txtAlertEmails" onChange={(e)=>settxtAlertEmails(e.target.value)} placeholder="Add email addresses" defaultValue={txtAlertEmails}/>
										</div>
										<p className="text-info">Use a coma to separate emails.</p>
									</div>
								</div>
								{									
								/*	
								<h5 className="tab-title mt-4">Enable / disable Notifications for:</h5>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch  ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchRepriceAlert" role="switch" id="switchRepriceAlert" checked={(switchRepriceAlert) ? "checked" : ''} onChange={(e)=>setswitchRepriceAlert(!switchRepriceAlert)} />
											<label htmlFor="switchRepriceAlert" className="col-form-label text-info">Reprice alert</label>
										</div>										
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchCompetitorAlert" role="switch" id="switchCompetitorAlert" checked={(switchCompetitorAlert) ? "checked" : ''} onChange={(e)=>setswitchCompetitorAlert(!switchCompetitorAlert)} />
											<label htmlFor="switchCompetitorAlert" className="col-form-label text-info">Competitor alert</label>
										</div>										
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchAnotherAlert" role="switch" id="switchAnotherAlert" checked={(switchAnotherAlert) ? "checked" : ''} onChange={(e)=>setswitchAnotherAlert(!switchAnotherAlert)} />
											<label htmlFor="switchAnotherAlert" className="col-form-label text-info">Another alert</label>
										</div>										
									</div>
								</div>								
								*/
								}
							</div>
							<hr/>
							<div className="modal-form mx-5">
								<h5 className="tab-title mt-4">Linked accounts</h5>
								<div className="row">
									<div className="col-12 row">
										<div className="col-2">
											<h5 className="">Store Name</h5>
										</div>

										<div className="col-3">
											<img src={eBay_img} className="me-5" alt="..."/>
											<button className="btn d-none"><i className="bi bot-edit-fill text-primary"></i></button>
											<button className="btn d-none"><i className="bi bot-trash text-danger-light"></i></button>
										</div>
									</div>
									<div className="col-4 d-grid d-none">
										<button className="btn btn-primary" id="btnSave" type="button" onClick={addeBayAccount()}>
											<i className="bi bi-plus-circle-fill text-default pe-1"></i>
											Add another account
										</button>
									</div>
								</div>
								

							</div>
							<hr className="d-none"/>
							<div className="modal-form mx-5 d-none">
								<h5 className="tab-title mt-4">Store Name</h5>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch  ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switcheBayManagePayment" role="switch" id="switcheBayManagePayment" checked={(switcheBayManagePayment) ? "checked" : ''} onChange={(e)=>setswitcheBayManagePayment(!switcheBayManagePayment)} />
											<label htmlFor="switcheBayManagePayment" className="col-form-label text-info">eBay managed payments active</label>
										</div>										
									</div>
								</div>
							</div>
						</div>
						<div className="tab-pane" id="stock" role="tabpanel" aria-labelledby="stock-tab">
							<h5 className="tab-title mx-5 mt-4">Store Name</h5>
							<div className="modal-form mx-5">
								<div className="row">
									<div className="col-12 col-md-4">
										<div className="form-check form-switch  ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchMntQty" role="switch" id="switchMntQty" checked={(switchMntQty) ? "checked" : ''} onChange={(e)=>setswitchMntQty(!switchMntQty)} />
											<label htmlFor="switchMntQty" className="col-form-label text-info">Maintain Quantity </label>
										</div>
										<p className="sub-promo">Maintain quantity at the specified level below. When an item sells, quantity will be restored to this value.</p>
            							<p className="text-secondary">NOTE: <span className="sub-promo">If items currently have a quantity higher then this, they will be decreased to this quantity.</span></p>
										
									</div>
								</div>
								<div className="row">
									<div className="col-2">
										<div className="btn-group input-btn " role="group" aria-label="First group">
											<button type="button" className={`btn btn-primary btn-xs ${ switchMntQty ? "" : "disabled"}`} onClick={() => { setRestoreQty("-"); }} ><i className="bi bi-dash"/></button>

											<input type="text" className={`form-control text-center form-control-xs`} disabled={ switchMntQty ? "" : "disabled"} name="txtRestoreQty" id="txtRestoreQty" value={txtRestoreQty} onChange={(e)=> constants.numericInput(e, settxtRestoreQty)}/>

											<button type="button" className={`btn btn-primary btn-xs ${ switchMntQty ? "" : "disabled"}`} onClick={() => { setRestoreQty("+"); }} > <i className="bi bi-plus"/></button>
										</div>
									</div>
										<p className="sub-promo text-info">When the available quantity drops below this value, it will be restored to this value. The default can be "Disabled" and the value set on a per product basis.</p>	
								</div>
							</div>
							<hr/>
							<div className="modal-form mx-5">
								<div className="row">
									<div className="col-12 col-md-6">
										<span>Receive an email alert when stock level is detected or a single email each day?</span>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch  ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchLowQty" role="switch" id="switchLowQty" checked={(switchLowQty) ? "checked" : ''} onChange={(e)=>setswitchLowQty(!switchLowQty)} />
											<label htmlFor="switchLowQty" className="col-form-label text-info">Low quantity alert when there is</label>

											<div className="btn-group input-btn mx-2" role="group" aria-label="First group">
												<button type="button" className={`btn btn-primary btn-xs ${ switchLowQty ? "" : "disabled"}`} onClick={() => { setLowItemStock("-"); }}><i className="bi bi-dash"/></button>

												<input type="text" className="form-control text-center form-control-xs" disabled={ switchLowQty ? "" : "disabled"} name="txtLowItemStock" id="txtLowItemStock" value={txtLowItemStock} onChange={(e)=> constants.numericInput(e, settxtLowItemStock)}/>

												<button type="button" className={`btn btn-primary btn-xs ${ switchLowQty ? "" : "disabled"}`} onClick={() => { setLowItemStock("+"); }}><i className="bi bi-plus"/></button>
											</div>
											<label htmlFor="" className="col-form-label text-info">Items or less in stock</label>
										</div>					
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-12 col-md-6">
										<span>Notification</span>
									</div>
								</div>								
								<div className="row">
									<div className="col-12 col-md-6 mx-2">
										
										<div className="form-check">
											<input className="form-check-input" type="radio" name="rdNotofication" id="rdNotificationASAP" checked={(rdNotification == 1) ? "checked" : ''} onChange={(e)=>setrdNotification(1)} />
											<label htmlFor="rdNotificationASAP" className="form-check-label text-info mt-1">Notify me asap</label>
										</div>
										<div className="form-check">
											<input className="form-check-input" type="radio" name="rdNotofication" id="rdNotificationDaily" checked={(rdNotification == 2) ? "checked" : ''} onChange={(e)=>setrdNotification(2)} />
											<label htmlFor="rdNotificationDaily" className="form-check-label text-info mt-1">Notify me daily</label>
										</div>
									</div>								
								</div>
								<div className="row mt-3">
									<div className="col-12 col-md-6">
										<span>Receive an email alert if an item is out of stock for more than 80 days?</span>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch  ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchExtOutOfStock" role="switch" id="switchExtOutOfStock" checked={(switchExtOutOfStock) ? "checked" : ''} onChange={(e)=>setswitchExtOutOfStock(!switchExtOutOfStock)} />
											<label htmlFor="switchExtOutOfStock" className="col-form-label text-info">Extended out of stock alert</label>
										</div>										
									</div>
								</div>
								<div className="row d-none">
									<div className="col-12 col-md-6">
										<div className="form-check form-switch ps-4 ms-1">
											<input className="form-check-input" type="checkbox" name="switchOffListing" role="switch" id="switchOffListing" checked={(switchOffListing) ? "checked" : ''} onChange={(e)=>setswitchOffListing(!switchOffListing)} />
											<label htmlFor="switchOffListing" className="col-form-label text-info">Turn off Listing</label>
										</div>										
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>
	<div className="modal fade" id="customers_payment_history" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Subscription" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="Subscription">Payment History</h5>
							<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={paymentHistoryModal}><i className="bi bi-x-circle-fill"></i></button>
						</div>
						<div className="modal-body">
						<div className="row m-0">
					<div className="col-12 table-responsive mb-2 p-0">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
								     <th>ID</th>
									<th>User Order ID</th>
									<th>Payment Date</th>
									<th>Payment Mode</th>
									<th>Transaction Fee</th>
									<th>Transaction Amount</th>
									<th>Subscription Amount</th>
									<th>Subscription Name</th>
									<th>Discount Amount</th>
									<th>Total Amount</th>
									
									<th>Paypal Subscription ID</th>
									<th>Stripe Subscription ID</th>
									<th>Payment State</th>
								</tr>
							</thead>
							<tbody>
							{
								history_spinner === true ?
								<><tr><td colSpan={15} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
								:
								(history_spinner === false && (history_result != undefined && history_result.hasOwnProperty('list')) ) ?
								history_result.list.data.map((sub, idx) => 
									fillHistoryTableRow(sub, idx)
								)
								: <><tr><td colSpan={15} className="text-center"> No data found</td></tr></>
							}
								
								
							</tbody>
						</table>
					</div>
					{
						history_result.hasOwnProperty('list') && 
						constants.pagination(history_result.list, history_cur_page, nextHistoryPage, prevHistoryPage, viewPaymentHistory,'historytable')
					}
				</div>
						</div>
					</div>
				</div>
			</div>
	</>
}