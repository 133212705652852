
import {Link, useNavigate} from 'react-router-dom';
import $ from 'jquery';
import * as constants from '../Constants'
import 'jquery-validation';
import Api from '../Api';
import {useEffect, useState} from 'react';

export default function Register(params){
	
	window.document.title = params.title;

	const navigate = useNavigate();
	let [is_allow_register, setIsAllowRegister] = useState(true);

	useEffect(() => {
		if(!is_allow_register){
			navigate('/signup');
		}
	}, [is_allow_register]);

	useEffect(() => {
		if(!sessionStorage.hasOwnProperty('is_allow_register')){
			setIsAllowRegister(sessionStorage.hasOwnProperty('is_allow_register'));
		}
	});

	$(function(){
		$("#form_register").validate({
			onkeyup: false,
			rules: {
				txtUsername : {
					required: true,
					//check_username : true
					remote: {
						url: constants.API_URL + "check-user",
						type: "post",
						dataType:"json",
						data: {
							id: function(){
								return null
							}
						}
					}
				},
				txtEmail : {
					required: true,
					emailcheck:true,
					remote: {
						url: constants.API_URL + "check-email",
						type: "post",
						dataType:"json",
						data: {
							id: null
						}
					}
				},
				txtPassword : {
					required: true,
					minlength:8,
					pwcheck:true,
				},
				txtRepeatPassword : {
					required: true,
					minlength:8,
					equalTo : "#txtPassword",
				},
			},
			messages:{
				txtUsername:{
					remote:"Username already exists."
				},
				txtEmail:{
					remote:"Email ID already exists."
				}
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div'));
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",
		});
	});

	const [isSpinner, setSpinner] = useState(false);

	const button = () => {
		if(isSpinner){
			return <button type="button" className="btn btn-primary pt-2 pb-1 px-4" disabled>
				<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
				<span className="btn-next-icon"><i className="bi bi-person-plus-fill"></i></span>&nbsp;  Create account
			</button>
		}else{
			return <button type="button" className="btn btn-primary pt-2 pb-1 px-4" onClick={userRegister}>
				<span className="btn-next-icon"><i className="bi bi-person-plus-fill"></i></span>&nbsp;  Create account
			</button>
		}
	}
	
	function userRegister(){
		if($("#form_register").valid()){
			setSpinner(true);
			let ebay_auth = JSON.parse(sessionStorage.getItem('ebay_auth'));
			let data = {
				ebay_auth,
				txtUsername : $("#txtUsername").val(),
				txtEmail : $("#txtEmail").val(),
				txtPassword : $("#txtPassword").val(),
				txtRepeatPassword : $("#txtRepeatPassword").val(),
			}
			
			Api('register', constants.prepareData('POST', data)).then((res)=>{
				setSpinner(false);
				
				sessionStorage.removeItem('ebay_auth');
				sessionStorage.removeItem('is_allow_register');

				console.log(res);
				if(res.status === 200){
					if(res.hasOwnProperty('is_registered')){
						if(res.is_registered){
							
							let auth = {
								token : res.data.access_token,
								expires : res.data.expires,
								type : res.data.type
							};
							localStorage.setItem("auth", JSON.stringify(auth));
							localStorage.setItem("current_user", JSON.stringify(res.data.user));
							navigate('/dashboard');
						}
						else{
							navigate('/sigin');
						}
					}
				}else{
					setIsAllowRegister(false);
				}
			})
		}
	}
	return <>
		<div className="form-signup my-5">
			<div className="card card-border">
					<div className="card-header card-title position-relative">
					<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
						<h5 className="page-title"><Link to={-1}><i className="bi bot-back"></i></Link>&nbsp; Create an account</h5>
					</div>
				</div>
				<form autoComplete="off" id="form_register" method="post" acceptCharset="utf-8">
					<div className="card-body card-bg-gray px-4 py-4">
						<div className="mb-3">
							<label htmlFor="txtUsername" className="form-label">Username</label>
							<div className="input-group">
								<span className="input-group-text"><i className="bi bi-person-fill"></i></span>
								<input type="text" className="form-control" id="txtUsername" name="txtUsername" placeholder="Enter username"/>
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="txtEmail" className="form-label">Email</label>
							<div className="input-group">
								<span className="input-group-text"><i className="bi bot-email"></i></span>
								<input type="text" className="form-control" id="txtEmail" name="txtEmail" placeholder="Enter email address"/>
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="txtPassword" className="form-label">Password</label>
							<div className="input-group">
								<span className="input-group-text"><i className="bi bot-password"></i></span>
								<input type="password" className="form-control" id="txtPassword" name="txtPassword" placeholder="Enter password"/>
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="txtRepeatPassword" className="form-label">Repeat password</label>
							<div className="input-group">
								<span className="input-group-text"><i className="bi bot-password"></i></span>
								<input type="password" className="form-control" id="txtRepeatPassword" name="txtRepeatPassword" placeholder="Repeat password"/>
							</div>			
						</div>
						{ button()}
					</div>
				</form>
			</div>
		</div>
	</>
}