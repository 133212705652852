import { Route, Routes, useLocation } from 'react-router-dom';
import './assets/css/custom.css'
import Sidebar from './Users/Common/Sidebar';
import Dashboard from './Users/Dashboard';
import $ from 'jquery';
import UserSettings from './Users/UserSettings';
import Preferences from './Users/Preferences';
import ItemVariation from './Users/ItemVariation';
import CustomerOrders from './Users/CustomerOrders';

export default function UserApp(params){
	function toggleButton(event){
		$("#sidebar").toggleClass('active');
		$(".burger-btn").toggleClass('click');
	}
	
	return <>
		<Sidebar logo={params.logo}/>
		<div id="main">
			<header className="mb-5 d-block d-lg-none">
				<button className="burger-btn btn btn-sm btn-light" onClick={(e) => toggleButton(e)}>
					<i className="bi bi-list fs-3"></i>
				</button>
			</header>
			<section className="section">
				<div className="row mb-3">
					<div className="col-12" id="process_bg">
					</div>
					<div className="col-12" id="notice">
					</div>
				</div>
			</section>
			<Routes>
				<Route path="/dashboard" element={<Dashboard title={"Dashboard"}/>} />
				<Route path="/item-variations/:itemId" element={ <ItemVariation title={"Variation"}/>} />			
				<Route path="/orders" element={<CustomerOrders title={"Customer Orders"}/>} />
				<Route path="/change-password" element={<UserSettings title={"User Settings"}/>} />
				<Route path="/preferences" element={<Preferences title={"Preference"}/>} />
			</Routes>
		</div>
	</>
};