import $ from 'jquery';
import './assets/js/bootstrap-notify.js';

export default function Notify(msg_data, msg_params){
	//notify("Hello");
	if(msg_params === undefined){
		msg_params = {
			"type":"alert-danger",
			"allow_dismiss":false,
			"newest_on_top":true,
			"animate":{
				"enter":"animated fadeInDown",
				"exit":"animated fadeOutUp"
			},
			"placement":{
				"from":"top",
				"align":"right"
			},
			"template":'<div data-notify="container" class="bootstrap-notify-container alert {0} d-flex align-items-center" role="alert" > <span data-notify="icon"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span> </div>'
		}
	}
	$.notify(msg_data, msg_params);
}