import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from "jquery";
import "../../assets/js/bootstrap-datepicker.min.js";
import Api from "../../Api";
import * as constants from "../../Constants";

function Staff(params) {
	window.document.title = params.title;
	let featuresModal = useRef(null),
		subscriptionModal = useRef(null),
		deleteModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [is_edit, setIsEdit] = useState(false);
	let [button_spinner, setButtonSpinner] = useState(false);

	//features form inputs

	const [formData, setFormData] = useState({});

	let navigate = useNavigate();

	let title_badge = [
		"badge bg-light-info",
		"badge bg-light-default",
		"badge bg-light-success",
		"badge bg-light-primary",
		"badge bg-light-warning-white",
	];

	const prevPage = () => {
		if (cur_page > 1) {
			setCurPage(--cur_page);
		} else {
			setCurPage(1);
		}
	};
	const nextPage = () => {
		if (cur_page < max_page) {
			setCurPage(++cur_page);
		} else {
			setCurPage(max_page);
		}
	};

	const editSubscription = (sub = null) => {
		setIsEdit(sub !== null);
		setFormData({
			dispaly_name: sub?.admin_display_name,
			username: sub?.admin_user_name,
			password: sub?.admin_password,
			staff_id: sub?.admin_id,
		});
	};

	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() :	constants.DEFAULT_RECORD_LIMIT;
		
		let data = {
			start: (cur_page - 1) * page_length,
			length: page_length || 10,
		};
		Api("staff", constants.prepareData("POST", data)).then((res) => {
			if (res.status !== 401) {
				if (res.hasOwnProperty("list")) {
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage(
						cur_page > res.list.total_pages && res.list.total_pages !== 0
							? res.list.total_pages
							: cur_page
					);
					setSpinner(false);
				}
			}
		});
	};
	const saveStaff = (event) => {
		event.preventDefault();
		if ($("#form_subscription").valid()) {
			setButtonSpinner(true);

			Api(
				"staff/save",
				constants.prepareData("POST", {
					...formData,
					staff_id: formData?.staff_id || 0,
				})
			).then((res) => {
				setButtonSpinner(false);
				setFormData(null);

				if (res.status !== 401) {
					if (res.hasOwnProperty("list")) {
						setResult(res);
						setMaxPage(res.list.total_pages);
						setCurPage(
							cur_page > res.list.total_pages && res.list.total_pages !== 0
								? res.list.total_pages
								: cur_page
						);
						setSpinner(false);
						resetForm("#form_subscription");
						loadTable();
						$("#add_edit_subscription").hide();
						$(".modal-backdrop").hide();
						$(".modal-open").css({ overflow: "visible" });
					}
				} else {
					localStorage.removeItem("admin_auth");
					setIsLoggedIn(false);
				}
			});
		}
	};

	const deleteSubscription = () => {
		let data = {
			staff_id: formData?.staff_id,
		};
		Api("staff/delete", constants.prepareData("POST", data)).then((res) => {
			setButtonSpinner(false);
			deleteModal.current.click();
			if (res.status !== 401) {
				if (res.hasOwnProperty("list")) {
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage(
						cur_page > res.list.total_pages && res.list.total_pages !== 0
							? res.list.total_pages
							: cur_page
					);
					setSpinner(false);
					loadTable();
				}
			} else {
				localStorage.removeItem("admin_auth");
				setIsLoggedIn(false);
			}
		});
	};

	useEffect(() => {
		if (is_logged_in === false) {
			navigate("/admin/");
		}
	}, [is_logged_in, navigate]);

	function resetForm(form_element) {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g, "")).reset();
		validator.resetForm();
	}

	$(() => {
		$("#txtOfferStartDate")
			.datepicker({
				autoclose: true,
				format: "dd-mm-yyyy",
				startDate: new Date(),
			})
			.on("changeDate", function () {});
		$("#form_subscription").validate({
			rules: {
				txtSubscriptionName: {
					required: true,
				},
				txtSubscriptionPrice: {
					required: true,
					number: true,
				},
				txtOfferPrice: {
					required: function () {
						return $("#chkPromo").is(":checked");
					},
					number: true,
				},
				txtOfferStartDate: {
					required: function () {
						return $("#chkPromo").is(":checked");
					},
				},
				txtOfferEndDate: {
					required: function () {
						return $("#chkPromo").is(":checked");
					},
				},
			},
			errorElement: "div",
			errorPlacement: function (error, element) {
				//console.log(element);
				error.appendTo(element.parent("div"));
				error.addClass("invalid-feedback");
			},
			errorClass: "is-invalid",
		});

		$("#form_features").validate({
			rules: {
				txtAllowedItems: {
					//required: true,
					number: true,
				},
				txtRepriceInterval: {
					//required: true,
					number: true,
				},
			},
			errorElement: "div",
			errorPlacement: function (error, element) {
				//console.log(element);
				error.appendTo(element.parent("div"));
				error.addClass("invalid-feedback");
			},
			errorClass: "is-invalid",
		});
	});

	useEffect(() => {
		loadTable();
	}, [cur_page]);

	const fillTableRow = (sub, idx) => {
		return (
			<>
				<tr key={sub.subscription_id}>
					<td>
						<span className={"radius-10 " + title_badge[idx % 5]}>
							{sub.admin_display_name}{" "}
						</span>
					</td>
					<td>
						<span className={"radius-10 " + title_badge[idx % 5]}>
							{sub.admin_user_name}{" "}
						</span>
					</td>
					<td>
						<button
							className="btn btn-edit"
							data-bs-toggle="modal"
							data-bs-target="#add_edit_subscription"
							onClick={() => {
								setFormData(null);
								editSubscription(sub);
							}}
						>
							<i className="bi bot-edit-fill text-info"></i>
						</button>
						<button
							className="btn btn-delete"
							onClick={() => {
								setFormData({
									dispaly_name: sub?.admin_display_name,
									username: sub?.admin_user_name,
									password: sub?.admin_password,
									staff_id: sub?.admin_id,
								});
							}}
							data-bs-toggle="modal"
							data-bs-target="#delete_subscription"
						>
							<i className="bi bot-trash text-danger-light"></i>
						</button>
					</td>
				</tr>
			</>
		);
	};

	return (
		<>
			<section className="section">
				<div className="row mb-3">
					<div className="col-6 col-md-9 col-lg-10">
						<h5 className="page-title">Manage Staff</h5>
					</div>
					<div className="col-6 col-md-3 col-lg-2 d-grid">
						<button
							className="btn btn-info"
							data-bs-toggle="modal"
							data-bs-target="#add_edit_subscription"
							onClick={() => editSubscription()}
						>
							<i className="bi bi-plus-circle-fill pe-1"></i> Add New Staff
						</button>
					</div>
				</div>
				<div className="row">
					<div className="col-12 table-responsive mb-2">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>Staff Name</th>
									<th>Username</th>
									<th width="200px">Actions</th>
								</tr>
							</thead>
							<tbody>
								{spinner === true ? (
									<>
										<tr>
											<td colSpan={7} className="text-center">
												<span
													className="spinner-border spinner-border-sm me-2"
													role="status"
													aria-hidden="true"
												></span>{" "}
												Loading Data..
											</td>
										</tr>
									</>
								) : spinner === false &&
									result != undefined &&
									result.hasOwnProperty("list") &&
									result?.list?.data?.length ? (
									result?.list?.data?.map((sub, idx) => fillTableRow(sub, idx))
								) : (
									<>
										<tr>
											<td colSpan={7} className="text-center">
												{" "}
												No data found
											</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
					</div>
					{result.hasOwnProperty("list") &&
						constants.pagination(
							result.list,
							cur_page,
							nextPage,
							prevPage,
							loadTable
						)}
				</div>
			</section>
			<div
				className="modal fade"
				id="add_edit_subscription"
				data-bs-backdrop="static"
				data-bs-keyboard="false"
				tabIndex="-1"
				aria-labelledby="Subscription"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">
								{!is_edit ? "Add New Staff" : "Edit Staff"}
							</h5>
							<button
								type="button"
								className="btn"
								data-bs-dismiss="modal"
								aria-label="Close"
								ref={subscriptionModal}
								onClick={() => resetForm("#form_subscription")}
							>
								<i className="bi bi-x-circle-fill"></i>
							</button>
						</div>
						<div className="modal-body">
							<form
								autoComplete="off"
								id="form_subscription"
								method="post"
								acceptCharset="utf-8"
								onSubmit={saveStaff}
							>
								<input
									type="hidden"
									name="hdnSubscriptionId"
									id="hdnSubscriptionId"
								/>
								<div className="modal-form">
									<div className="m-4">
										<label htmlFor="displayname" className="form-label">
											Display Name
										</label>
										<input
											type="text"
											className="form-control"
											id="displayname"
											name="displayname"
											required
											minLength={5}
											placeholder="Enter a Display name"
											defaultValue={formData?.dispaly_name}
											onChange={(e) =>
												setFormData((formdata) => ({
													...formdata,
													dispaly_name: e.target.value,
												}))
											}
										/>
									</div>
									<div className="m-4">
										<label htmlFor="username" className="form-label">
											Username
										</label>
										<input
											type="text"
											className="form-control"
											id="username"
											name="username"
											minLength={5}
											required
											placeholder="Enter a Display name"
											defaultValue={formData?.username}
											onChange={(e) =>
												setFormData((formdata) => ({
													...formdata,
													username: e.target.value,
												}))
											}
										/>
									</div>
									<div className="m-4">
										<label htmlFor="password" className="form-label">
											Password
										</label>
										<input
											type="text"
											className="form-control"
											id="password"
											name="password"
											minLength={5}
											required
											placeholder="Enter a password"
											onChange={(e) =>
												setFormData((formdata) => ({
													...formdata,
													password: e.target.value,
												}))
											}
										/>
									</div>
								</div>

								{button_spinner ? (
									<button
										type="submit"
										className="btn btn-info ms-5 mb-4 px-4"
										disabled
									>
										<span
											className="spinner-border spinner-border-sm me-2"
											role="status"
											aria-hidden="true"
										></span>
										Save Staff
									</button>
								) : (
									<div>
										<button
											style={{
												width: "155px",
												backgroundColor: "#67C7DF",
												borderColor: "#67C7DF",
											}}
											type="button"
											onClick={() => {
												$("#add_edit_subscription").hide();
												$(".modal-backdrop").hide();
												$(".modal-open").css({ overflow: "visible" });
											}}
											className="btn btn-info ms-5 mb-4 px-4"
										>
											Cancel
										</button>
										<button
											style={{ width: "155px" }}
											type="submit"
											className="btn btn-info mx-2 mb-4 px-4"
										>
											Save
										</button>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>

			{constants.confirmDialog({
				id: "delete_subscription",
				title: "Delete Staff",
				action: "Delete",
				msg: "Are you sure to delete this staff?",
				callback: deleteSubscription,
				cancelConfirm: deleteModal,
			})}
		</>
	);
}

export default Staff;
