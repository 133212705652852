import { Link } from 'react-router-dom';
import img_banner from '../assets/images/logo/laptop.png';

export default function Home(params){
	
	window.document.title = params.title;

	return <>
		<div className="home-header mb-4 py-5">
			<div className="container">
				<h1 className="title text-center mb-5">Maximise your eBay profits and sales</h1>
				<div className="row">
					<div className="col-md-4 d-flex">
						<div className="align-self-center">
							<div className="b-subtitle">
								<h5 className="ms-2 subtitle">
									Monitor your competition and automatically keep your prices up to date for maximum sales and profit.
								</h5>
							</div>
							<Link className="btn btn-info mt-3" to={"/signup"}>
								Free Trial
							</Link>
						</div>
					</div>
					<div className="col-md-8 text-end">
						<img src={img_banner} className="img-fluid" />
					</div>
				</div>
			</div>
		</div>
		<div className="container mb-5">
			<div className="text-center clearfix">
				<h1 className="title2 mb-4">Used by eBay sellers all around the world</h1>
				<div className="mb-4 clearfix">
					<button type="button" className="btn btn-outline-dark">See what our customers think</button>
				</div>
				{ /* <img src={img_ebay_compatible} className="img-fluid"/> */ }
			</div>
		</div>
		<div className="feature-bg-1">
			<div className="container my-5 text-center">
				<div className="row">
					<div className="col col-md-4 py-5">
						<div className="border-end px-2 ">
							<i className="bi bot-money mt-3 text-info"></i>
							<h1 className="feature-title">Maximise your profit</h1>
							<span className="feature-subtitle mb-3">When competitors increase their price, or no longer exist, your item prices automatically increase based on your rules.</span>
						</div>
					</div>
					<div className="col col-md-4 py-5">
						<div className="border-end px-2 ">
							<i className="bi bot-sales mt-3 text-info"></i>
							<h1 className="feature-title">Increase sales</h1>
							<span className="feature-subtitle mb-3">When competitors lower their prices, your prices automatically adjust to just below theirs so you don't miss out on sales.</span>
						</div>
					</div>
					<div className="col col-md-4 py-5">
						<div className="px-2 ">
							<i className="bi bot-save-time mt-3 text-info"></i>
							<h1 className="feature-title">Save time</h1>
							<span className="feature-subtitle mb-3">Don't spend time manually checking competitor prices. Let RepriceBot monitor your competitors and keep your prices up to date for you.</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="feature-bg">
			<div className="container my-5 text-center">
				<div className="row">
					<div className="col col-md-4 py-5">
						<div className="border-end px-2 ">
							<i className="bi bot-integration mt-3 text-info"></i>
							<h1 className="feature-title">Integration</h1>
							<span className="feature-subtitle mb-3">RepriceBot can reprice your items in eBay directly or just calculate the best prices for you to export to your inventory software. RepriceBot also supports syncing your prices directly to your Magento or Neto store.</span>
						</div>
					</div>
					<div className="col col-md-4 py-5">
						<div className="border-end px-2 ">
							<i className="bi bot-increase-sales mt-3 text-info"></i>
							<h1 className="feature-title">Increase sales</h1>
							<span className="feature-subtitle mb-3">Get alerted when competitor items are sold or prices change, so you can check your listings or modify your pricing rules if necessary.</span>
						</div>
					</div>
					<div className="col col-md-4 py-5">
						<div className="px-2 ">
							<i className="bi bot-dropship mt-3 text-info"></i>
							<h1 className="feature-title">Drop shipping</h1>
							<span className="feature-subtitle mb-3">Track the prices and product availability from your supplier, your eBay listing will be updated to suit.</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div className="container mb-5">
			<div className="text-center clearfix">
				<button type="button" className="btn btn-info">View all Features<i className="bi bot-f-arrow ps-1 v-middle"></i> </button>
			</div>
		</div>
		<div className="get-started-bg">
			<div className="container py-5">
				<div className="text-center clearfix">
					<h6 className="mb-4 title-get-started">Get started with RepriceBot now and stay one step ahead of your competitors.</h6>					
					<Link role="button" className="btn btn-light" to={"/signup"}>Get Started Now</Link>
				</div>
			</div>
		</div>
		<div className="footer">
			<div className="px-2 py-1">
				<div className="d-flex">
					<div className="p-2 flex-grow-1">&copy; 2023 RepriceBot </div>
  					<div className="p-2"><i className="bi bot-twitter"></i></div>
  					<div className="p-2"><i className="bi bot-linkedin"></i></div>			
				</div>
			</div>
		</div>
	</>
}