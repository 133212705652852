import $ from 'jquery';
import 'jquery-validation';
import {useEffect, useState} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Api from '../Api';
import * as constants from "../Constants";

export default function NewPassword(params){
	
	window.document.title = params.title;

	
	let [searchParams] = useSearchParams();

	let navigate = useNavigate();
	
	let [button_spinner, setButtonSpinner] = useState(false);

	let [txtEmail, settxtEmail] = useState('');
	let [txtPhoneNumber, settxtPhoneNumber] = useState('');
	let [txtName, settxtName] = useState('');
	let [txtSubject, settxtSubject] = useState('');
	let [txtMessage, settxtMessage] = useState('');
	
	$(function() {
		$("#form_contactus").validate({
			rules: {
				txtEmail : {
					required : true,
					emailcheck:true,
				},
				txtPhoneNumber : {
					required: true,
					digits: true
					
				},
				txtName : {
					required: true,
				},
				txtSubject : {
					required: true,
				},
				txtMessage : {
					required: true,
				}
			},
		
			errorElement : 'div',
			errorPlacement: function(error, element) {
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},

			errorClass: "is-invalid",
		})
	});

	const sendContactUs = (e) => {
		if($("#form_contactus").valid()){
			setButtonSpinner(true)
			let data = {
				txtEmail : $("#txtEmail").val(),
				txtPhoneNumber : $("#txtPhoneNumber").val(),
				txtName : $("#txtName").val(),
				txtSubject : $("#txtSubject").val(),
				txtMessage : $("#txtMessage").val(),
			}

			Api("send-contactus", constants.prepareData("POST", data)).then((res) => {
				setButtonSpinner(false);
				console.log("contact form res: ",res);
				if(res.success){
					settxtEmail('');
					settxtPhoneNumber('');
					settxtName('');
					settxtSubject('');
					settxtMessage('');
					//navigate('/contact');
				}
			});
		}
	}
	
	const [isSpinner, setSpinner] = useState(false);
	const button = () => {
		if(isSpinner){
			return <button type="button" className="btn btn-primary px-5 py-1" disabled>
				<i class="bi bot-send-message pe-1 v-middle"></i> Send message
			</button>
		}else{
			return <button type ="button" class="btn btn-info me-2" onClick={sendContactUs}>
				<i class="bi bot-send-message pe-1 v-middle"></i> Send message
			</button>
		}
	}
	return <>
		<div className="pricing-header mb-4">
			<div className="container text-center">
				<h1 className="pricing-title">Contact us</h1>
				<h5 className="pricing-subtitle">Want to know more or have a question about your account ? Get in touch today</h5>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-md-4 mx-auto">
				<form autoComplete="off" id="form_contactus" method="post" acceptCharset="utf-8">
					<div className="mb-3">
						<label htmlFor="txtEmail" className="form-label">Email</label>					
						<div class="form-group has-icon-left">
							<div class="position-relative">
								<input type="text" className="form-control" id="txtEmail" name="txtEmail" placeholder="Enter email address"/>
								<div class="form-control-icon"><i class="bi bot-email f-color"></i></div>
							</div>
						</div>

					</div>
					<div className="mb-3">
						<label htmlFor="txtPhoneNumber" className="form-label">Phone Number</label>
										
						<div class="form-group has-icon-left">
							<div class="position-relative">
								<input type="text" className="form-control" id="txtPhoneNumber" name="txtPhoneNumber" placeholder="Enter phone number"/>	
								<div class="form-control-icon"><i class="bi bot-phone f-color"></i></div>
							</div>
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="txtName" className="form-label">Name</label>
						
						<div class="form-group has-icon-left">
							<div class="position-relative">
								<input type="text" className="form-control" id="txtName" name="txtName" placeholder="Enter name"/>
								<div class="form-control-icon"><i class="bi bot-name f-color"></i></div>
							</div>
						</div>
					</div>
					<div className="mb-3">
						<label htmlFor="txtSubject" className="form-label">Subject</label>
						<input type="text" className="form-control" id="txtSubject" name="txtSubject" placeholder="Enter subject"/>
					</div>
					<div className="mb-3">
						<label htmlFor="txtMessage" className="form-label">Message</label>
						<textarea  class="form-control" placeholder="Enter your message" id="txtMessage" name="txtMessage"/>
					</div>
					{button()}
				</form>
			</div>
		</div>
	</>
}