import { useEffect,useState } from "react";
import Api from "../../Api";
import * as constants from '../../Constants';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
//Datatable Modules
export default function SystemError(params){

	window.document.title = params.title;
	
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [error_log_details, setErrorLogDetails] = useState('');
	let [result, setResult] = useState({});
	let [active_tab, setActiveTab] = useState(1);
	let navigate = useNavigate();

	let error_badge = [
		'badge bg-light-primary','badge bg-light-warning','badge bg-light-default',
	];
	let errors_type = [
		'PHP','DB','Payment', 'eBay'
	];
	
	const viewDetails = (error) => {

		var data =  <>
			<div className="modal-header">
				<h5 className="modal-title" id="error_title">{error.error_title}</h5>
				<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-circle-fill"></i></button>
			</div>
			<div className="modal-body">
				<div className="modal-form">
					<table className="table table-striped">
						<tbody>
							<tr><th width="70px">Title</th><td>{error.error_msg}</td></tr>
							<tr><th>File</th><td>{error.error_file} on line no {error.error_line_no}</td></tr>
							<tr><th>Date</th><td>{error.error_date}</td></tr>
							<tr><td colSpan="2"><pre>{error.error_stack_trace}</pre></td></tr>
							<tr><th>Query</th>{error.error_query}<td></td></tr>
						</tbody>
					</table>
				</div>
			</div>
		</>
		setErrorLogDetails(data);
	}
	
	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}
	}
	const deleteLog = (id_list) => {
		let page_length = $("#page_length").val();
		let data = {
			id_list,
			type : active_tab,
			start : (cur_page-1) * page_length,
			length : page_length
		};
		setSpinner(true);
		Api("system-errors/solved", constants.prepareData('POST', data)).then((res) => {
			setSpinner(false);
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					//setCurPage((res.list.total_pages > cur_page ) ? res.total_pages : cur_page);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn( false);
			}
		})
	}
	const loadTable = () => {
		setSpinner(true);
		
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() :  constants.DEFAULT_RECORD_LIMIT;
		
		let data = {
			type : active_tab,
			start : (cur_page-1) * page_length,
			length : page_length
		}
		
		Api('system-errors', constants.prepareData('POST', data)).then((res) => {
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn( false);
			}
		});
	}

	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])
	 
    useEffect(() => {
		loadTable();
    }, [cur_page, active_tab])
	
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-12 col-md-9 col-lg-10">
					<h5 className="page-title">System Error Logs</h5>
				</div>
				<div className="col-12">
					<ul className="nav nav-tabs text-center" role="tablist">
						{
							errors_type.map((type, key) => 

								 <>
									<li className="nav-item" role="presentation" key={key}>
										<a className={ key == 0 ? "nav-link active" : "nav-link"} id="expired-orders-tab" data-bs-toggle="tab" data-bs-target="#expired-orders" type="button" role="tab" aria-controls="expired-orders" aria-selected="false"  onClick={ () => setActiveTab(key+1)}>{type} Errors</a>
									</li>
								</>
							)
						}
					</ul>
				</div>
				<div className="col-12 table-responsive mb-2">
					<table className="table table-striped mb-0 datatable" id="SystemError">
						<thead>
							<tr>
								<th>#</th>
								<th>Error Detail</th>
								<th>Code</th>
								<th>Type</th>
								<th>Occurance</th>
								<th width="120px">Date</th>
								<th >Actions</th>
							</tr>
						</thead>
						<tbody>
							{
								spinner === true &&
								<tr ><td colSpan={7} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr>
							}
							{
								(spinner === false && result.hasOwnProperty('list') > 0 ) &&
								result.list.data.map((error, idx) => 
									<>
										<tr key={idx}>
											<td>{result.list.start+idx}</td>
											<td style={{"cursor":"pointer"}} data-bs-toggle="modal" data-bs-target="#error_details" onClick={() => viewDetails( error)}>
												<div> {error.error_msg}</div>
												<span className="sub-promo">{error.error_title} </span>
												<div className="sub-promo text-danger">{error.error_file} on line {error.error_line_no}</div>
											</td>
											<td>{error.error_no}</td>
											<td><span className={error_badge[error.error_type - 1]}>{errors_type[error.error_type - 1]}</span></td>
											<td>{error.total}</td>
											<td>{error.error_date}</td>
											<td>
												<button className="btn actionDelete" onClick={() => deleteLog(error.id)}>
													<i className="bi bot-trash text-danger-light"></i>
												</button>
											</td>
										</tr>
									</>
								)
							}
						</tbody>
					</table>
				</div>
				{
					result.hasOwnProperty('list') && 
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
				}
			</div>
		</section>
		<div className="modal fade" id="error_details" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="System Error" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
				<div className="modal-content">
					{error_log_details}
				</div>
			</div>
		</div>
	</>
}