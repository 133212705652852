
import { useEffect, useState } from 'react';
import {Link } from 'react-router-dom';
import * as constants from '../Constants';
import Api from '../Api';
export default function Nav(props){
	const [current_user, setCurrentUser] = useState({});
	const [is_logged_in, setIsLoggedIn] = useState(false);
	

	useEffect(() => {
		
		let token = (localStorage.hasOwnProperty('auth')) ? JSON.parse( localStorage.getItem('auth')) : null;
		let time = Math.round(Date.now()/1000);
		
		if(token !== null && token.expires > time){
			setCurrentUser((sessionStorage.hasOwnProperty('current_user')) ? JSON.parse(sessionStorage.getItem('current_user')) : {});
			setIsLoggedIn(true);
		}else{
			localStorage.removeItem('auth');
			sessionStorage.removeItem('current_user');
		}
	}, [])

	useEffect(() => {
		console.log("session changed");
		if(is_logged_in){

			Api("current-user",  constants.prepareData('POST', {})).then((res) => {
				//console.log("current-user:- ",res);
				if(res.status == 200){
					current_user.active_subscription = res.active_subscription;
					current_user.total_active_items = res.total_active_items;
					current_user.user_tags = res.user_tags;
					current_user.user = res.user;
					current_user.ebay = res.ebay;

					sessionStorage.setItem('current_user', JSON.stringify(current_user));
					setCurrentUser(current_user);
				}
				
			});
		}
	}, [is_logged_in, sessionStorage])
	//navbar navbar-expand-lg navbar-light
	return <>
		<nav className="navbar navbar-expand-lg navbar-light ">
			<div className="container-fluid">
				<Link to={'/'} className="navbar-brand" > 
					<img src={props.logo} alt="" height="32" className="align-text-top"/>
				</Link>
				<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
				</button>
				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						{ 
						/*
						<li className="nav-item">
							<Link to={'/'} className="nav-link">Features</Link>
						</li>
						<li className="nav-item dropdown">
							<Link to={'#'} className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
							Guides
							</Link>
							<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
								<li><a className="dropdown-item" href="/">One</a></li>
								<li><a className="dropdown-item" href="/">Two</a></li>
								<li><hr className="dropdown-divider"/></li>
								<li><a className="dropdown-item" href="/">Three</a></li>
							</ul>
						</li>
						*/
						}
						<li className="nav-item">
							<Link to={'/pricing'} className="nav-link">Pricing</Link>
						</li>
						
						<li className="nav-item">
							<Link className="nav-link" to={"/contact"}>Contact</Link>
						</li>				
					</ul>
				
					<div className="d-flex">
						{
							is_logged_in ? 
							<>
								<Link className="btn btn-primary" to={"/dashboard"}>
									<i className="bi bot-dashboard  text-white pe-1 v-middle"></i> Dashboard
								</Link>
							</>
							:
							<>
								<Link className="btn btn-primary me-2" to={"/signin"}>
									<i className="bi bot-login-front pe-1 v-middle"></i> Login
								</Link>
								<Link className="btn btn-info" to={"/signup"}>
									<i className="bi bot-signup pe-1 v-middle"></i> Free Trial
								</Link>
							</>
						}
					</div>
				</div>
			</div>
		</nav>
	</>
}