import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'

function Dashboard(params){
	window.document.title = params.title;

	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [order_status, setOrderStatus] = useState('all');
	let [dashboard_date_range, setDashboardDateRange] = useState({});
	let [revenue_date_range, setRevenueDateRange] = useState({});
	let [revenueRes, setRevenueRes] = useState({});
	let [txtDataTableSearch, settxtDataTableSearch] = useState(null);

	let navigate = useNavigate();

	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}

	const loadRevenue = () => {
		//setSpinner(true);
		let data = {
			report_date : revenue_date_range,
		}
		Api('dashboard-revenue', constants.prepareData('POST', data)).then((res) => {
			setSpinner(false);

			if(res.status !== 401){
				revenueRes = {
					current : res.current_period,
					previous : res.previous_period,
					diff : res.diff,
				};

				if(revenueRes.diff.transaction > 0){
					revenueRes.current.transaction = {
						text : 'text-success revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-success mx-2 align-text-bottom'
					}

				}else if(revenueRes.diff.transaction < 0){
					revenueRes.current.transaction = {
						text : 'text-danger revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-danger mx-2 align-text-bottom'
					}
				}else{
					revenueRes.current.transaction = {
						text : 'text-secondary revenue-amount mb-2',
						badge : 'badge rounded-pill bg-secondary mx-2 align-text-bottom'
					}
				}

				if(revenueRes.diff.orders > 0){
					revenueRes.current.orders = {
						text : 'text-success revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-success mx-2 align-text-bottom'
					}

				}else if(revenueRes.diff.orders < 0){
					revenueRes.current.orders = {
						text : 'text-danger revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-danger mx-2 align-text-bottom'
					}
				}else{
					revenueRes.current.orders = {
						text : 'text-secondary revenue-amount mb-2',
						badge : 'badge rounded-pill bg-secondary mx-2 align-text-bottom'
					}
				}

				if(revenueRes.diff.fee > 0){
					revenueRes.current.fee = {
						text : 'text-success revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-success mx-2 align-text-bottom'
					}

				}else if(revenueRes.diff.fee < 0){
					revenueRes.current.fee = {
						text : 'text-danger revenue-amount mb-2',
						badge : 'badge rounded-pill bg-light-danger mx-2 align-text-bottom'
					}
				}else{
					revenueRes.current.fee = {
						text : 'text-secondary revenue-amount mb-2',
						badge : 'badge rounded-pill bg-secondary mx-2 align-text-bottom'
					}
				}


				
				setRevenueRes(revenueRes);
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		})
	}
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() :  constants.DEFAULT_RECORD_LIMIT;
		
		let data = {
			start : (cur_page-1) * page_length,
			length : page_length,
			status : order_status, 
			report_date : dashboard_date_range,
			search_by : txtDataTableSearch,
		}
		Api('dashboard', constants.prepareData('POST', data)).then((res) => {
			setSpinner(false);
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages) ? res.list.total_pages : cur_page );
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	useEffect(() => {
		if(Object.keys(dashboard_date_range).length > 0){
			loadTable()
		}
	}, [cur_page, order_status, dashboard_date_range, txtDataTableSearch])

	
	useEffect(() => {
		if(Object.keys(revenue_date_range).length > 0){
			loadRevenue()
		}
	}, [revenue_date_range])

	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])


	const fillTableRow = (order) => {
		
		if(order.order_status == 2){
			var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-resume text-warning"></i>
			</button> 
		}else {
			var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-hold text-warning"></i>
			</button> 
		}
		return <>
			<tr key={order.user_order_id}>
				<td>{order.user_order_id}</td>
				<td>{order.total_amount}</td>
				<td>Montly valid</td>
				<td>{order.first_name} {order.last_name}</td>
				<td>{constants.formatDate(order.order_date)}</td>
				<td>{constants.formatDate(order.subscription_renewal_date, 'dd-mm-yyyy') }</td>
				{
				/*
				<td>
					<button type="button" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit Order">
						<i className="bi bot-edit-fill text-primary"></i>
					</button>
					<button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Refund">
						<i className="bi bot-refund text-info"></i>
					</button>
					{ 
						(order.order_status == 2) ? 
						<button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
							<i className="bi bot-resume text-warning"></i>
						</button>  : <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
							<i className="bi bot-hold text-warning"></i>
						</button> 
					}
					<button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Delete Order">
						<i className="bi bot-trash text-danger-light"></i>
					</button>
				</td>
				*/
				}
			</tr>
		</>
	}
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-6 col-md-3 col-lg-9">
					<h5 className="page-title">Orders</h5>
				</div>
				<div className="col-6 col-md-3 col-lg-3 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							{
								<constants.InputDateRange id="txtOrderDateRange" className="form-control bg-textbox" 
								setChange={setRevenueDateRange} position="right"/>
							}
							<div className="form-control-icon">
								<i className="bi bot-calendar text-primary"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-md-4">
					<div className="card dashboard">
						<div className="card-body">
							<h5 className="">Gross revenue</h5>
							<div className="">
								<span className={revenueRes?.current?.transaction?.text}>
									{revenueRes?.current?.symbol}{revenueRes?.current?.total_transaction}
								</span>
								<span className={revenueRes?.current?.transaction?.badge}>
									{revenueRes?.diff?.transaction >= 0 ? '+' : ''}{revenueRes?.diff?.transaction}%
								</span>
							</div>
							<p className="card-text card-description">Previous: <b>{revenueRes?.previous?.symbol}{revenueRes?.previous?.total_transaction}</b></p>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card dashboard">
						<div className="card-body">
							<h5 className="">Daily sales</h5>
							<div className="">
								<span className={revenueRes?.current?.fee?.text}>
									{revenueRes?.current?.symbol}{revenueRes?.current?.total_fee}
								</span>
								<span className={revenueRes?.current?.fee?.badge}>
									{revenueRes?.diff?.fee > 0 ? '+' : ''}{revenueRes?.diff?.fee}%
								</span>
							</div>
							<p className="card-text card-description">Previous: <b>{revenueRes?.previous?.symbol}{revenueRes?.previous?.total_fee}</b></p>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="card dashboard">
						<div className="card-body">
							<h5 className="">Orders today</h5>
							<div className="">
								<span className={revenueRes?.current?.orders?.text}>
									{revenueRes?.current?.total_orders}
								</span>
								<span className={revenueRes?.current?.orders?.badge}>
									{revenueRes?.diff?.orders >= 0 ? '+' : ''}{revenueRes?.diff?.orders}%
								</span>
							</div>
							<p className="card-text card-description">Previous: <b>{revenueRes?.previous?.total_orders}</b></p>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-6 col-md-3 col-lg-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							{
								<constants.InputDateRange id="txtDashboardDateRange" className="form-control bg-textbox"
								setChange={setDashboardDateRange} />
							}
							<div className="form-control-icon">
								<i className="bi bot-calendar text-primary"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 col-lg-6">
					&nbsp;
				</div>
				<div className="col-6 col-md-3 col-lg-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control bg-textbox" placeholder="Search by Order no" id="txtDataTableSearch" name="txtDataTableSearch" onKeyUp={
								(e) =>  settxtDataTableSearch(e.target.value)} />
							<div className="form-control-icon">
								<i className="bi bi-search f-color text-primary"></i>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<ul className="nav nav-tabs text-center" role="tablist">
						<li className="nav-item" role="presentation">
							<a className="nav-link active" id="all-orders-tab" data-bs-toggle="tab" data-bs-target="#all-orders" type="button" role="tab" aria-controls="all-orders" aria-selected="true" onClick={ () => setOrderStatus('all')}>All Orders</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="active-orders-tab" data-bs-toggle="tab" data-bs-target="#active-orders" type="button" role="tab" aria-controls="active-orders" aria-selected="false" onClick={ () => setOrderStatus('active')}>Active Orders</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="on-hold-tab" data-bs-toggle="tab" data-bs-target="#on-hold" type="button" role="tab" aria-controls="onhold" aria-selected="false"  onClick={ () => setOrderStatus('hold')}>On Hold</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="refunded-tab" data-bs-toggle="tab" data-bs-target="#refunded" type="button" role="tab" aria-controls="refunded" aria-selected="false" onClick={ () => setOrderStatus('refunded')}>Refunded</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="failed-tab" data-bs-toggle="tab" data-bs-target="#failed" type="button" role="tab" aria-controls="failed" aria-selected="false"  onClick={ () => setOrderStatus('failed')}>Failed</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="cancelled-tab" data-bs-toggle="tab" data-bs-target="#cancelled" type="button" role="tab" aria-controls="cancelled" aria-selected="false" onClick={ () => setOrderStatus('cancelled')}>Cancelled</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="expired-trial-tab" data-bs-toggle="tab" data-bs-target="#expired-trial" type="button" role="tab" aria-controls="expired-trial" aria-selected="false"  onClick={ () => setOrderStatus('trial_expired')}>Expired Trial</a>
						</li>
						<li className="nav-item" role="presentation">
							<a className="nav-link" id="expired-orders-tab" data-bs-toggle="tab" data-bs-target="#expired-orders" type="button" role="tab" aria-controls="expired-orders" aria-selected="false"  onClick={ () => setOrderStatus('expired')}>Expired Orders</a>
						</li>
					</ul>
				</div>
				<div className="col-12 table-responsive mb-2">
					<table className="table table-striped mb-0 datatable">
						<thead>
							<tr>
								<th width="15%">Order No</th>
								<th width="5%">Total</th>
								<th>Staus</th>
								<th>Customer</th>
								<th>Date</th>
								<th>Renewal Date</th>
								{
									//<th width="20%">Actions</th>
								}
							</tr>
						</thead>
						<tbody>
						{
							spinner === true ?
							<><tr><td colSpan={7} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
							:
							(spinner === false && (result != undefined && result.hasOwnProperty('list')) ) ?
							result.list.data.map((sub, idx) => 
								fillTableRow(sub, idx)
							)
							: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
						}
						
						</tbody>
					</table>
				</div>
				{
					result.hasOwnProperty('list') && 
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
				}
			</div>
		</section>
	</>

}

export default Dashboard;