import face from '../../assets/images/faces/1.jpg';
import {Link, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Api from '../../Api';
import * as constants from '../../Constants';



function Sidebar(params){

	const navigate = useNavigate();
	const [is_logged_in, setIsLoggedIn] = useState(false);
	const [current_user, setCurrentUser] = useState({});
	
	let location = window.location.pathname
	//console.log(location);
	useEffect(() => {
		
		constants.Resize();
		
		if(localStorage.getItem('auth') == null){
			navigate('/signin');
		}
		
		let token = (localStorage.hasOwnProperty('auth')) ? JSON.parse( localStorage.getItem('auth')) : null;
		let time = Math.round(Date.now()/1000);
		if(token !== null && token.expires < time){
			localStorage.removeItem('auth');
			sessionStorage.removeItem('current_user');
			setIsLoggedIn(false);
		}
	}, [location])
 

	useEffect(() => {

		if(is_logged_in){
			Api("current-user", constants.prepareData("POST")).then((res) => {
				//console.log(res);
				if(res.status == 200){
					current_user.active_subscription = res.active_subscription;
					current_user.total_active_items = res.total_active_items;
					current_user.user_tags = res.user_tags;
					current_user.user = res.user;
					current_user.ebay = res.ebay;

					sessionStorage.setItem('current_user', JSON.stringify(current_user));
					setCurrentUser(current_user);
				}
			})
		}
	}, [is_logged_in, localStorage, sessionStorage])

	useEffect(() => {

		constants.Resize();

		let token = (localStorage.hasOwnProperty('auth')) ? JSON.parse( localStorage.getItem('auth')) : null;
		let time = Math.round(Date.now()/1000);
		if(token !== null && token.expires > time){
			/*Api("current-user", constants.prepareData("POST")).then((res) => {
				//console.log(res);
				if(res.status = 200){
					current_user.active_subscription = res.active_subscription;
					current_user.total_active_items = res.total_active_items;
					current_user.user_tags = res.user_tags;
					current_user.user = res.user;
					current_user.ebay = res.ebay;

					sessionStorage.setItem('current_user', JSON.stringify(current_user));
					setCurrentUser(current_user);
				}
			})*/
			setIsLoggedIn(true);
		}else{
			navigate("signin");
		}

	}, []);


	function logout(){
		localStorage.removeItem('auth');
		sessionStorage.removeItem('current_user');
	}


	return <>
		<div id="sidebar" className="active">
			<div className="sidebar-wrapper">
				<div className="sidebar-header">
					<div className="d-flex justify-content-between align-items-center">
						<div className="logo m-3">
							<Link to={"/"}><img src={params.logo} alt="BotPricer"/></Link>
						</div>
					</div>
					<div className="d-flex align-items-center header-bg">
						<div className="avatar avatar-lg offset-1 mt-2 mb-2">
							<img src={face} className="p-1" alt="Face 1"/>
						</div>
						<div className="d-flex profile-div">
							<button className="btn profile-btn " data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent" id="profile">
								<div className="row">
									<div className="col-9 text-start">
										<span className="ebay-profile-name"> </span>
										<span className="ebay-id-label">eBay Id: {current_user?.ebay?.user_name}</span>
									</div>
									<div className="col-3"><i className="bi bi-caret-down-fill"></i></div>
								</div>
							</button>
							<div className="dropdown-menu " aria-labelledby="profile">
								<Link className="dropdown-item" to="/preferences">
								<i className="bi bot-preference pe-2"></i>	
								Preferences</Link>
								<Link className="dropdown-item " to="/change-password"><i className="bi bot-settings pe-2"></i>User Settings</Link>
								<div className="dropdown-divider"></div>
								<Link className="dropdown-item text-danger" to={'/signin'} onClick={logout}>
								<i className="bi bot-logout text-danger pe-2"></i>Log out</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="sidebar-menu">
					<ul className="menu">
						<li className={(location == '/dashboard') ? "sidebar-item active" : "sidebar-item"}>
							<Link to={"/dashboard"} className='sidebar-link'>
								<i className="bi bot-dashboard text-primary"></i>
								<span>Dashboard</span>
							</Link>
						</li>
						{
						// <li className="divider">Competitors tracking</li>

						// <li className={location == '/daily-sales' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/daily-sales" className='sidebar-link'>
						// 		<i className="bi bot-daily-sales text-primary"></i>
						// 		<span>Daily sales</span>
						// 	</Link>
						// </li>
					
						// <li className={location == '/tracking-item' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/tracking-item" className='sidebar-link'>
						// 		<i className="bi bot-tracking-item text-primary"></i>
						// 		<span>Tracking item</span>
						// 	</Link>
						// </li>
						// <li className={location == '/competitors' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/competitors" className='sidebar-link'>
						// 		<i className="bi bot-competitors text-primary"></i>
						// 		<span>Competitors</span>
						// 	</Link>
						// </li>
						
						// <li className="divider">manage</li>

						// <li className={location == '/strategies' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/strategies" className='sidebar-link'>
						// 		<i className="bi bot-strategies text-primary"></i>
						// 		<span>Strategies</span>
						// 	</Link>
						// </li>
						// <li className={location == '/adaptive-profiles' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/adaptive-profiles" className='sidebar-link'>
						// 		<i className="bi bot-adaptive-profiles text-primary"></i>
						// 		<span>Adaptive Profiles</span>
						// 	</Link>
						// </li>
						// <li className={location == '/product-groups' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/product-groups" className='sidebar-link'>
						// 		<i className="bi bot-product-groups text-primary"></i>
						// 		<span>Product Groups</span>
						// 	</Link>
						// </li>
						
						}
						<li className="divider">orders</li>

						<li className={location == '/orders' ? "sidebar-item active" : "sidebar-item"}>
							<Link to="/orders" className='sidebar-link'>
								<i className="bi bot-orders text-primary"></i>
								<span>Orders</span>
							</Link>
						</li>
						{
						// <li className="divider">Ebay listings</li>

						// <li className={location == '/add-listing' ? "sidebar-item active" : "sidebar-item"}>
						// 	<Link to="/add-listing" className='sidebar-link'>
						// 		<i className="bi bot-add-listing text-primary"></i>
						// 		<span>Add Listing</span>
						// 	</Link>
						// </li>
						}
					</ul>
				</div>

			</div>
		</div>
	</>
}

export default Sidebar;