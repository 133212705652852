import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'

function Subscriptions(params){

	window.document.title = params.title;
	let featuresModal = useRef(null), subscriptionModal = useRef(null), deleteModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [is_edit, setIsEdit] = useState(false);
	let [button_spinner, setButtonSpinner] = useState(false);

	//features form inputs
	let [chkCompetitorSale, setCompetitorSale] = useState(false);
	let [chkProfit, setProfit] = useState(false);
	let [chkTrackPosition, setTrackPosition] = useState(false);
	let [chkCompetitorTracking, setCompetitorTracking] = useState(false);
	let [chkAdaptivePrice, setAdaptivePrice] = useState(false);
	let [chkSourceProductPrice, setSourceProductPrice] = useState(false);
	let [txtAllowedItems, settxtAllowedItems] = useState('');
	let [txtRepriceInterval, settxtRepriceInterval] = useState('');
	let [hdnFeaturesId, sethdnFeaturesId] = useState('');

	//subscription form inputs
	let [hdnSubscriptionId, sethdnSubscriptionId] = useState('');
	let [txtSubscriptionName, settxtSubscriptionName] = useState('');
	let [txtSubscriptionPrice, settxtSubscriptionPrice] = useState('');
	let [chkPromo, setchkPromo] = useState(false);
	let [hdnCurrency, sethdnCurrency] = useState('');
	let [hdnOfferType, sethdnOfferType] = useState('');
	let [txtOfferPrice, settxtOfferPrice] = useState('');
	let [txtOfferStartDate, settxtOfferStartDate] = useState('');
	let [txtOfferEndDate, settxtOfferEndDate] = useState('');



	let navigate = useNavigate();
	
	let title_badge = [
		'badge bg-light-info', 'badge bg-light-default','badge bg-light-success','badge bg-light-primary','badge bg-light-warning-white',
	];

	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const editSubscription = (sub = null) => {
		
		setIsEdit((sub !== null));
		sethdnSubscriptionId((sub !== null) ? sub.subscription_id : '');
		settxtSubscriptionName((sub !== null) ? sub.subscription_name : '');
		settxtSubscriptionPrice((sub !== null) ? sub.subscription_amount.value : '');
		sethdnCurrency((sub !== null) ? sub.subscription_currency : hdnCurrency);
		setchkPromo((sub !== null && sub.offer_type !== '0') ? true : false)
		sethdnOfferType((sub !== null) ? sub.offer_type : hdnOfferType);
		settxtOfferPrice((sub !== null) ? sub.offer_amount : '');
		settxtOfferStartDate((sub !== null) ? sub.offer_start_date.display : '');

		$('#txtOfferEndDate').datepicker('destroy');
		if(sub !== null && sub.offer_end_date.display != ''){
			var dateString = txtOfferStartDate;

			$('#txtOfferEndDate').datepicker({
				autoclose : true,
				format: "dd-mm-yyyy",
				startDate: new Date(Date.UTC(dateString[2], dateString[1]-1, parseInt(dateString[0])+1 ))
			});
		}
		settxtOfferEndDate((sub !== null) ? sub.offer_end_date.display : '');

	}

	
	const editFeatures = (features = null) => {
		settxtAllowedItems((features !== null) ? features.items_allowed : '');
		settxtRepriceInterval((features !== null) ? features.reprice_interval : '');
		sethdnFeaturesId((features !== null) ? features.feature_id : '');
		sethdnSubscriptionId((features !== null) ? features.subscription_id : '');

		setCompetitorSale((features !== null && features.competitor_sales_alert === '1' ) ? true : chkCompetitorSale);
		setProfit((features !== null && features.price_based_on_profit === '1') ? true : chkProfit);
		setCompetitorTracking((features !== null && features.competitor_tracking === '1') ? true : chkCompetitorTracking);
		setTrackPosition((features !== null && features.eBay_tracking_position === '1') ? true : chkTrackPosition); 
		setAdaptivePrice((features !== null && features.adaptive_pricing === '1') ? true : chkAdaptivePrice); 
		setSourceProductPrice((features !== null && features.source_product_tracking === '1') ? true : chkSourceProductPrice);
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() :  constants.DEFAULT_RECORD_LIMIT;
		
		let data = {
			start : (cur_page-1) * page_length,
			length : page_length
		}
		Api('subscriptions', constants.prepareData('POST', data)).then((res) => {
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
					setSpinner(false);
					sethdnCurrency(res.currency[0]);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	const saveSubscription = () => {
		if($("#form_subscription").valid()){
			setButtonSpinner(true)
			let data = {
				hdnSubscriptionId, txtSubscriptionName, txtSubscriptionPrice, hdnCurrency, chkPromo, hdnOfferType, txtOfferPrice, txtOfferStartDate, txtOfferEndDate
			}
			
			Api("subscriptions/save", constants.prepareData('POST', data)).then((res) => {
				setButtonSpinner(false);
				
				if(res.status !== 401){
					if(res.hasOwnProperty('list')){
						setResult(res);
						setMaxPage(res.list.total_pages);
						setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
						setSpinner(false);
						sethdnCurrency(res.currency[0]);
						subscriptionModal.current.click();
						//resetForm("#form_subscription");
					}
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});
		}
	}
	
	const saveFeatures = () => {
		if($("#form_features").valid()){
			setButtonSpinner(true)
			let data = {
				hdnFeaturesId, hdnSubscriptionId, txtAllowedItems, txtRepriceInterval, chkCompetitorSale, chkProfit, chkTrackPosition, chkCompetitorTracking, chkAdaptivePrice, chkSourceProductPrice,
			};
			Api("subscriptions/save-features", constants.prepareData('POST', data)).then((res) => {
				setButtonSpinner(false);
				
				if(res.status !== 401){
					if(res.hasOwnProperty('list')){
						setResult(res);
						setMaxPage(res.list.total_pages);
						setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
						setSpinner(false);
						sethdnCurrency(res.currency[0]);
						featuresModal.current.click();
					}
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});

		}
	}
	const deleteSubscription = () => {
		let data = {
			hdnSubscriptionId,
		}
		Api("subscriptions/delete", constants.prepareData('POST', data)).then((res) => {
			setButtonSpinner(false);
			deleteModal.current.click();
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
					setSpinner(false);
					sethdnCurrency(res.currency[0]);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});	
		
	}

 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])

	const resetForm = (form_element) => {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();

		sethdnSubscriptionId('');
		settxtSubscriptionName('');
		settxtSubscriptionPrice('');
		sethdnCurrency(hdnCurrency);
		sethdnOfferType(hdnOfferType);
		settxtOfferPrice('');
		settxtOfferStartDate('');

		$('#txtOfferEndDate').datepicker('destroy');
		settxtOfferEndDate('');

		settxtAllowedItems('');
		settxtRepriceInterval('');
		sethdnFeaturesId('');

		setCompetitorSale(false);
		setProfit(false);
		setCompetitorTracking(false);
		setTrackPosition(false); 
		setAdaptivePrice(false); 
		setSourceProductPrice(false); 
	}

	$(() => {

		$('#txtOfferStartDate').datepicker({
			autoclose : true,
			format: "dd-mm-yyyy",
			startDate: new Date()
		}).on("changeDate", function(){
			settxtOfferStartDate($('#txtOfferStartDate').datepicker('getFormattedDate'));
			var dateString = $('#txtOfferStartDate').datepicker('getFormattedDate').split('-');
			
			$('#txtOfferEndDate').datepicker('destroy').val('');

			$('#txtOfferEndDate').datepicker({
				autoclose : true,
				format: "dd-mm-yyyy",
				startDate: new Date(Date.UTC(dateString[2], dateString[1]-1, parseInt(dateString[0])+1 ))
			}).on("changeDate", function(){
				settxtOfferEndDate($('#txtOfferEndDate').datepicker('getFormattedDate'));
			});
		});
		$("#form_subscription").validate({
			rules: {
				txtSubscriptionName : {
					required: true,
				},
				txtSubscriptionPrice : {
					required: true,
					number:true
				},
				txtOfferPrice : {
					required : function(){
						return $("#chkPromo").is(":checked");
					},
					number:true
				},
				txtOfferStartDate : {
					required : function(){
						return $("#chkPromo").is(":checked");
					}
				},
				txtOfferEndDate : {
					required : function(){
						return $("#chkPromo").is(":checked");
					}
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",

		});

		$("#form_features").validate({
			rules: {
				txtAllowedItems : {
					//required: true,
					number:true
				},
				txtRepriceInterval : {
					//required: true,
					number:true
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",

		});

		
	})

	useEffect(() => {
		loadTable();
	}, [cur_page])
	
	const fillTableRow = (sub, idx) => {
			let offer_amount = 0;
			if(sub.offer_type == 1){
					offer_amount = sub.offer_amount;
			}else if(sub.offer_type == 2){
				offer_amount = sub.subscription_amount.value * sub.offer_amount/100;
			}

			return <>
			<tr key={sub.subscription_id}>
				<td>{result.list.start + idx}</td>
				<td><span className={"radius-10 " + title_badge[(idx%5)]}>{sub.subscription_name} </span></td>
				<td>{sub.created_date}</td>
				<td className="weight-600">{sub.subscription_amount.symbol}{sub.subscription_amount.value}</td>
				<td>
					{
						sub.offer_type > 0 &&
						<>
							<span className="weight-600">{sub.subscription_amount.symbol + offer_amount}</span>
							<span className="sub-promo"><br/> ends {sub.offer_end_date.display}</span>
						</>
					}
					{
						offer_amount === 0 &&
						<>
							<span className="weight-600">-</span>
						</>
					}
				</td>
				<td>
					{
						(sub.paypal_plan_id != null || sub.paypal_plan_id != '') ?
						<i className="bi bi-paypal text-success"></i> : <i className="bi bi-paypal text-secondary"></i>
					}

					{
						(sub.stripe_plan_id != null || sub.stripe_plan_id != '') ?
						<i className="bi bot-stripe text-success"></i> : <i className="bi bot-stripe text-secondary"></i>
					}
				</td>
				<td>
					<button className="btn btn-edit" data-bs-toggle="modal" data-bs-target="#add_edit_subscription" onClick={() => editSubscription(sub)}><i className="bi bot-edit-fill text-info"></i></button>
					<button className="btn btn-delete" onClick={() => sethdnSubscriptionId(sub.subscription_id)} data-bs-toggle="modal" data-bs-target="#delete_subscription"><i className="bi bot-trash text-danger-light"></i></button>
					<button className="btn btn-features" data-bs-toggle="modal" data-bs-target="#add_edit_features" onClick={() => editFeatures(sub)}><i className="bi bot-link text-primary"></i></button>
				</td>
			</tr>
		</>
	}
	
	return <>
			<section className="section">
				<div className="row mb-3">
					<div className="col-6 col-md-9 col-lg-10">
						<h5 className="page-title">Subscriptions</h5>
					</div>
					<div className="col-6 col-md-3 col-lg-2 d-grid">
						<button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#add_edit_subscription" onClick={() => editSubscription()}><i className="bi bi-plus-circle-fill pe-1"></i> Add new</button>
					</div>
				</div>
				<div className="row">
					<div className="col-12 table-responsive mb-2">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>ID</th>
									<th>Name</th>
									<th>Date Created</th>
									<th>Price</th>
									<th>Promo</th>
									<th width="150px">Active Gateway</th>
									<th width="200px">Actions</th>
								</tr>
							</thead>
							<tbody>
							{
								spinner === true ?
								<><tr><td colSpan={7} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
								:
								(spinner === false && (result != undefined && result.hasOwnProperty('list')) ) ?
								result.list.data.map((sub, idx) => 
									fillTableRow(sub, idx)
								)
								: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
							}
							
							</tbody>
						</table>
					</div>
					{
						result.hasOwnProperty('list') && 
						constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
					}
				</div>
			</section>
			<div className="modal fade" id="add_edit_subscription" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Subscription" aria-hidden="true">
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">{(!is_edit) ? 'Add New Subscription' : 'Edit Subscription'}</h5>
							<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={subscriptionModal} onClick={ () => resetForm("#form_subscription") }><i className="bi bi-x-circle-fill"></i></button>
						</div>
						<div className="modal-body">
						<form autoComplete="off" id="form_subscription" method="post" acceptCharset="utf-8">
							<input type="hidden" name="hdnSubscriptionId" id="hdnSubscriptionId" defaultValue={hdnSubscriptionId}/>
							<div className="modal-form">
								<div className="m-4">
									<label htmlFor="txtSubscriptionName" className="form-label">Subscription Name</label>
									<input type="text" className="form-control" id="txtSubscriptionName" name="txtSubscriptionName" placeholder="Enter a Subscription name" defaultValue={txtSubscriptionName} onChange={(e) => settxtSubscriptionName(e.target.value)}/>
								</div>
								<div className="m-4">
									<label htmlFor="txtSubscriptionPrice" className="form-label">Subscription Price</label>
									<input type="hidden" name="hdnCurrency" id="hdnCurrency" defaultValue={hdnCurrency}/>
									<div className="form-group input-group has-validation">
										<input type="text" className="form-control" id="txtSubscriptionPrice" name="txtSubscriptionPrice" placeholder="Enter a Subscription Price" defaultValue={txtSubscriptionPrice} onChange={(e) => settxtSubscriptionPrice(e.target.value)}/>
										<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" disabled={is_edit ? 'disabled' : ''}>{hdnCurrency}</button>
										<ul className="dropdown-menu dropdown-menu-end">
											{
												(result != undefined && result.hasOwnProperty('currency')) &&
												result.currency.map((cur, key) =>
													<li key={key}><button type="button" className="dropdown-item" onClick={() => sethdnCurrency(cur)}>{cur}</button></li>
												)
											}
										</ul>
									</div>
								</div>
								<div className="m-4">
									<input type="checkbox" className="form-check-input" id="chkPromo" name="chkPromo" checked={chkPromo ? "checked" : ""} onChange={() => setchkPromo(!chkPromo)}/>
									<label htmlFor="chkPromo" className="form-label fst-normal">Show promo inputs</label>
								</div>
							</div>
							
							<div id="promo" className={(chkPromo === true) ? '' : 'd-none'}>
								<hr className="modal-divider"/>
								<div className="modal-form">
									<div className="row g-3 mx-4">
										<div className="col-md-4">
											<label htmlFor="txtOfferPrice" className="col-form-label">Offer Price</label>
										</div>
										<div className="col-md-8">
											<input type="hidden" name="hdnOfferType" id="hdnOfferType" defaultValue={hdnOfferType}/>
											<div className="form-group input-group has-validation">
												<input type="text" id="txtOfferPrice" name="txtOfferPrice" className="form-control" placeholder="Offer price"  defaultValue={txtOfferPrice} onChange={(e) => settxtOfferPrice(e.target.value)}/>
												<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{hdnOfferType == 2 ? '%' : '$'}</button>
												<ul className="dropdown-menu dropdown-menu-end">
													<li><button type="button" className="dropdown-item offerType" onClick={ () => sethdnOfferType(2)}>%</button></li>
													<li><button type="button" className="dropdown-item offerType" onClick={ () => sethdnOfferType(1)}>$</button></li>
												</ul>
											</div>
										</div>
									</div>
									<div className="row g-3 mx-4">
										<div className="col-md-4">
											<label htmlFor="txtOfferStartDate" className="col-form-label">Offer Start Date</label>
										</div>
										<div className="col-md-8">
											<div className="form-group has-icon-left">
												<div className="position-relative">
													<input type="text" className="form-control" placeholder="Offer Start Date" id="txtOfferStartDate" name="txtOfferStartDate" defaultValue={txtOfferStartDate}  onChange={(e) => settxtOfferStartDate(e.target.value)}/>
													<div className="form-control-icon">
														<i className="bi bot-calendar text-secondary"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="row g-3 mx-4">
										<div className="col-md-4">
											<label htmlFor="txtOfferEndDate" className="col-form-label">Offer End Date</label>
										</div>
										<div className="col-md-8">
											<div className="form-group has-icon-left">
												<div className="position-relative">
													<input type="text" className="form-control" placeholder="Offer End Date" id="txtOfferEndDate" name="txtOfferEndDate" defaultValue={txtOfferEndDate} onChange={(e) => settxtOfferEndDate(e.target.value) }/>
													<div className="form-control-icon">
														<i className="bi bot-calendar text-secondary"></i>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							{
								(button_spinner) ?
								<button type="button" className="btn btn-info ms-5 mb-4 px-4" disabled>
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Save Subscription
								</button>
								:
								<button type="button" className="btn btn-info ms-5 mb-4 px-4" onClick={ saveSubscription }>Save Subscription</button>
							}
						</form>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="add_edit_features" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Subscription" aria-hidden="true">
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="Subscription">Link Features</h5>
							<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={featuresModal} onClick={() => resetForm("#form_features")}><i className="bi bi-x-circle-fill"></i></button>
						</div>
						<div className="modal-body">
							<form autoComplete="off" id="form_features" method="post" acceptCharset="utf-8">
								<input type="hidden" name="hdnFeaturesId" id="hdnFeaturesId" defaultValue={hdnFeaturesId}/>
								<input type="hidden" name="hdnSubscriptionId" id="hdnFeatureSubscriptionId" defaultValue={hdnSubscriptionId}/>
								<div className="modal-form">
									<div className="mx-3">
										<label htmlFor="txtAllowedItems" className="mb-4">Select features you want to include in this plan</label>
									</div>
									<div className="row g-3 mx-3 mb-3">
										<div className="col-md-5">
											<label htmlFor="txtAllowedItems" className="col-form-label">How many items allowed?</label>
										</div>
										<div className="col-md-7">
											<div className="form-group">
												<input type="text" id="txtAllowedItems" name="txtAllowedItems" className="form-control" placeholder="Enter number of items allowed" defaultValue={txtAllowedItems} onChange={(e) => settxtAllowedItems(e.target.value)}/>
											</div>
										</div>
									</div>
									<div className="row g-3 mx-3 mb-4">
										<div className="col-md-5">
											<label htmlFor="txtRepriceInterval" className="col-form-label">Reprice Interval</label>
										</div>
										<div className="col-md-7">
											<div className="form-group">
												<input type="text" id="txtRepriceInterval" name="txtRepriceInterval" className="form-control" placeholder="Enter reprice interval"  defaultValue={txtRepriceInterval} onChange={(e) => settxtRepriceInterval(e.target.value)}/>
											</div>
										</div>
									</div>
								</div>
								<hr className="modal-divider mt-2"/>
								<div className="modal-form">
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkCompetitorSale" className="col-form-label">Competitor Sale alert</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkCompetitorSale" name="chkCompetitorSale" value="1" checked={ chkCompetitorSale ? "checked" : ''} onChange={() => setCompetitorSale(!chkCompetitorSale)}/>
										</div>
									</div>
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkProfit" className="col-form-label">Set prices based on profit</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkProfit" name="chkProfit" value="1" checked={ chkProfit ? "checked" : ''} onChange={() => setProfit(!chkProfit)}/>
										</div>
									</div>
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkTrackPosition" className="col-form-label">Track your Position in Ebay's Result</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkTrackPosition" name="chkTrackPosition" value="1" checked={ chkTrackPosition ? "checked" : ''} onChange={() => setTrackPosition(!chkTrackPosition)}/>
										</div>
									</div>
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkCompetitorTracking" className="col-form-label">Competitor Tracking</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkCompetitorTracking" name="chkCompetitorTracking" value="1" checked={ chkCompetitorTracking ? "checked" : ''} onChange={() => setCompetitorTracking(!chkCompetitorTracking)}/>
										</div>
									</div>
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkAdaptivePrice" className="col-form-label">Adaptive Pricing</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkAdaptivePrice" name="chkAdaptivePrice" value="1" checked={ chkAdaptivePrice ? "checked" : ''} onChange={() => setAdaptivePrice(!chkAdaptivePrice)}/>
										</div>
									</div>
									<div className="row mx-3">
										<div className="col-md-10">
											<label htmlFor="chkSourceProductPrice" className="col-form-label">Monitor Source Product Prices Availability</label>
										</div>
										<div className="col-md-2 text-end">
											<input type="checkbox" className="form-check-input text-right" id="chkSourceProductPrice" name="chkSourceProductPrice" value="1" checked={ chkSourceProductPrice ? "checked" : ''} onChange={() => setSourceProductPrice(!chkSourceProductPrice)}/>
										</div>
									</div>
								</div>
								{
									(button_spinner) ?
									<button type="button" className="btn btn-info m-5 mb-4 px-4" disabled>
										<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
										Save Features
									</button>
									:
									<button type="button" className="btn btn-info m-5 mb-4 px-4" onClick={saveFeatures}>Save Features</button>
								}
							</form>
						</div>
					</div>
				</div>
			</div>
			
			{
				constants.confirmDialog({
					id: "delete_subscription",
					title : "Delete Subscription", 
					action : "Delete",
					msg : "Are you sure to delete this subscription?",
					callback : deleteSubscription,
					cancelConfirm : deleteModal,
				})
			}
				
		</>

}

export default Subscriptions;