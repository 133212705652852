import { useEffect, useRef, useState } from 'react';
import default_img from '../assets/images/image.png';
import $ from 'jquery';
import '../assets/js/bootstrap-datepicker.min.js';
import Api from "../Api";
import * as constants from '../Constants'
import { Link } from 'react-router-dom';

export default function CustomerOrders(params) {

	window.document.title = params.title;

	let editOrderModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [button_spinner, setButtonSpinner] = useState(false);
	let [order_detail, setOrderDetail] = useState({});
	let [orderRevenue, setOrderRevenue] = useState({});

	let [is_show_address, setShowAddres] = useState(false);
	let [order_id, setOrderId] = useState(null);
	let [is_extra_filter, setIsExtraFilter] = useState(false);

	let [chkIsSource, setchkIsSource] = useState(false);
	let [chkIsShiped, setchkIsShiped] = useState(false);
	let [txtReference, settxtReference] = useState('');
	let [txtTrackingNumber, settxtTrackingNumber] = useState('');
	let [hdnOrderId, sethdnOrderId] = useState('');
	
	let [drpShippingCarrier, setdrpShippingCarrier] = useState({});

	let [txtDataTableSearchByName, settxtDataTableSearchByName] = useState({});
	let [drpDataTableSortBy, setdrpDataTableSortBy] = useState();
	let [drpDataTableSearchByOrderStatus, setdrpDataTableSearchByOrderStatus] = useState();
	let [drpDataTableSearchByShippedStatus, setdrpDataTableSearchByShippedStatus] = useState();
	let [order_date_range, setOrderDateRange] = useState({});

	const prevPage = () => {
		if (cur_page > 1) {
			setCurPage(--cur_page)
		} else {
			setCurPage(1);
		}
	}
	const nextPage = () => {
		if (cur_page < max_page) {
			setCurPage(++cur_page)
		} else {
			setCurPage(max_page)
		}
	}
	const loadOrderRevenue = () => {

		setSpinner(true);
		Api('orders-revenue', constants.prepareData('POST')).then((res) => {
			
			//console.log("revenue result:- ", res);
			if (res.status !== 401) {
				if (res.hasOwnProperty('order_revenue')) {

					setOrderRevenue(res.order_revenue);
				}
			} else {
				localStorage.removeItem('auth');
				sessionStorage.removeItem('current_user');
				setIsLoggedIn(false);
			}
		});
	} 
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		let data = {
			start: (cur_page - 1) * page_length,
			length: page_length,
			sort_by_name: drpDataTableSortBy,
			search_by_name: txtDataTableSearchByName,
			search_by_order_status: drpDataTableSearchByOrderStatus,
			search_by_shipped_status: drpDataTableSearchByShippedStatus,
			search_by_order_date: order_date_range,
		}
		Api('orders', constants.prepareData('POST', data)).then((res) => {
			//console.log("result:- ", res);
			if (res.status !== 401) {
				if (res.hasOwnProperty('list')) {
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			} else {
				localStorage.removeItem('auth');
				sessionStorage.removeItem('current_user');
				setIsLoggedIn(false);
			}
		});
	}

	const importOrders = () => {
		setButtonSpinner(true);

		Api('orders-import', constants.prepareData('POST')).then((res) => {
			//console.log("result:- ", res);
			setButtonSpinner(false);
		});
	}

	useEffect(() => {
		loadOrderRevenue();
	},[]);

	useEffect(() => {
		loadTable();
	}, [cur_page, drpDataTableSortBy, txtDataTableSearchByName, drpDataTableSearchByOrderStatus, drpDataTableSearchByShippedStatus, order_date_range]);

	const fillTableRow = (order, key) => {
		//console.log("orders", order);
		return <>
			<tr key={key}>
				<td>
					<div className="row">
						<div className="col-12 col-md-4">
							<img src={order.item_image != null ? order.item_image : default_img} onError={({ currentTarget }) => {
								currentTarget.src = default_img;
							}} className="img-thumbnail" />
						</div>
						<div className="col-12 col-md-8">
							<div className="item-title font-16">
								{order.ebay_order_item_title}
								<a className='btn btn-sm' href={order.ebay_item_url} target="_blank" rel="noopener noreferrer" ><i className='bi bi-box-arrow-up-right text-primary'></i></a>
							</div>
							<div className="mt-1">
								<span className="badge bg-light-white radius-10-left font-10">Record</span>
								<span className="badge bg-light-split radius-10-right font-10">{order.id}</span>

								{
									order.ebay_order_is_shipped == 1 &&
									<>
										{
											(order.ebay_tracking_number != '' && order.ebay_tracking_number != null) &&
											<>
												<span className="badge bg-light-white ms-1 radius-10-left font-10">Tracking</span>
												<span className="badge bg-light-split radius-10-right font-10">{order.ebay_tracking_number}</span>
												<span className="badge bg-light-success ms-1 radius-10 font-10">{order.source_product_ref}</span>
											</>
										}

										<span className="badge bg-light-info ms-1 radius-10 font-10">Shipped</span>
									</>
								}
							</div>
							<div className="mt-1 d-none">
								<span className="radius-10 badge bg-promo sub-promo className"><i className="bi bot-global text-info"></i> costco.com.au</span>
							</div>
						</div>
					</div>
				</td>
				<td className="text-center">
					<div className="item-price">{order.ebay_order_total}</div>
					<div className="m-1 radius-10 badge bg-light-success d-none">
						<i className="bi bot-dollar-circle text-success"></i>
						<span className="align-text-top">Total: $2.00</span>
					</div>
					{
						order.in_stock == 0 &&
						<div className="ms-2">
							<span className="radius-10 badge bg-light-danger font-12 ">Out of stock</span>
						</div>
					}
				</td>
				<td>
					<div className="order-address mt-0">
						<span className="font-16">{order.item_title}</span>
					</div>
					<div>

						<span className="order-address" >
							<div>{order.buyer_full_name}</div>
							{
								(is_show_address && order_id == order.id) &&
								<>
									<div className="position-relative">
										<button type="button" className="btn position-absolute top-0 end-0" onClick={() => {
											
											setOrderId(order.id)
											setShowAddres(true);;
										}}>
											<i className="bi bi-x-circle-fill"></i>
										</button>
									</div>
									<div>{order.street1}</div>
									<div>{order.street2}</div>
									<div>{order.city}</div>

									<div>{order.state + " " + order.country + " " + order.post_code}</div>

									<div>Phone: {order.buyer_phone}</div>
									<div>Email: {order.buyer_email}</div>
								</>
							}
						</span>
						{(order_id != order.id) && <a className="btn p-0" onClick={() => {
							setOrderId(order.id)
							setShowAddres(true);
						}}><span className="item-detail">Show Details </span></a>}
					</div>
				</td>
				<td>{order.ebay_order_paid_date}</td>
				<td>
					<button type="button" className="btn" data-bs-target="#orderDetails" data-bs-toggle="modal" onClick={() => {
						
						//setOrderDetail(order);
						editOrder(order);
					}
					}><i className='bi bot-edit-fill text-primary'></i></button>

				</td>
			</tr>
		</>
	}
	
	const resetForm = (form_element = '') => {

		setOrderDetail({})
		setchkIsShiped(false)
		setchkIsSource(false);

		if(form_element.length > 0){
			var validator = $(form_element).validate();
			document.getElementById(form_element.replace(/#/g,'')).reset();
			validator.resetForm();
		}
	}

	const handleSourceCheck = (e) => {
		if(e.target.checked){
			setchkIsSource(true);
		}else{
			setchkIsSource(false);
		}
	}
	const handleShippedCheck = (e) => {
		if(e.target.checked){
			setchkIsShiped(true);
		}else{
			setchkIsShiped(false);
		}
	}
	const editOrder = (order = null) => {

		//console.log("order",order);
		sethdnOrderId((order.id !== null) ? order.id : '');
		setchkIsSource(order.is_order_from_source == 1 ? true : false);
		settxtReference((order.source_product_ref !== null) ? order.source_product_ref : '');
		setchkIsShiped(order.ebay_order_is_shipped == 1 ? true : false);
		settxtTrackingNumber((order.ebay_tracking_number !== null) ? order.ebay_tracking_number : '');

	}
	const saveOrderDetails = () => {
		setButtonSpinner(true);
		let data = {
			hdnOrderId, chkIsSource, txtReference, chkIsShiped, txtTrackingNumber
		}
		console.log("data",data);
		Api('orders/save', constants.prepareData('POST', data)).then(resp => {
			setButtonSpinner(false);
			//editOrderModal.current.click();
			if (resp.status == 200) {
				//dialogRef.current.click();
				loadTable();
			}
		});
	}
	
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-12 col-md-8">
					<h5 className="page-title my-2">Orders</h5>
				</div>
				<div className="col-6 col-md-2">
					<div className="d-grid">
						{
							button_spinner ? 
							<button className="btn btn-primary disabled"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Import Orders</button>
							:
							<button className="btn btn-primary" onClick={() => importOrders()}><i className="bi bi-plus-circle-fill pe-1"></i> Import Orders</button>
						}
					</div>
				</div>
				<div className="col-6 col-md-2">
					<div className="d-grid">
						<Link to={"/export-order-csv"} className="btn btn-info"><i className="bi bi-plus-circle-fill pe-1"></i> Export selected CSV</Link>
					</div>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="card-order-title font-14">Profit today</span>
							<div className="mb-1">
								<span className="card-order-price font-20">-</span>
							</div>
							<span className="card-order-title font-12">Sales</span>
							<div className="mb-1">
								<span className="card-order-price font-14">{orderRevenue?.today?.symbol}{(orderRevenue.hasOwnProperty('today')) ? orderRevenue.today.total_amount : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="card-order-title font-14">Profit yesterday</span>
							<div className="mb-1">
								<span className="card-order-price font-20">-</span>
							</div>

							<span className="card-order-title font-12">Sales</span>
							<div className="mb-1">
								<span className="card-order-price font-14">{orderRevenue?.yesterday?.symbol}{(orderRevenue.hasOwnProperty('yesterday')) ? orderRevenue.yesterday.total_amount : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="card-order-title font-14">Profit this week</span>
							<div className="mb-1">
								<span className="card-order-price font-20">-</span>
							</div>

							<span className="card-order-title font-12">Sales</span>
							<div className="mb-1">
								<span className="card-order-price font-14">{orderRevenue?.week?.symbol}{(orderRevenue.hasOwnProperty('week')) ? orderRevenue.week.total_amount : 0}</span>
							</div>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-3 my-2">
					<div className="card dashboard">
						<div className="card-body">
							<span className="card-order-title font-14">Profit this month</span>
							<div className="mb-1">
								<span className="card-order-price font-20">-</span>
							</div>

							<span className="card-order-title font-12">Sales</span>
							<div className="mb-1">
								<span className="card-order-price font-14">{orderRevenue?.month?.symbol}{(orderRevenue.hasOwnProperty('month')) ? orderRevenue.month.total_amount : 0}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-12 col-md-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" placeholder="Search by Name" id="txtDataTableSearchByName" name="txtDataTableSearchByName" onKeyUp={
								(e) => settxtDataTableSearchByName(e.target.value)} />
							<div className="form-control-icon">
								<i className="bi bot-search-normal text-info"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-5"></div>
				<div className="col-6 col-md-2 d-grid">
					<div className="form-group has-icon-left d-none">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-sort text-info"></i>
							</div>
							<select className="form-select" aria-label="Sort By"
								defaultValue={''} id="drpDataTableSortBy" name="drpDataTableSortBy" onChange={(e) => setdrpDataTableSortBy(e.target.value)}>
								<option value="" hidden>Sort by</option>
								<option value="1">Sort</option>
								<option value="2">Most recent</option>
								<option value="3">Profit lowest to highest </option>
								<option value="4">Profit highest to lowest </option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-2">
					<div className="form-group has-icon-left d-grid">
						<button className={`btn ${is_extra_filter ? 'btn-info-light' : 'btn-light'} border d-flex justify-content-between`}
							onClick={() =>
								setIsExtraFilter(!is_extra_filter)
							}>
							<i className="bi bot-filter text-info"></i>
							<span aria-hidden="true" className="text-start">Filter By</span>
							<i className={`bi ${is_extra_filter ? 'bi-caret-up-fill' : 'bi-caret-down-fill'} text-end`
							}></i>
						</button>
					</div>
				</div>
			</div>

			<div className={(is_extra_filter) ? "row" : "row d-none"}>
				<div className="col-12 col-md-3 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-order-status text-info"></i>
							</div>
							<select className="form-select" aria-label="Order Status"
								defaultValue={''} id="drpDataTableSearchByOrderStatus" name="drpDataTableSearchByOrderStatus" onChange={(e) => setdrpDataTableSearchByOrderStatus(e.target.value)}>
								<option value="" hidden>Order Status</option>
								<option value="">Any</option>
								<option value="1">Ordered</option>
								<option value="2">Not Ordered</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-3 d-grid">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-shipped-status text-info"></i>
							</div>
							<select className="form-select" aria-label="Shipped Status"
								defaultValue={''} id="drpDataTableSearchByShippedStatus" name="drpDataTableSearchByShippedStatus" onChange={(e) => setdrpDataTableSearchByShippedStatus(e.target.value)}>
								<option value="" hidden>Shipped Status</option>
								<option value="">Any</option>
								<option value="1">Shipped</option>
								<option value="2">Not Shipped</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-2 d-grid ">
					<div className="form-group has-icon-left d-none">
						<div className="position-relative">
							<div className="form-control-icon">
								<i className="bi bot-calendar text-info"></i>
							</div>
							<select className="form-select" aria-label="Source Product">
								<option value="" hidden>Source Product</option>
								<option value="1">Any</option>
								<option value="2">Have Source Product</option>
								<option value="3">Doen't have source product</option>
							</select>
						</div>
					</div>
				</div>
				<div className="col-6 col-md-4 col-lg-4">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							{
								<constants.InputDateRange id="txtOrderDateRange" name="txtOrderDateRange" className="form-control bg-textbox"
									setChange={setOrderDateRange} />
							}
							<div className="form-control-icon">
								<i className="bi bot-calendar text-info"></i>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className='row'>
				<div className='col-12 table-responsive mb-2'>
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th>Item</th>
								<th width="10%" className="text-center">Total</th>
								<th>Address</th>
								<th width="15%">Paid</th>
								<th width="10%"></th>
							</tr>
						</thead>
						<tbody>
							{
								spinner === true ?
									<><tr><td colSpan={8} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
									:
									(spinner === false && (result.hasOwnProperty('list') && result.list.total_records > 0)) ?
										result.list.data.map((sub, idx) =>
											fillTableRow(sub, idx)
										)
										: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
							}
						</tbody>
					</table>
				</div>
				{
					result.hasOwnProperty('list') &&
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
				}
			</div>
		</section>

		<div className="modal fade"  key="orderDetails" id="orderDetails" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Order Details" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Order</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={editOrderModal} onClick={() => resetForm('#form_order_details')}><i className="bi bi-x-circle-fill" ></i></button>
					</div>
					<div className="modal-body">
						<form autoComplete="off" id="form_order_details" method="post" acceptCharset="utf-8" onSubmit={(e) => {
							e.preventDefault();
							saveOrderDetails();
						}}>
							<div className="modal-form">
								<div className="container mb-2">
									<div className="form-check">
										<constants.CheckBox 
											id="chkIsSource"
											className="form-check-input"
											isChecked={chkIsSource}
											value={order_detail?.id}
											name="source_product"
											label="Source product ordered"
											handleEvent={handleSourceCheck}
										/>
									</div>
								</div>
								{
									chkIsSource &&
									<div className="container mb-3">
										<label for="txtReference" className="form-label">Order URL or reference</label>
										<input type="text" className="form-control" id="txtReference" name="txtReference" defaultValue={txtReference} onChange={(e) => settxtReference(e.target.value)}/>
										<label className="form-check-label text-info">
											Eg. https://www.url.com.au/gp/.../orderID=123 or 000001012023 <br/>
											NOTE: this is just internal for your own reference.</label>							
									</div>	
								}											
								<div className="container mb-3">
									<div className="form-check">
										<constants.CheckBox 
											id="chkIsShiped"
											className="form-check-input"
											isChecked={chkIsShiped}
											value={order_detail?.id}
											name="order_shipped"
											label="Shipped"
											handleEvent={handleShippedCheck}
										/>
									</div>
								</div>
								{
									chkIsShiped &&	
									<>
									<div className="row">
										<label className="form-check-label text-dark fw-bold">Tracking</label>
										<div className="input-group mb-1">
											{
												<>
												<select className="form-select form-select-white" data-live-search="true" id="drpShippingCarrier" name="drpShippingCarrier" value={drpShippingCarrier} onChange={(e) => setdrpShippingCarrier(e.target.value)}>
													<option className="selectSP" key="op_" value={''} hidden>Carrier</option>
													{
														(result.list.hasOwnProperty("shipping_carrier")) &&
														result.list.shipping_carrier.map((sub, idx) =>
															<option key={"op_"+idx} value={sub.carrier_id} defaultValue={drpShippingCarrier}> {sub.carrier_name}</option>
														)
													}
												</select>
												
												<input type="text" className="form-control" id="txtTrackingNumber" name="txtTrackingNumber"  defaultValue={txtTrackingNumber} onChange={(e) => settxtTrackingNumber(e.target.value)}/>
												</>
											}
										</div>
									</div>
									<label className="form-check-label text-info">Mark as shipped and save to eBay</label>
									</>
								}							
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<div className='d-grid col-4 mx-3'>
						{
							button_spinner ? 
								<button type="button" className="btn btn-primary disabled">
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
									Save
								</button>
							:
								<button type="button" className="btn btn-primary" onClick={() => saveOrderDetails()}>Save</button>
						}
						</div>
					</div>
				</div>
			</div>
		</div>

		{/*<constants.EditOrder
			id="orderDetails"
			isEdit={orderEdit}
			setIsEdit={setOrderEdit}
			action={saveEditOrder}
			defaultValue={order_detail}
			setChanged={setOrderDetail}
		/>*/}
	</>
}