import Api from '../Api';
import {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import offer_img from '../assets/images/icons/offer.png';
import * as constants from '../Constants';
import $ from 'jquery';
import moment from 'moment';
import {loadScript} from "@paypal/paypal-js";
import { loadStripe } from "@stripe/stripe-js";
import { format } from 'date-fns';

export default function Pricing(params){
	
	window.document.title = params.title;
	window.$ = $;
	let navigate = useNavigate();

	let [paypal, setPaypal] = useState({});
	let [is_logged_in, setIsLoggedIn] = useState(false);
	let [spinner, setSpinner] = useState(false);
	let [is_purchase, setIsPurchase] = useState(false);
	let [plan_data, setPlanData] = useState({});
	let [current_user, setCurrentUser] = useState({});

	//const [active_subscription, setActiveSubscription] = useState([]);
	const [is_stripe_subscription, setStripeSubscription] = useState(false)
	const [selected_plan,setSelectedPlan] = useState(null);
	const [stripe,setStripe] = useState(null);
	const {pay_status, reference_id, session_id} = useParams();

	let modalRef = useRef(null);

	
	useEffect(() => {
		Api('pricing', constants.prepareData('POST')).then((res) => {
			
			if(res.hasOwnProperty("pricing")){

				setPlanData(res.pricing);
				
				let token = (localStorage.hasOwnProperty('auth')) ? JSON.parse( localStorage.getItem('auth')) : null;
				let time = Math.round(Date.now()/1000);

				if(token !== null && token.expires > time ){
					current_user = JSON.parse(sessionStorage.getItem("current_user"));
					setCurrentUser(current_user);
					setIsLoggedIn(true);
					
					if(current_user !== null){
						current_user.active_subscription.map((active,key) => {
							
							if(!is_purchase &&	active.is_paid && active.on_hold === false){
								setIsPurchase(true);
							}
							return active;
						});
						//console.log(cur_user.active_subscription.length)
					}
				}else{
					localStorage.removeItem("auth");
					sessionStorage.removeItem("current_user");
				}
				const initialOptions = {
					"client-id": res.config.paypal_client,
					components : "buttons",
					intent : "subscription",
					vault : true
				}
				loadStripe(res.config.stripe_public_key).then((stripe) => {
					setStripe(stripe);
				});
				
				loadScript(initialOptions).then((paypal) => {
					setPaypal(paypal);
				});
			}
		});
		
	}, [navigate]);

	useEffect(() => {
		
		if(!is_logged_in){
			navigate(window.location.pathname);
		}

		console.log(current_user);

	}, [is_logged_in]);

	
	useEffect(() => {

		if(reference_id !== undefined && pay_status !== undefined){
			if(!$("body").hasClass('modal-open')){
				let body = {
					order_id : reference_id,
					reference : reference_id,
					status : (pay_status == 'success') ? 'active' : 'cancelled',
					payment_source : "stripe",
					session : session_id,
				}
				modalRef.current.click();
				savePlanResponse(body)
			}
	
		}else{
			$("#payment_gateway").show();
			$("#payment_response").hide();
		}
	}, [stripe])

	const savePlanResponse = (body) => {
		setSpinner(true);
		Api("plan-subscribed", constants.prepareData('POST', body)).then((res) => {
			$("#payment_response").show()
			$("#payment_gateway").hide()
			setSpinner(false);
			
			var alert = '';

			if(res.is_subscribed){
				current_user.active_subscription = res.subscription
				setCurrentUser(current_user);
				//setActiveSubscription(res.subscription)
			}
			if(body.status == 'active'){
				
				alert = '<div class="alert alert-success text-start" role="alert">' +
				'<h4 class="alert-heading">Payment Success!</h4>';
				alert += (pay_status == undefined) ? '<p class="mb-0"> Subscription id : ' + body.subscriber_id + '</p>' : '';
				alert += '<p class="mb-0"> Order ID : ' + body.order_id + '</p>' +
				'</div>';
			}else if(body.status == 'cancelled'){
				
				alert = '<div class="alert alert-warning text-start" role="alert">' +
				'<h4 class="alert-heading">Cancelled!</h4>';
				alert += '<p class="mb-0"> Order ID : ' + body.order_id + '</p>' +
				'</div>';
			}else if(body.status == 'failed'){
				

				alert = '<div class="alert alert-danger text-start" role="alert">' +
				'<h4 class="alert-heading">Failed!</h4>' +
				'<p class="mb-0"> Error</p>' +
				'</div>';
			}

			$("#payment_response").html(alert);
			return alert;
		})
	}

	//console.log("current_user",current_user);
	const subscribePlan = (paypal_plan_id, stripe_plan_id, items_allowed) => {
		setSpinner(true);
		setStripeSubscription(false);
		if(!is_logged_in){
			return ;
		}
		
		//check current user live inventory items
		if(current_user.total_active_items > items_allowed)
		{
			
			$("#payment_gateway").hide();
			setSpinner(false);
			var alert = '<div class="alert alert-danger text-start" role="alert">' +
			'<h4 class="alert-heading">Inventory Warring!</h4>';
			alert += '<p class="mb-0"> Your active listing is more than selected plan allow items.</p>';
			alert += '<p class="mb-0"> Please deactive items first.</p>'+
			'</div>';
			$("#inventory_response").html(alert);
			$("#inventory_response").show();

		}
		else
		{
			
			let paypal_button_container = '#paypal-button-container';
			$("#payment_response, " + paypal_button_container).html('');
			
			let body = {paypal_plan_id, stripe_plan_id};
			Api("create-subscription", constants.prepareData('POST', body)).then((res)=>{
				setSpinner(false);
				
				if(res.status !== 401){
					
					$("#payment_gateway").show();
					$("#payment_response").hide();

					if(res.hasOwnProperty("reference_id")){
						if(stripe_plan_id != null && stripe_plan_id.length > 0){
							setStripeSubscription(true);
						}
						setSelectedPlan({
							...selected_plan,
							reference_id: res?.reference_id,
							subscriber: res?.subscriber,
							source: res?.source,
							renewal: res?.renewal,
							next_start_at: res?.next_start_at,
						});

						paypal.Buttons({
							style: {
								shape: 'rect',
								color: 'white',
								layout: 'horizontal',
								label: 'subscribe',
								tagline: false,
							},
							createSubscription: function (data, actions) {
								setSpinner(true);
								$("#payment_gateway").hide();
								if(res.hasOwnProperty('next_start_at')){
									var data = {
										'plan_id': paypal_plan_id,
										'custom_id' : res.reference_id,
										'start_time' : res.next_start_at,
									}
								}else{
									var data = {
										'plan_id': paypal_plan_id,
										'custom_id' : res.reference_id,
									}
								}
								return actions.subscription.create(data);
							},
							onApprove: function (data, actions) {

								let body = {
									order_id: data.orderID,
									payment_source: data.paymentSource,
									subscriber_id: data.subscriptionID,
									reference: res.reference_id,
									status: 'active'
								}

								savePlanResponse(body);
							},
							onCancel: function (data) {
								let body = {
									order_id: data.orderID,
									payment_source: 'paypal',
									reference: res.reference_id,
									status: 'cancelled'
								}
								savePlanResponse(body);
							},
							onError: function (err) {
								
								let body = {
									reference: res.reference_id,
									payment_source: 'paypal',
									status: 'failed'
								}
								savePlanResponse(body);
							}

						}).render(paypal_button_container);
					}else{
						$(paypal_button_container).html(res.notice);
					}
				}else{
					localStorage.removeItem('auth');
					sessionStorage.removeItem('current_user');
					setIsLoggedIn(false);
				}
			})
			
			$("#inventory_response").hide();
			$("#payment_gateway").show();
		}
	}

	const stripeCheckout = async () => {
		
		setSpinner(true);
		let body = selected_plan;
			
		$("#payment_gateway").hide();

		const {sessionId, res_body} = await Api("stripe-checkout", constants.prepareData('POST', body)).then((res) => {
			setSpinner(false);
			if (res.hasOwnProperty('session_id')){
				//window.location.href = res.stripe_url;
				let body = {}
				if(res.hasOwnProperty('is_upgrade')){
					body = {
						payment_source: 'stripe',
						subscriber_id: res.subscriber_id,
						reference: res.reference_id,
					}
				}
				return {sessionId : res.session_id, res_body : body};
			}
		})
		if(sessionId != null){
			const { error } = await stripe.redirectToCheckout({
				sessionId,
			});
		}else{
			
			var alert = '<div class="alert alert-success text-start" role="alert">' +
			'<h4 class="alert-heading">Suscription updated!</h4>';
			alert += '<p class="mb-0"> Subscription id : ' + res_body.subscriber_id + '</p>';
			alert += '<p class="mb-0"> Order ID : ' + res_body.reference + '</p>' +
			'</div>';
			$("#payment_response").html(alert);
			$("#payment_response").show();
			
		}
	}


	const subscribe_button = (plans) => {
		let is_current = false,  is_next = false,next_start_date = '';
		//console.log("plan",plans);
		//console.log("active_subscription",active_subscription);
		if(is_logged_in){
			if(plans.subscription_amount <= 0 && current_user !== null){
				return	<>
					<button type="button" className="btn btn-secondary" disabled>Get Started</button>
				</>
			}

			current_user.active_subscription.map((active, key) =>	{
				if(active.is_paid && plans.subscription_id === active.id){
					if(moment(new Date()).isBefore(active.start_at)){
					
						is_next = true;
						next_start_date = moment(active.start_at).format('DD-MM-YYYY');
					}
					else if(moment(new Date()).isAfter(active.start_at)){
						is_current = true;
					}
				}
				return active;
			})
			
			if(is_current){
				return <>
					<button type="button" className="btn btn-info btn-plan" disabled>Current Plan</button>
				</>
			}else if(is_next){
				return <>
					<button type="button" className="btn btn-info btn-plan">Starting on.. {next_start_date}</button>
				</>
			}else{
				return <>
					<button type="button" className="btn btn-primary btn-plan" data-bs-toggle="modal" data-bs-target="#subscriptionModal" onClick={ () => subscribePlan(plans.paypal_plan_id, plans.stripe_plan_id, plans.items_allowed)}>Subscribe</button>
				</>
			}
		}else{
			return <>
				<Link type="button" to={'/signup'} className="btn btn-primary btn-plan">
				{
					plans.subscription_amount > 0 ? 
					'Try for 30 days' : 'Get Started'
				}
				</Link>
			</>
		}
		
	}
	const plan = (plans) => {

		let is_offer = false;
		let offer = 0;

		//check for offer is available or not
		if(plans.offer_type !== 0){

			let o_start_date = new Date(plans.offer_start_date);
			let o_end_date = new Date(plans.offer_end_date);
			let today = new Date();
			
			//calclulate discounted new price
			if(today.getTime() >= o_start_date.getTime()	&& today.getTime() <= o_end_date.getTime() )
			{
				is_offer = true;
				if(plans.offer_type === 1){
					
					offer = plan_data.currency.symbol + parseFloat(plans.offer_amount);
					//discount_amt = parseFloat(plans.subscription_amount - plans.offer_amount).toFixed(2);
					
				} else if(plans.offer_type === 2){
					offer = parseFloat(plans.offer_amount) + '%';
					//discount_amt = parseFloat(plans.subscription_amount - Math.round((plans.offer_amount / 100 ) * plans.subscription_amount)).toFixed(2);
				}
			}
		}
		
		return	<div className="col" key={plans.subscription_id}>
			<div className="card pricing-card">
				{
					is_offer &&
					<div className="pricing-offer">
						<img src={offer_img} alt="" height="70"/>
						<div className="offer-text text-center">{offer} OFF</div>
					</div>
				}
				<div className="card-body mx-2">
					<h5 className="plan-name">{plans.subscription_name}</h5>
					<div className="mb-4 plan-subtitle"></div>
						{
							(plans.subscription_amount <= 0 ) &&
							<div className="text-center mb-4 plan-price">FREE</div>
						}
						{ 
							(is_offer && plans.subscription_amount > 0 ) &&
							<>
								<div className="offer-price">
									{plan_data.currency.symbol + plans.subscription_amount}
								</div>
								<div className="mb-4 plan-price">
									{plan_data.currency.symbol + plans.total_amount } <sup><span className="price-span">&nbsp;per / month</span></sup>
								</div>
							</>
						}
						{ 
							(!is_offer && plans.subscription_amount > 0) &&
								<div className="mb-4 plan-price">
									{plan_data.currency.symbol + plans.subscription_amount}
									<sup><span className="price-span">&nbsp;per / month</span></sup>
								</div>
						}

					<div className="d-grid mb-4">
						{ subscribe_button(plans) }
					</div>
					<ul className="pricing-plan-features list-group">
						<li><i className="bi bot-check mx-2"></i>Up to <b>{plans.items_allowed}</b> items</li>
						<li><i className="bi bot-check mx-2"></i>Prices checked every <b>{plans.reprice_interval} hours</b></li>
						{
							plans.competitor_sales_alert === '1' &&
							<li><i className="bi bot-check mx-2"></i>Competitor sales alerts</li>
						}
						{
							plans.price_based_on_profit === '1' &&
							<li><i className="bi bot-check mx-2"></i>Set prices based on profit</li>
						}
						{
							plans.eBay_tracking_position === '1' &&
							<li><i className="bi bot-check mx-2"></i>Track your position in eBay's result</li>
						}
						{
							plans.competitor_tracking === '1' &&
							<li><i className="bi bot-check mx-2"></i>Competitor tracking</li>
						}
						{
							plans.adaptive_pricing === '1' &&
							<li><i className="bi bot-check mx-2"></i>Adaptive Pricing</li>
						}
						{
							plans.source_product_tracking === '1' &&
							<li><i className="bi bot-check mx-2"></i>Monitor source product prices & availability. Perfect for drop shipping.</li>
						}
					</ul>
				</div>
			</div>
		</div>
	}
	return <>
		<div className="pricing-header">
			<div className="container text-center">
				<h1 className="pricing-title">Pricing plan that&rsquo;s perfect for any businesses</h1>
				<h5 className="pricing-subtitle">Choose a plan tailored to your needs</h5>
			</div>
		</div>
		<div className="container">
			<div className="pricing-tab mb-5">
				<div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
					{
						plan_data.plans !== undefined &&
						plan_data.plans.map((plans, plan_key) =>
							plan(plans)
						)
					}
				</div>
			</div>
		</div>
		<div className="d-none" ref={modalRef} data-bs-toggle="modal" data-bs-target="#subscriptionModal"></div>
		<div className="modal fade" id="subscriptionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Subscribe" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="error_title">Upgrade Account</h5>
					</div>
					<div className="modal-body">
						<div className="modal-form text-center" >
							{
								spinner && 
								<>
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								</>
							}
							<div id="payment_response" > </div>
							<div id="inventory_response"> </div>
							<div id="payment_gateway">
								<div id="paypal-button-container" />
								{
									is_stripe_subscription && 
									<div id="stripe-button-container">
										<button
											style={{
											width: "100%",
											height: 55,
											borderWidth: 1,
											borderRadius: 5,
											fontSize: 20,
											background: "blue",
											color: "#ffffff",
											fontWeight: "bold",
											}}
											onClick={() => stripeCheckout()}
										>
											Stripe Subscribe
										</button>
									</div>
								}
							</div>
						</div>
					</div>
					<div className="modal-footer ">
						<div className="col-3 d-grid">
							<button
							type="button"
							className="btn btn-info"
							data-bs-dismiss="modal"
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</>
}