import {Link, useNavigate} from 'react-router-dom';
import { useEffect, useState} from 'react';
import $ from 'jquery';
import 'jquery-validation';
import Api from '../Api';
import * as constants from '../Constants';

export default function Signup(params){
	
	window.document.title = params.title;
	
	const navigate = useNavigate();
	const auth = localStorage.hasOwnProperty('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
	const [oAuthUrl, setOAuthUrl] = useState(null);

	useEffect(() => {
		if(auth != null && auth.hasOwnProperty('token')){
			navigate('/dashboard')
		}
		
		Api('signup', constants.prepareData('POST')).then((res) => {
			setOAuthUrl(null);
			if(res.hasOwnProperty('authUrl')){
				setOAuthUrl(res.authUrl);
			}
		})
	}, [auth, navigate])

	$(function(){
		$("#form_signup").validate({
			rules: {
				chkAgree : {
					required: true,
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid"
		});
	});	
	function userConsent(){

		if($("#form_signup").valid()){
			if(oAuthUrl !== null){
				window.location =  oAuthUrl;
			};
		}
	}
	return <>
		<div className="form-signup my-5">
			<div className="card card-border">
				<div className="card-header card-title position-relative">
					<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
						<h5 className="page-title"><Link to={-1}><i className="bi bot-back"></i></Link>&nbsp; Create an account</h5>
					</div>
				</div>
				<div className="card-body card-bg-gray px-4 py-5">
					<div className="text-14">Already have an account?</div>
					<div className="mb-4">
						<Link className="card-link" to={'/signin'}>Login</Link>
					</div>
					<div className="card-text-bold mb-2">You will be redirecred to eBay so you can login and authorise access for the BotPricer application</div>
					<div className="card-note mb-4">NOTE: we only access your product information in order to provide alerts and reprice per your rules. You can revoke the permission from your eBay account at any time.</div>
					<form autoComplete="off" id="form_signup" method="post" acceptCharset="utf-8">
						<div className="form-check mb-3">
							<input className="form-check-input" type="checkbox" value="" id="chkAgree" name="chkAgree"/>
							<label className="form-check-label" htmlFor="chkAgree">
								I agree to terms and conditions
							</label>
						</div>
						<button type="button" className="btn btn-primary pt-2 pb-1 px-4" onClick={ userConsent }>
							Continue to eBay 
							<span className="btn-next-icon ms-2"><i className="bi bot-next"></i></span>
						</button>
					</form>
				</div>
			</div>
		</div>
	</>
}