import $ from 'jquery';
import 'jquery-validation';
import {useState} from 'react';
import { Link } from 'react-router-dom';
import Api from '../Api';
import * as constants from '../Constants'

export default function Forget(params){
	
	window.document.title = params.title;


	var [isSpinner, setSpinner] = useState(false);
	$(function(){
		//alert("hello");
		$("#form_forgot_password").validate({
			rules: {
				txtUsername : {
					required: true,
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid"
		});
	});
	const submitAction = (e) =>{
		e.preventDefault();
		if($("#form_forgot_password").valid()){
			setSpinner(true);
			let data = {
				txtUsername : $('#txtUsername').val(),
			}
			Api('forget', constants.prepareData('POST', data)).then((res) => {
				
				setSpinner(false);
			});
		}
	}
	const button = () => {
		if(isSpinner){
			return <button type="button" className="btn btn-primary px-5 py-1" disabled>
				<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
				<span className="action">Send Email</span>
			</button>
		}else{
			return <button type="button" className="btn btn-primary px-5 py-1" onClick={submitAction}>
				<span className="action">Send Email</span>
			</button>
		}
	}
	return <>
		<div className="form-signup my-5">
			<div className="card card-border">
				<div className="card-header card-title position-relative">
					<div className="ms-3 position-absolute top-50 start-0 translate-middle-y">
						<h5 className="page-title"><Link to={-1}><i className="bi bot-back"></i></Link>&nbsp; Reset Password</h5>
					</div>
				</div>
				<div className="card-body card-bg-gray px-4 py-5">
					<form autoComplete="off" id="form_forgot_password" method="post" acceptCharset="utf-8" onSubmit={(e) => submitAction(e)}>
						<div className="mb-3">
							<label htmlFor="txtUsername" className="form-label">Username or Email</label>
							<input type="text" className="form-control" id="txtUsername" name="txtUsername" placeholder="Username"/>
						</div>
						{ button() }
					</form>
				</div>
			</div>
		</div>
	</>
}