import face from '../../assets/images/faces/1.jpg';
import {Link, useNavigate} from 'react-router-dom';
import * as constants from '../../Constants';
import { useEffect, useState } from 'react';
import Api from '../../Api';

function Sidebar(params){
	let site_url = constants.ADMIN_PATH;
	let location = (window.location.pathname).replace(site_url, '');
	
	const [is_logged_in, setIsLoggedIn] = useState(true);
	
	const navigate = useNavigate();

	useEffect(() => {
		constants.Resize();
		if(window.location.pathname === '/admin'){
			navigate('dashboard');
		}
		let token = (localStorage.hasOwnProperty('auth')) ? JSON.parse( localStorage.getItem('auth')) : null;
		let time = Math.round(Date.now()/1000);
		if(token !== null && token.expires < time){
			localStorage.removeItem('admin_auth');
			setIsLoggedIn(false);
		}

	}, [location])

	useEffect(() => {
		
		constants.Resize();
		
		Api("current-user", constants.prepareData("POST")).then((res) => {
			//console.log(res);
			if(res.status == 200){
				var current_admin = {
					preference : res.preference,
					admin_tags: res.admin_tags,
				}
				sessionStorage.setItem('current_admin', JSON.stringify(current_admin));
			}
		})

	}, []);

	useEffect(() => {
		if(!is_logged_in){
			navigate("/admin");
		}
	}, [is_logged_in])

	

	function logout(){
		localStorage.removeItem('admin_auth');
	}
	
	var settings = ['master-settings', 'user-settings']
	
	
	return <>
			<div id="sidebar" className="active hidden-sm ">
				<div className="sidebar-wrapper active">
					<div className="sidebar-header">
						<div className="d-flex justify-content-between align-items-center">
							<div className="logo m-3">
								<Link to={site_url}><img src={params.logo} alt="BotPricer"/></Link>
							</div>
						</div>
						
						<div className="d-flex align-items-center header-bg">
							<div className="avatar avatar-lg offset-1 mt-2 mb-2">
								<img src={face} className="p-1" alt="Face 1"/>
							</div>
							<div className="d-flex profile-div">
								<button className={settings.includes(location) ? "btn profile-btn show" : "btn profile-btn"} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-reference="parent" id="profile">
									<div className="row">
										<div className="col-9 text-start">
											<span className="fw-bold">Admin</span>
										</div>
										<div className="col-3"><i className="bi bi-caret-down-fill"></i></div>
									</div>
								</button>
							<div className={settings.includes(location) ? "dropdown-menu show" : "dropdown-menu"} aria-labelledby="profile" style={settings.includes(location) ? {position: "absolute", inset: "0px auto auto 0px", margin: "0px", transform: "translate(0px, 152px)"} : {}}>

									<Link className={location == 'master-settings' ?  "dropdown-item active" : "dropdown-item"} to={site_url + "master-settings"}> 
										<i className="bi bot-preference pe-2"></i>
										Master Settings
									</Link>
									<Link className={location == 'user-settings' ?  "dropdown-item active" : "dropdown-item"}  to={site_url + "user-settings"}>
										<i className="bi bot-settings pe-2"></i>
										User Settings
									</Link>
									<div className="dropdown-divider"></div>
									<Link className="dropdown-item text-danger" to={site_url } onClick={logout}> 
										<i className="bi bot-logout text-danger pe-2"></i>
										Log out
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="sidebar-menu">
					<ul className="menu">
						<li className={location == 'dashboard' ? "sidebar-item active" : "sidebar-item"}>
							<Link to={site_url + "dashboard"} className='sidebar-link'>
								<i className="bi bot-dashboard text-primary"></i>
								<span>Dashboard</span>
							</Link>
						</li>
						<li className={location == 'customers' ? "sidebar-item active" : "sidebar-item"}>
							<Link to={site_url + 'customers'} className='sidebar-link'>
								<i className="bi bot-people text-primary"></i>
								<span>Customers</span>
							</Link>
						</li>
						<li className={location == 'staff' ? "sidebar-item active" : "sidebar-item"}>
							<Link to={site_url + 'staff'} className='sidebar-link'>
								<i className="bi bot-people text-primary"></i>
								<span>Staff</span>
							</Link>
						</li>
						<li className={location == 'subscriptions' ? "sidebar-item active" : "sidebar-item"}>
							<Link to={site_url + 'subscriptions'} className='sidebar-link'>
								<i className="bi bot-subscription text-primary"></i>
								<span>Subscriptions</span>
							</Link>
						</li>
						<li className={location == 'system-error' ? "sidebar-item active" : "sidebar-item"}>
							<Link to={site_url + 'system-error'} className='sidebar-link'>
								<i className="bi bi-bug-fill"></i>
								<span>System Error</span>
							</Link>
						</li>
					</ul>
					</div>
				</div>
			</div>

			<div className="modal fade" id="modalDeleteConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="System Error" aria-hidden="true">
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="error_title">Confirm Deletion</h5>
						</div>
						<div className="modal-body">
							<div className="modal-form">
								<input type="hidden" id="hdnDeleteID" name="hdnDeleteID" value=""/>
								<label htmlFor="txtAllowedItems" className="mx-4 mb-5">Are you sure you want to delete this error?</label>
							</div>
							<button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-info ms-5 mb-4 px-5">Cancel</button>
							<button type="submit" className="btn btn-danger ms-3 mb-4 px-5">Delete</button>
						</div>
					</div>
				</div>
			</div>
		</>

}

export default Sidebar;