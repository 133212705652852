import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Tagify from '../../assets/js/Tagify/tagify';
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'
import { format } from "date-fns";

export default function Customers(params){

	window.document.title = params.title;
	let navigate = useNavigate(); 
	let blockModal = useRef(null), unblockModal = useRef(null), editModal = useRef(null), deleteModal = useRef(null), paymentHistoryModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [button_spinner, setButtonSpinner] = useState(false);

	let [history_cur_page, setHistoryCurPage] = useState(1);
	let [history_max_page, setHistoryMaxPage] = useState(1);
	let [history_spinner, setHistorySpinner] = useState(false);
	let [history_result, setHistoryResult] = useState({});

	//customer form data
	let [hdnCustomerId, sethdnCustomerId] = useState('');
	let [txtFirstName, settxtFirstName] = useState('');
	let [txtLastName, settxtLastName] = useState('');
	let [txtEmail, settxtEmail] = useState('');
	let [txtStreet, settxtStreet] = useState('');
	let [txtCity, settxtCity] = useState('');
	let [txtZipCode, settxtZipCode] = useState('');
	let [txtCountry, settxtCountry] = useState('');
	
	let [lblMemberSince, setlblMemberSince] = useState('');
	let [drpSubscriptionPlan, setdrpSubscriptionPlan] = useState('');
	let [action, setAction] = useState(false);
	let [txtDataTableSearchByTag, settxtDataTableSearchByTag] = useState(null);
	let [txtDataTableSearchByName, settxtDataTableSearchByName] = useState(null);
	let [drpDataTableSearchSubscriptionType, setdrpDataTableSearchSubscriptionType] = useState();
	

	const [cust_tags, setCustTags] = useState(null);
	const[current_admin, setCurrentAdmin] = useState(JSON.parse(sessionStorage.getItem('current_admin')));
	let [tagify, setTagify] = useState(null);

	const tagOptions = {
		delimiters	: null,
		editTags : false,
		enforceWhitelist : true,
		//whitelist: current_user.admin_tags,
		dropdown : {
			enabled       : 0,         // show the dropdown immediately on focus
			maxItems      : 8,         // place the dropdown near the typed text
			closeOnSelect : false,     // keep the dropdown open after selecting a suggestion
			highlightFirst: true,
			searchKeys: ["value"],
			width : '100%',
		},
		templates : {
			dropdownItem: function(tagData){
				return `<li ${this.getAttributes(tagData)} class='tagify__dropdown__item list-group-item ${tagData.class ? tagData.class : ""}' >
					<span>${tagData.value}</span>
				</div>`
			}
		},
	}
	useEffect(() => {
		var input = document.querySelector('#txtDataTableSearchByTag');
		tagify = new Tagify(input, tagOptions);

		if(current_admin != null){
			tagify.whitelist = current_admin.admin_tags;
			setTagify(tagify);
		}
	}, [current_admin, sessionStorage])

	
	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val() != undefined ? $("#page_length").val() :  constants.DEFAULT_RECORD_LIMIT;
		
		let data = {
			start : (cur_page-1) * page_length,
			length : page_length,
			search_by_name : txtDataTableSearchByName,
			search_by_tag : txtDataTableSearchByTag,
			search_by_subscription : drpDataTableSearchSubscriptionType,
		}
		//console.log("data: ",data);
		Api('customers', constants.prepareData('POST', data)).then((res) => {
			if(res.status !== 401){
				
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	

 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])


	useEffect(() => {
		loadTable();
	}, [cur_page,txtDataTableSearchByName,txtDataTableSearchByTag,drpDataTableSearchSubscriptionType])

	

	const fillTableRow = (cust, idx) => {
			/*if(order.order_status == 2){
				var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
					<i className="bi bot-resume text-warning"></i>
				</button> 
			}else {
				var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
					<i className="bi bot-hold text-warning"></i>
				</button> 
			}*/
			var add_tag = <>Add Tag</>
			var tag_icon = <i  className="bi bi-plus-circle-fill text-primary pe-1"></i>
			if(cust.tag_ids != null && cust.tag_ids != ''){
				tag_icon = <i  className="bi bi-tag-fill text-primary pe-1"></i>
				//console.log(cust.tags);
				add_tag = cust.tags.map((val, i) => 
					<>
						<span key={"tag_"+i} className="badge bg-light-default mx-1">{val.value}</span>
					</>
				)
			}

		return <>
			<tr key={"cust_"+idx}>
				<td>{result.list.start+idx}</td>
				<td>{cust.user_name} </td>
				<td>{cust.first_name+ ' ' +cust.last_name} </td>
				<td><button className="btn px-0"  data-bs-toggle="modal" data-bs-target="#tagsCustomers" onClick={() =>{
					setCustTags(JSON.stringify(cust.tags));
					sethdnCustomerId(cust.user_id)
				} }>
					{tag_icon} {add_tag}
				</button>
				</td>
				<td><span className="radius-10 badge bg-light-info">{(cust.order_is_trial == 1) ? 'TRIAL' : cust.subscription_name}</span></td>
				<td>{cust.subscription_start_date.display}</td>
				<td>
					<button className="btn" onClick={() => loginAsCustomer(cust.user_id)}><i className="bi bot-login text-info"></i></button>
					{/*
					<button className="btn " data-bs-toggle="modal" data-bs-target="#pause_order" onClick={() => editCustomer(cust)} ><i data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top" className="bi bot-hold text-warning"></i></button>
					*/}
					<button className="btn" data-bs-toggle="modal" data-bs-target="#add_edit_customers" onClick={() => editCustomer(cust)}><i className="bi bot-edit-fill text-primary"></i></button>
					<button className="btn" data-bs-toggle="modal" data-bs-target="#delete_customer"><i className="bi bot-trash text-danger-light"></i></button>
					<button className="btn" data-bs-toggle="modal" data-bs-target={ (cust.is_enabled) ? "#block_customer" : "#unblock_customer" } onClick={() => {
							sethdnCustomerId(cust.user_id);
							setAction(!cust.is_enabled)
						}
					}><i className={
						(!cust.is_enabled) ? "bi  bot-block-user text-danger" : "bi bot-block-user text-success"
					}></i></button>
				</td>
			</tr>
		</>
	}

	const loginAsCustomer = (user_id = null) => {
		
		if(user_id != null){
			let data = {user_id};
			Api("customers/login", constants.prepareData('POST', data)).then((res)=>{
				if(res.hasOwnProperty('access_token')){
					let auth = {
						token : res.access_token,
						expires : res.expires,
						type : res.type
					};
					localStorage.setItem("auth", JSON.stringify(auth));
					localStorage.setItem("current_user", JSON.stringify(res.user));
					window.open(constants.SITE_PATH + '/dashboard', "_blnak");
				}
			});
		}
	}
	const prevHistoryPage = () =>{
		if(history_cur_page > 1){
			setHistoryCurPage(--history_cur_page)
		}else{ 
			setHistoryCurPage(1);
		}
	}
	const nextHistoryPage = () =>{
		if(history_cur_page < history_max_page){
			setHistoryCurPage(++history_cur_page)
		}else{
			setHistoryCurPage(history_max_page)
		}
	}
	const selectCustomer = (hdnCustomerId) =>{
		hdnCustomerId=hdnCustomerId;
		setHistoryCurPage(1);
		setHistoryMaxPage(1);
		
	}
	const viewPaymentHistory = () => {
		let user_id=hdnCustomerId;
		if(user_id){
			setHistorySpinner(true);
			
			let page_length = $(".page_length_historytable").val() != undefined ?  $(".page_length_historytable").val() :  constants.DEFAULT_RECORD_LIMIT;
			
			let data = {
				start : (history_cur_page-1) * page_length,
				length : page_length,
				user_id : user_id
			}
			
			Api("customers/payment-history", constants.prepareData('POST', data)).then((res)=>{
				if(res.status !== 401){
					if(res.hasOwnProperty('list')){
						setHistoryResult(res);
						setHistoryMaxPage(res.list.total_pages);
						//setHistoryCurPage((res.list.total_pages > history_cur_page ) ? res.list.total_pages : history_cur_page);
						setHistorySpinner(false);
					}
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});
		}
	}
	useEffect(() => {
		viewPaymentHistory();
	}, [history_cur_page]) 

	const fillHistoryTableRow = (hist, idx) => {
		
			return <>
			<tr key={"pay_his_"+idx }>
				<td>{history_result.list.start+idx}</td>
				<td>{hist.user_order_id} </td>
				<td>{constants.formatDate(hist.payment_date)} </td>
				<td>{hist.payment_mode} </td>
				<td>{hist.currency}{hist.transaction_fee} </td>
				<td>{hist.currency}{hist.transaction_amount} </td>
				<td>{hist.currency}{hist.subscription_amount} </td>
				<td>{hist.subscription_name} </td>
				<td>{hist.currency}{hist.offer_type > 0 ? hist.discount_amount : '0.00'} </td>
				<td>{hist.currency}{hist.total_amount} </td>
				<td>{hist.paypal_subscription_id} </td>
				<td>{hist.stripe_subscription_id} </td>
				<td>{hist.payment_state} </td>

			</tr>
		</>
	}

	const editCustomer = (cust = null) => {
		sethdnCustomerId((cust !== null) ? cust.user_id : '');
		settxtFirstName((cust !== null) ? cust.first_name : '');
		settxtLastName((cust !== null) ? cust.last_name : '');
		settxtEmail((cust !== null) ? cust.user_email : '');
		settxtStreet((cust !== null) ? cust.street_name : '');
		settxtCity((cust !== null) ? cust.city_name : '');
		settxtZipCode((cust !== null) ? cust.zipcode : '');
		settxtCountry((cust !== null) ? cust.user_country : '');
		setdrpSubscriptionPlan((cust !== null) ? cust.user_active_subscription_id
		: '');
		setlblMemberSince((cust !== null) ? cust.created_date : '');

	}

	const saveCustomer = (e) => {
		if($("#form_customer").valid()){
			setButtonSpinner(true)
			let data = {
				hdnCustomerId, txtFirstName, txtLastName, txtEmail, txtStreet, txtCity, txtZipCode, txtCountry, drpSubscriptionPlan
			}

			Api("customers/save", constants.prepareData("POST", data)).then((res) => {
				setButtonSpinner(false);
				editModal.current.click();
				if(res.status !== 401){
					if(res.hasOwnProperty('list')){
						setResult(res);
						setMaxPage(res.list.total_pages);
						setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
						setSpinner(false);
					}
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			})
		}
	}

	const resetForm = (form_element) => {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();

		sethdnCustomerId('');
		settxtFirstName('');
		settxtLastName('');
		settxtEmail('');
		settxtStreet('');
		settxtCity('');
		settxtZipCode('');
		settxtCountry('');
		setdrpSubscriptionPlan('');
		setlblMemberSince('');
	}

	const saveCustomerTags = (body, tagSpinner, dialogRef) => {
		body.userId = hdnCustomerId;
		
		
		//return false;
		Api("customers/save-tags", constants.prepareData("POST", body)).then((res) => {
			tagSpinner(false);

			if(res.status !== 401){
				if(res.status == 200){
					current_admin.admin_tags = res.admin_tags;

					tagify.whitelist = res.admin_tags;
					setTagify(tagify);
					setCurrentAdmin(current_admin);
					sessionStorage.setItem('current_admin', JSON.stringify(current_admin));
					dialogRef.current.click();
					loadTable();
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		})
	}

	const blockCustomer = () => {
		let data = {
			user_id : hdnCustomerId, 
			is_enabled : (action) ? 1 : 0
		}
		
		Api("customers/block", constants.prepareData("POST", data)).then((res) => {
			if(!action){
				blockModal.current.click();
			}else{
				unblockModal.current.click();
			}
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		})
	};

	const deleteCustomer = () => {
		let data = {
			user_id : hdnCustomerId, 
			is_enabled : (action) ? 1 : 0
		}
		Api("customers/delete", constants.prepareData("POST", data)).then((res) => {
			deleteModal.current.click();
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		})
	};
	
	const pauseOrder = () => {
		let data = {
			user_id : hdnCustomerId,
			status : action,
		}
		Api("customers/pause-order", constants.prepareData("POST", data)).then((res) => {
			deleteModal.current.click();
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		})
	}

	$(() => {
		$("#form_customer").validate({
			onkeyup: false,
			rules: {
				txtFirstName : {
					required: true,
				},
				txtLastName : {
					required: true,
				},
				txtStreet : {
					required: true,
				},
				txtEmail : {
					required: true,
					remote: {
						url: constants.API_URL + "check-email",
						type: "post",
						dataType:"json",
						data: {
							//id: $("#hdnCustomerId").val()
							id: () => {
								return $("#hdnCustomerId").val()
							}
						}
					}
				},
				txtCity : {
					required: true,
				},
				txtZipCode : {
					required: true,
					digits: true
				},
				txtCountry : {
					required: true,
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",

		});
	})
		
	
	return <>
			<section className="section">
				<div className="row mb-3">
					<div className="col-6 col-md-3">
						<h5 className="page-title">Customers</h5>
					</div>
					{/*
					<div className="col-md-3">
						<select className="form-select" multiple="multiple" defaultValue={''} id="drpDataTableSearchByTag" name="drpDataTableSearchByTag" onChange={(e) => setdrpDataTableSearchByTag(e.target.value)}>
							<option value="">Select Tag</option>
							{
								(current_admin.hasOwnProperty('user_tags')) &&
								current_admin.user_tags.map((tag, idx) =>
									<option key={idx} value={tag.id} > {tag.value}</option>
								)
							}
						</select>
					</div>
					*/}
					<div className="col-md-3">
						<div className="form-group has-icon-left">
							<div className="position-relative">
								<input type="text" className="form-control" placeholder="Search by tag" id="txtDataTableSearchByTag" name="txtDataTableSearchByTag" value={txtDataTableSearchByTag} onChange={ (e) =>  settxtDataTableSearchByTag(e.target.value)}/>
								<div className="form-control-icon">
									<i className="bi bi-search f-color"></i>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="form-group has-icon-left">
							<div className="position-relative">
								<input type="text" className="form-control" placeholder="Search by name" id="txtDataTableSearchByName" name="txtDataTableSearchByName" onKeyUp={
								(e) =>  settxtDataTableSearchByName(e.target.value)}/>
								<div className="form-control-icon">
									<i className="bi bi-search f-color"></i>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<select className="form-select" defaultValue={''} id="drpDataTableSearchSubscriptionType" name="drpDataTableSearchSubscriptionType" onChange={(e) => setdrpDataTableSearchSubscriptionType(e.target.value)}>
							<option value="" key={'op_sub_'}>Subscription Type</option>
							{
								(result.hasOwnProperty('subscription_type')) &&
								result.subscription_type.map((sub, idx) =>
									<option key={"op_sub_"+idx} value={sub.subscription_id} > {sub.subscription_name} - {sub.subscription_currency}</option>
								)
							}
						</select>
					</div>
				</div>
				<div className="row">
					<div className="col-12 table-responsive mb-2">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>ID</th>
									<th>Username</th>
									<th>Display Name</th>
									<th>Tag</th>
									<th>Current Subscription</th>
									<th>Subscription Date</th>
									<th width="300px">Actions</th>
								</tr>
							</thead>
							<tbody>
							{
								spinner === true ?
								<><tr><td colSpan={7} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
								:
								(spinner === false && (result != undefined && result.hasOwnProperty('list')) ) ?
								result.list.data.map((sub, idx) => 
									fillTableRow(sub, idx)
								)
								: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
							}
							</tbody>
						</table>
					</div>
					{
						result.hasOwnProperty('list') && 
						constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
					}
				</div>
			</section>

			<div className="modal fade" id="customers_payment_history" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Payment History</h5>
							<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={paymentHistoryModal}><i className="bi bi-x-circle-fill"></i></button>
						</div>
						<div className="modal-body">
							<div className="row m-0">
								<div className="col-12 table-responsive mb-2 p-0">
									<table className="table table-striped mb-0">
										<thead>
											<tr>
												<th>ID</th>
												<th>User Order ID</th>
												<th>Payment Date</th>
												<th>Payment Mode</th>
												<th>Transaction Fee</th>
												<th>Transaction Amount</th>
												<th>Subscription Amount</th>
												<th>Subscription Name</th>
												<th>Discount Amount</th>
												<th>Total Amount</th>
												
												<th>Paypal Subscription ID</th>
												<th>Stripe Subscription ID</th>
												<th>Payment State</th>
											</tr>
										</thead>
										<tbody>
										{
											history_spinner === true ?
											<><tr><td colSpan={15} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
											:
											(history_spinner === false && (history_result != undefined && history_result.hasOwnProperty('list')) ) ?
											history_result.list.data.map((sub, idx) => 
												fillHistoryTableRow(sub, idx)
											)
											: <><tr><td colSpan={15} className="text-center"> No data found</td></tr></>
										}
											
											
										</tbody>
									</table>
								</div>
								{
									history_result.hasOwnProperty('list') && 
									constants.pagination(history_result.list, history_cur_page, nextHistoryPage, prevHistoryPage, viewPaymentHistory,'historytable')
								}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade" id="add_edit_customers" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Subscription" aria-hidden="true">
				<div className="modal-dialog modal-md">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="Subscription">Edit Customer</h5>
							<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={editModal}><i className="bi bi-x-circle-fill" onClick={() => resetForm("#form_customer")}></i></button>
						</div>
						<div className="modal-body">
							<form autoComplete="off" id="form_customer" method="post" acceptCharset="utf-8">
							<input type="hidden" name="hdnCustomerId" id="hdnCustomerId" defaultValue={hdnCustomerId}/>
								<div className="modal-form">
									<div className="row m-2">
										<div className="col-md-6">
											<label htmlFor="txtFirstName" className="form-label">First Name</label>
											<input type="text" className="form-control" id="txtFirstName" name="txtFirstName" placeholder="Enter First name" defaultValue={txtFirstName} onChange={(e) => settxtFirstName(e.target.value)}/>
										</div>
										<div className="col-md-6">
											<label htmlFor="txtLastName" className="form-label">Last Name</label>
											<input type="text" className="form-control " id="txtLastName" name="txtLastName" placeholder="Enter Last name" defaultValue={txtLastName} onChange={(e) => settxtLastName(e.target.value) }/>
										</div>
									</div>
									<div className="m-4">
										<label htmlFor="txtEmail" className="form-label">Email address</label>
										<input type="text" className="form-control" id="txtEmail" name="txtEmail" placeholder="Enter Email address" defaultValue={txtEmail} onChange={(e) => settxtEmail(e.target.value) } />
									</div>
									<div className="m-4">
										<label htmlFor="txtStreet" className="form-label">Street address</label>
										<input type="text" className="form-control" id="txtStreet" name="txtStreet" placeholder="Enter Street address" defaultValue={txtStreet} onChange={(e) => settxtStreet(e.target.value) }/>
									</div>
									<div className="row m-2">
										<div className="col-md-6">
											<label htmlFor="txtCity" className="form-label">City</label>
											<input type="text" className="form-control" id="txtCity" name="txtCity" placeholder="Enter City" defaultValue={txtCity} onChange={(e) => settxtCity(e.target.value) }/>
										</div>
										<div className="col-md-6">
											<label htmlFor="txtZipCode" className="form-label">ZIP code</label>
											<input type="text" className="form-control" id="txtZipCode" name="txtZipCode" placeholder="Enter ZIP code" defaultValue={txtZipCode} onChange={(e) => settxtZipCode(e.target.value) }/>
										</div>
									</div>
									<div className="m-4">
										<label htmlFor="txtCountry" className="form-label">Country</label>
										<input type="text" className="form-control" id="txtCountry" name="txtCountry" placeholder="Enter Country" defaultValue={txtCountry} onChange={(e) => settxtCountry(e.target.value) }/>
									</div>
								</div>
								<hr className="modal-divider"/>
								<div className="modal-form">
									<div className="m-4">
										<label htmlFor="txtCountry" className="form-label">Subscription Plan</label>
										<div className="col-md-6">
											<select className="form-select form-select-white" data-live-search="true" id="drpSubscriptionPlan" name="drpSubscriptionPlan" aria-label="Default select example" value={drpSubscriptionPlan} onChange={(e) => setdrpSubscriptionPlan(e.target.value)}>
												<option className="selectSP" key="op_" value={''}>Subscription Plan</option>
												{
													(result.hasOwnProperty("subscription_type")) &&
													result.subscription_type.map((sub, idx) =>
														<option key={"op_"+idx} value={sub.subscription_id} defaultValue={drpSubscriptionPlan}> {sub.subscription_name} - {sub.subscription_currency}</option>
													)
												}
											</select>
										</div>
										<span className="sub-since-customer">Member Since: {constants.formatDate(lblMemberSince,'dd.mm.yyyy')}</span>
									</div>
									<div className="m-4">
										<a href="" className="payment-history" data-bs-toggle="modal" data-bs-target="#customers_payment_history" onClick={() => {
													selectCustomer(hdnCustomerId);
													viewPaymentHistory()
												}
											} >
											View payment history
										</a>
									</div>
								</div>
								<button type="button" id="btnSave" name="btnSave" className="btn btn-primary ms-5 mb-4 px-4" onClick={(e) => saveCustomer(e)} disabled={button_spinner ? "disabled" : ""}>
								{ 
									button_spinner && 
									<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								}
									Save Customer
								</button>
								<button type="button" id="btnCustomerLogin" name="btnCustomerLogin" data-loginid = '' onClick={() => loginAsCustomer(hdnCustomerId)} className="btn btn-info ms-5 mb-4 px-5">Log in as</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			
			{
				constants.confirmDialog({
					id: "delete_customer",
					title : "Delete Customer", 
					action : "Delete",
					msg : "Are you sure to delete this customer?",
					callback : deleteCustomer,
					cancelConfirm : deleteModal,
					color : "danger"
				})
			}

			
			{
				constants.confirmDialog({
					id: "block_customer",
					title : "Block Customer", 
					action : "Block",
					msg : "Are you sure to block this customer?",
					callback : blockCustomer,
					cancelConfirm : blockModal,
					colors : "danger",
				})
			}

			{
				constants.confirmDialog({
					id: "unblock_customer",
					title : "Unblock Customer", 
					action : "Unblock",
					msg : "Are you sure to unblock this customer?",
					callback : blockCustomer,
					cancelConfirm : unblockModal,
					colors : "success",
				})
			}

			{
				constants.confirmDialog({
					id: "pause_order",
					title : "Pause Order", 
					action : "Pause",
					msg : "Are you sure to pause this order?",
					callback : pauseOrder,
					cancelConfirm : unblockModal,
					colors : "warning",
				})
			}
			
			<constants.AddTag 
				id="tagsCustomers" 
				action={saveCustomerTags}
				defaultValue={cust_tags}
				setChanged={setCustTags}
				tags={current_admin?.admin_tags}
				/>
		</>

}
