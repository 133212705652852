import { Link, Route, Routes, useLocation } from 'react-router-dom';
import './assets/css/front.css'
import Nav from './Front/Nav';
import Pricing from './Front/Pricing';
import Signup from './Front/Signup';
import Signin from './Front/Signin';
import EbayVerify from './Front/EbayVerify';
import Register from './Front/Register';
import Forget from './Front/Forget';
import NewPassword from './Front/NewPassword';
import Contact from './Front/Contact';
import Home from './Front/Home';

const pricingRoutes = ['/pricing', '/pricing/:pay_status/:reference_id/:session_id']

export default function FrontApp(params){

	var location = useLocation();
	var isNav = true;

	if(location.pathname !== '/'){
		var uri_segments = location.pathname.split("/");
		
		uri_segments.forEach((path, i) => {
			if(i > 0 && (path == 'signin' || path == 'signup')){
				isNav = false;
			}
		})
	}
	

	return <>
		{
			isNav ? <Nav logo={params.logo}/> : <div className="text-center my-5">
			<Link to={"/"}><img src={params.logo} alt="BotPricer" width="200px"/></Link>
		</div>
		}
		<Routes>
			<Route path="/" element={<Home title="RepriceBot"/>} />
			<Route path="/signup" element={<Signup title="RepriceBot - Create an account"/>} />
			<Route path="/signin" element={<Signin title="RepriceBot - Log In"/>} />
			<Route path="/ebay/verify-oauth" element={<EbayVerify />} />
			<Route path="/register" element={<Register title="RepriceBot - Setup an account"/>} />
			<Route path="/forgot-password" element={<Forget title="RepriceBot - Reset Password"/>} />
			<Route path="/reset-password" element={<NewPassword  title="RepriceBot - Set New Password"/>} />
			<Route path="/contact" element={<Contact  title="RepriceBot - Contact Us"/>} />
			{
				pricingRoutes.map((path,i)=>(<Route path={path} key={i}  element={<Pricing  title="RepriceBot - Pricing"/>}/>))
			}
			<Route path="/*" element={<Pricing  title="RepriceBot - Pricing"/>}></Route>
		</Routes>
	</>
}