import { useEffect, useRef, useState } from 'react';
import default_img from '../assets/images/image.png';
import $ from 'jquery';
import '../assets/js/bootstrap-datepicker.min.js';
import Api from "../Api";
import * as constants from '../Constants'
import { Link, useParams } from 'react-router-dom';

export default function ItemVariation(params){
	
	window.document.title = params.title;
	const {itemId} = useParams();
	let deleteModal = useRef(null);

	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [itemEdit, setItemEdit] = useState(false);
	let [button_spinner, setButtonSpinner] = useState(false);
	let [filterData, setFilterData] = useState({});
	let [variation_detail, setVariationDetail] = useState({});
	let [checkedItem, setCheckedItem] = useState([]);
	let [selectAll, setSelectAll] = useState(false);
	let [variation_tags, setVariationTags] = useState(false);

	const[current_user, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem('current_user')));

	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		let data = {
			itemId : itemId,
			start : (cur_page-1) * page_length,
			length : page_length,
			filterData
		}
		Api('item-variations', constants.prepareData('POST', data)).then((res) => {
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages && res.list.total_pages !== 0) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('auth');
				sessionStorage.removeItem('current_user');
				setIsLoggedIn(false);
			}
		});
	}

	
	const saveVariationFomo = (body, btnSpinner, dialogRef) => {
		
		body.itemId = variation_detail.item_id;
		body.itemVariationId = variation_detail.variation_id;
		btnSpinner(true);
		
		Api('item-variations/save-fomo', constants.prepareData('POST', body)).then(resp => {

			if(resp.status == 200){
				dialogRef.current.click();
				btnSpinner(false);
				if(resp.hasOwnProperty('reloadTable')){
					resp.reloadTable();
				}
				loadTable();
			}
		})
	
	}

	const saveEditVariation = (body, btnSpinner, dialogRef) => {
		body.itemId = variation_detail.item_id;
		body.variationId = variation_detail.variation_id;

		Api('item-variations/save-item-details', constants.prepareData('POST', body)).then(resp => {
			btnSpinner(false);
			if(resp.status == 200){
				dialogRef.current.click();
				loadTable()
			}
		})
	}

	const saveVariationTags = (body, btnSpinner, dialogRef) => {
		body.itemId = variation_detail.item_id;
		body.variationId = variation_detail.variation_id;

		Api('item-variations/save-tags', constants.prepareData('POST', body)).then(tag_res => {
			
			btnSpinner(false);

			if(tag_res.hasOwnProperty('user_tags')){
				current_user.user_tags = tag_res.user_tags;
				setCurrentUser(current_user);
				sessionStorage.setItem(JSON.stringify(current_user));
			}
			
			dialogRef.current.click();
			loadTable();
			
		});
	}
	
	useEffect(() => {
		loadTable();
	}, [cur_page, filterData])

	const deleteItemVariation = (btnSpinner, dialogRef) => {
		//console.log("delete Items",item_detail);
		btnSpinner(true);
		let data = {
			itemVariationId : variation_detail.variation_id,
		}
		Api("item-variations/delete", constants.prepareData("POST", data)).then((res) => {
			btnSpinner(false);
			if(res.status == 200){
				dialogRef.current.click();
				loadTable()
			}
			
		})
	}
	
	const handleSelectAll  = (event) => {
		setCheckedItem([]);
		setSelectAll(event.target.checked);
		if(event.target.checked){
			if(result.hasOwnProperty('list') && result.list.total_records > 0){
				result.list.data.map((variations, i) => {
					checkedItem[i] = variations.variation_id
				})
				
				setCheckedItem(checkedItem);
			}
		}
	}
	const handleItemCheckbox = (event) => {
		if(event.target.checked){
			if(checkedItem.findIndex((data) => data == event.target.value) === -1) {
				setCheckedItem([...checkedItem, event.target.value])
			}
		}else{
			setCheckedItem((checkedItem) => 
				checkedItem.filter((variations) => variations !== event.target.value)
			)
		}
	}

	const fillTableRow = (variations, key) => {
		
		var add_tag = <>Add Tag</>
		var tag_icon = <i  className="bi bi-plus-circle-fill text-primary pe-1"></i>
		if(variations.variation_tag != null && variations.variation_tag != ''){
			tag_icon = <i  className="bi bi-tag-fill text-primary pe-1"></i>
			//console.log(cust.tags);
			add_tag = variations.tags.map((val, i) => 
				<>
					<span className="badge bg-light-default mx-1">{val.value}</span>
				</>
			)
		}
		return <>
			<tr key={key}>
				<td>
					<constants.CheckBox 
						value={variations.variation_id}
						className="form-check-input" 
						handleEvent = {handleItemCheckbox}
						isChecked={(checkedItem.findIndex((data) => data == variations.variation_id) != -1)}
					/>
				
				</td>
				<td>
					<div  className="row">
						<div className="col-12 col-md-4 d-none">
							<img src={variations.item_image != null ? variations.item_image : default_img} onError={({ currentTarget }) => {
								currentTarget.src = default_img;
							}} className="img-thumbnail"/>
						</div>
						<div className="col-12 col-md-8">
							<div className="item-title">{variations.variation_title}</div>
							<div> 
								<span className="text-secondary">ID: </span>
								<span className="sub-promo">{variations.variation_id}</span>
							</div>
							<div className="d-none"> 
								<span className="radius-10 badge bg-promo sub-promo"><i className="bi bot-global"></i>costco.com.au</span>
							</div>
							
							<a className='btn btn-sm d-none' data-bs-target="#itemDetails" data-bs-toggle="modal" onClick={() => setVariationDetail(variations)}><span className="item-detail mx-1" >View Details </span></a>							
						</div>
					</div>
				</td>
				<td>
					<button className="btn px-0" data-bs-toggle="modal" data-bs-target="#userVariationTags" onClick={() => {
						setVariationTags(JSON.stringify(variations.tags));
						setVariationDetail(variations)
					}}>
						{tag_icon} {add_tag}
					</button>
				</td>
				<td className="text-center">
					<div className="item-price  d-none">{variations.variation_item_cost} / {variations.variation_item_price}</div>
					<div className="item-price">{variations.variation_item_price}</div>
					<div className="m-1 radius-10 badge bg-light-success  d-none">
						<i className="bi bot-dollar-circle text-success"></i>
						<span className="align-text-top">Profit: $0.00</span>
					</div>
					{
						variations.variation_qty == 0 &&
						<div className="ms-2">
							<span className="radius-10 badge bg-light-danger font-12 ">Out of stock</span>
						</div>	
					}
				</td>
				<td className="text-center">{variations.variation_fomo_qty}</td>
				<td className="text-center">{variations.variation_qty}</td>
				<td width="15%">
					<button className={`btn m-1 badge font-12 d-grid ${(variations.variation_fomo_qty > 0) ? "bg-light-warning" : "bg-light text-secondary"}`}  data-bs-toggle="modal" data-bs-target="#fomoItem" onClick={() => {
						variations.fomo_qty = variations.variation_fomo_qty
						setVariationDetail(variations)
					}}>FOMO</button>
					<div className='d-none'>
						<button className="btn m-1 badge bg-light-purple font-12 d-grid">Item Cost and Repricing</button>
						<button className="btn m-1 badge bg-light-green font-12 d-grid">Strategy</button>
						<button className="btn m-1 badge bg-light-default font-12 d-grid">Competitors</button>
						<button className="btn m-1 badge bg-light-primary font-12 d-grid">Item Source</button>
					</div>
				</td>
				<td>
					<div className="btn-group btn-group-sm me-2" role="group">
						<button type="button" className="btn btn-outline-primary" data-bs-target="#itemDetails" data-bs-toggle="modal" onClick={() => {

							variations.item_title= variations.variation_title;
							variations.price = variations.price;
							variations.item_price = variations.variation_item_price;
							variations.item_qty = variations.variation_qty;
							variations.item_sku = variations.variation_sku;

							setVariationDetail(variations);
							setItemEdit(true);
						}
						}><i className='bi bot-edit-fill text-primary'></i></button>
						<button type="button" className="btn btn-outline-primary dropdown-toggle hideDefaultDrpArrow" data-bs-toggle="dropdown" aria-expanded="false"><i className='bi bi-caret-down-fill text-primary'></i></button>
						<ul className=" item-action dropdown-menu" aria-labelledby="btnGroupDrop1">
							{/*
								<li><a className="dropdown-item text-primary" href="#">Log</a></li>
								<li className="dropdown-divider"></li>
								<li><a className="dropdown-item text-primary" href="#">Reprice Now</a></li>
								<li className="dropdown-divider"></li>
								<li><a className="dropdown-item text-primary" href="#">Problems</a></li>
								<li className="dropdown-divider"></li>
							*/}
							<li><a className="dropdown-item text-primary" href="#" data-bs-toggle="modal" data-bs-target="#delete_item_variation" onClick={() => setVariationDetail(variations)}>Delete</a></li>
						</ul>
					</div>
				</td>
			</tr>
		</>
	}

	return <>
		<section className="section">			
			<div className="row">
				<div className="col-12 col-md-12">
					<h5 className="page-title my-2"><Link to={-1}><i className="bi bot-back"></i></Link> Variation List for {itemId}</h5>
				</div>							
			</div>				
			<div className="row d-none">
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-primary'>Enable Repricing</button>
				</div>
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-danger'>Disable Repricing</button>
				</div>
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-primary'>Restore to available</button>
				</div>
				<div className="col-md-3 mb-2 d-grid">
					<button className='btn btn-outline-danger'>Override to unavailable</button>
				</div>
			</div>
			<div className='row'>
				<div className='col-12 table-responsive mb-2'>
					<table className="table table-striped mb-0">
						<thead>
							<tr>
								<th><input type="checkbox" name="chkAllitem" value="1" className="form-check-input" onChange={handleSelectAll}/></th>
								<th width="35%">Variation Item</th>
								<th className="text-center">Tag</th>
								<th className="text-center">Cost/Price</th>
								<th className="text-center" width="8%">Quantity</th>
								<th className="text-center" width="8%">Stock</th>
								<th width="8%">Actions</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{
							spinner === true ?
							<><tr><td colSpan={8} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
							:
							(spinner === false && (result.hasOwnProperty('list') && result.list.total_records > 0) ) ?
								result.list.data.map((sub, idx) => 
									fillTableRow(sub, idx)
								)
							: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
						}
						</tbody>
					</table>
				</div>
			{
				result.hasOwnProperty('list') && 
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
			}
			</div>
			<div className="row">
				<div className="col-12 col-md-12">
					<span className="text-secondary">* Please note : If checkbox ticked, it will Override parent item settings</span>
				</div>							
			</div>
		</section>

		{/*
			constants.confirmDialog({
				id: "delete_item",
				title : "Delete Item", 
				action : "Delete",
				msg : "Are you sure to delete this item?",
				callback : deleteItem,
				cancelConfirm : deleteModal,
				color : "danger"
			})
		*/}
		<constants.ConfirmDialog 
			id= "delete_item_variation"
			title = "Delete Item" 
			action = "Delete"
			msg = "Are you sure to delete this item?"
			callback = {deleteItemVariation}
			color = "danger"
		/>
		<constants.ItemDetails 
			id="itemDetails"
			isEdit={itemEdit}
			setIsEdit={setItemEdit}
			action={saveEditVariation}
			defaultValue={variation_detail}
			setChanged={setVariationDetail}
		/>

		<constants.FOMO 
			id="fomoItem"
			action={saveVariationFomo}
			defaultValue={variation_detail}
			setChanged={setVariationDetail}
			
		/>

		<constants.AddTag 
			id="userVariationTags"
			action={saveVariationTags}
			defaultValue={variation_tags}
			setChanged={setVariationTags}
			label={variation_detail?.variation_title}
		/> 
	</>
}