import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'


export default function MasterSettings(params){
	
	window.document.title = params.title;

	let navigate = useNavigate();
	
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [button_spinner, setButtonSpinner] = useState(false);

	let refFileLogo = useRef(null);
	let [fileLogo, setfileLogo] = useState(constants.STATIC_CONTENT + 'assets/images/icons_image.png');
	let [txtTitle, settxtTitle] = useState('');
	let [txtAddress, settxtAddress] = useState('');
	let [txtPhone, settxtPhone] = useState('');
	let [switchStripe, setswitchStripe] = useState(false);
	let [txtStripeSecret, settxtStripeSecret] = useState('');
	let [txtStripePublic, settxtStripePublic] = useState('');
	let [switchPaypal, setswitchPaypal] = useState('');
	let [txtPaypalClient, settxtPaypalClient] = useState('');
	let [txtPaypalSecret, settxtPaypalSecret] = useState('');
	let [txtSmtpHost, settxtSmtpHost] = useState('');
	let [txtSmtpUserName, settxtSmtpUserName] = useState('');
	let [txtSmtpPassword, settxtSmtpPassword] = useState('');
	let [txtServerName, settxtServerName] = useState('');
	let [txtPort, settxtPort] = useState('');
	let [txtSenderEmail, settxtSenderEmail] = useState('');
	let [txtSupportEmail, settxtSupportEmail] = useState('');
	let [switchNewSubscription, setswitchNewSubscription] = useState(false);
	let [switchNewTrial, setswitchNewTrial] = useState(false);
	let [switchEndTrial, setswitchEndTrial] = useState(false);
	let [switchSubscriptionCancelled, setswitchSubscriptionCancelled] = useState(false);
	let [switchPaymentFailure, setswitchPaymentFailure] = useState(false);
	let [switchSuccessfulSubscriptionRenewal, setswitchSuccessfulSubscriptionRenewal] = useState(false);
	let [switchUnsuccessfulSubscriptionRenewal, setswitchUnsuccessfulSubscriptionRenewal] = useState(false);
	let [switchAbandonedCart, setswitchAbandonedCart] = useState(false);
	let [txtAutoResponderName, settxtAutoResponderName] = useState('');
	let [txtAutoResponderKey, settxtAutoResponderKey] = useState('');
	
	const onSelectFile = (e) =>{
		if (!e.target.files || e.target.files.length === 0) {
            setfileLogo('')
            return
        }
		
		const objectUrl = URL.createObjectURL(e.target.files[0])
        setfileLogo(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
	}
	const setDefault = (res) => {
		
		settxtTitle(res.preference.site_title);
		settxtAddress(res.preference.site_address);
		settxtPhone(res.preference.support_phone);
		setswitchStripe(res.preference.stripe_is_live == 0 ? false: true);
		settxtStripeSecret(res.preference.stripe_secret_key);
		settxtStripePublic(res.preference.stripe_public_key);
		setswitchPaypal(res.preference.paypal_is_live == 0 ? false: true);
		settxtPaypalClient(res.preference.paypal_client_id);
		settxtPaypalSecret(res.preference.paypal_client_secret);
		settxtSmtpHost(res.preference.email_smtp_host);
		settxtSmtpUserName(res.preference.email_smtp_username);
		settxtSmtpPassword(res.preference.email_smtp_password);
		settxtServerName(res.preference.email_server_name);
		settxtPort(res.preference.email_smtp_port);
		settxtSenderEmail(res.preference.sender_email_id);
		settxtSupportEmail(res.preference.support_email_id);
		setswitchNewSubscription(res.preference.notify_new_subscription == 0 ? false : true);
		setswitchNewTrial(res.preference.notify_new_trial == 0 ? false : true);
		setswitchEndTrial(res.preference.notify_end_of_trial == 0 ? false : true);
		setswitchSubscriptionCancelled(res.preference.notify_subscription_cancelled == 0 ? false : true);
		setswitchPaymentFailure(res.preference.notify_payment_failure == 0 ? false : true);
		setswitchSuccessfulSubscriptionRenewal(res.preference.notify_subscription_renew_successful == 0 ? false : true);
		setswitchUnsuccessfulSubscriptionRenewal(res.preference.notify_subscription_renew_unsuccessful == 0 ? false : true);
		setswitchAbandonedCart(res.preference.notify_abandoned_cart == 0 ? false : true);
		setfileLogo(constants.STATIC_CONTENT + res.preference.site_logo);
		settxtAutoResponderName(res.preference.responder_name);
		settxtAutoResponderKey(res.preference.responder_api_key);
	}
	
	const loadPreference = () => {
		Api('master-settings', constants.prepareData('POST')).then((res) => {
			if(res.status !== 401){
				if(res.hasOwnProperty('preference')){
					setDefault(res)
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	

 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])

	useEffect(() => {
		loadPreference();
	}, [])
	
	const saveMasterSettings = (e) => {
		if($("#form_preference").valid()){
			
			setButtonSpinner(true)
			
			const formData = new FormData();
			//console.log(formData);
			if(refFileLogo.current.files[0] !== undefined){
				formData.append("fileLogo", refFileLogo.current.files[0])
			}
			formData.append("txtTitle", txtTitle)
			formData.append("txtAddress", txtAddress)
			formData.append("txtPhone", txtPhone)
			formData.append("switchStripe", switchStripe)
			formData.append("txtStripeSecret", txtStripeSecret)
			formData.append("txtStripePublic", txtStripePublic)
			formData.append("switchPaypal", switchPaypal)
			formData.append("txtPaypalClient", txtPaypalClient)
			formData.append("txtPaypalSecret", txtPaypalSecret)
			formData.append("txtSmtpHost", txtSmtpHost)
			formData.append("txtSmtpUserName", txtSmtpUserName)
			formData.append("txtSmtpPassword", txtSmtpPassword)
			formData.append("txtServerName", txtServerName)
			formData.append("txtPort", txtPort)
			formData.append("txtSenderEmail", txtSenderEmail)
			formData.append("txtSupportEmail", txtSupportEmail)
			formData.append("switchNewSubscription", switchNewSubscription)
			formData.append("switchNewTrial", switchNewTrial)
			formData.append("switchEndTrial", switchEndTrial)
			formData.append("switchSubscriptionCancelled", switchSubscriptionCancelled)
			formData.append("switchPaymentFailure", switchPaymentFailure)
			formData.append("switchSuccessfulSubscriptionRenewal", switchSuccessfulSubscriptionRenewal)
			formData.append("switchUnsuccessfulSubscriptionRenewal", switchUnsuccessfulSubscriptionRenewal)
			formData.append("switchAbandonedCart", switchAbandonedCart)
			formData.append("txtAutoResponderName", txtAutoResponderName)
			formData.append("txtAutoResponderKey", txtAutoResponderKey)

			Api("master-settings/save", constants.prepareData("POST", formData, true)).then((res) => {
				setButtonSpinner(false);

				if(res.status !== 401){
					if(res.hasOwnProperty('preference')){
						setDefault(res)
						localStorage.removeItem("preferences");
					}
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			})
		}
	}

	$(() => {
		$("#form_preference").validate({
			rules: {
				txtTitle : {
					required: true
				},
				txtAddress : {
					required: true
				},
				txtPhone : {
					required: true
				},
			},
		
			errorElement : 'div',
			errorPlacement: function(error, element) {
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},

			errorClass: "is-invalid",
		})
	});

	return <>
	<section className="section">
		<div className="row mb-3">
			<div className="col-12 col-sm-6 col-md-9 col-lg-10">
				<h5 className="page-title">Admin Master Settings</h5>
			</div>
			<div className="col-12 col-sm-6 col-md-3 col-lg-2">
				<div className="row">
					<div className="col-6 d-grid">
						<button className="btn btn-light" type="button">Cancel</button>
					</div>
					<div className="col-6 d-grid">
						<button className="btn btn-primary" id="btnSave" type="button" onClick={(e) => saveMasterSettings(e)}>
							{
								button_spinner &&
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
							} Save</button>
					</div>
				</div>
			</div>
		</div>
		<div className="row">
			<div className="col-12">
				<ul className="nav nav-tabs text-center" role="tablist">
					<li className="nav-item" role="presentation">
						<a className="nav-link active" id="general-tab" data-bs-toggle="tab" data-bs-target="#general" type="button" role="tab" aria-controls="general" aria-selected="true">General</a>
					</li>
					<li className="nav-item" role="presentation">
						<a className="nav-link" id="payment-gateway-tab" data-bs-toggle="tab" data-bs-target="#payment-gateway" type="button" role="tab" aria-controls="payment-gateway" aria-selected="false">Payment Gateway</a>
					</li>
					<li className="nav-item" role="presentation">
						<a className="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="false">Email</a>
					</li>
					<li className="nav-item" role="presentation">
						<a className="nav-link" id="notification-tab" data-bs-toggle="tab" data-bs-target="#notification" type="button" role="tab" aria-controls="notification" aria-selected="false">Notification</a>
					</li>
					<li className="nav-item" role="presentation">
						<a className="nav-link" id="auto-responder-tab" data-bs-toggle="tab" data-bs-target="#auto-responder" type="button" role="tab" aria-controls="auto-responder" aria-selected="false">Auto Responder</a>
					</li>
				</ul>
				<form autoComplete="off" id="form_preference" method="post" acceptCharset="utf-8">
				<div className="tab-content">
					<div className="tab-pane active" id="general" role="tabpanel" aria-labelledby="general-tab">
						<h5 className="tab-title mx-5 mt-4 mb-4">General</h5>
						<div className="modal-form">
							<div className="row mb-2">
								<label htmlFor="txtTitle" className="col-sm-2 col-form-label">Title: </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtTitle" className="form-control" type="text" name="txtTitle" defaultValue={txtTitle} onChange={(e)=>settxtTitle(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<label className="col-sm-2 col-form-label">Logo: </label>
								<div className="col-12 col-md-4">
									<div className="img-circle form-group" >
										<img src={fileLogo} id="logoPreview" onClick={() => refFileLogo.current.click()}/>
										<button type="button" className="btn float-button select-file" onClick={() => refFileLogo.current.click()}><i className="bi bot-edit text-secondary"></i></button>
									</div>
									<div className="d-none form-group">
									<input type="file" id="fileLogo" ref={refFileLogo} accept="image/*" name="fileLogo" className="form-control" onChange={(e) => onSelectFile(e)}/>
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<label htmlFor="txtAddress" className="col-sm-2 col-form-label">Address: </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtAddress" className="form-control" type="text" name="txtAddress" defaultValue={txtAddress} onChange={(e)=>settxtAddress(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="row mb-2">
								<label htmlFor="txtPhone" className="col-sm-2 col-form-label">Support Phone: </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtPhone" className="form-control" type="text" name="txtPhone" defaultValue={txtPhone} onChange={(e)=>settxtPhone(e.target.value)} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="payment-gateway" role="tabpanel" aria-labelledby="payment-gateway-tab">
						<h5 className="tab-title mx-5 mt-4 mb-4">Stripe</h5>
						<div className="modal-form">
							<div className="row">
								<label htmlFor="switchStripe" className="col-sm-2 col-form-label">Live Mode </label>
								<div className="col-12 col-md-4">
									<div className="form-check form-switch">
									<input className="form-check-input" type="checkbox" name="switchStripe" role="switch" id="switchStripe" checked={(switchStripe) ? "checked" : ''} onChange={(e)=>setswitchStripe(!switchStripe)} />
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtStripeSecret">Secret Key </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtStripeSecret" className="form-control" type="text" name="txtStripeSecret" defaultValue={txtStripeSecret} onChange={(e)=>settxtStripeSecret(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<label className="col-sm-2 col-form-label" htmlFor="txtStripePublic">Public Key </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtStripePublic" className="form-control" type="text" name="txtStripePublic" defaultValue={txtStripePublic} onChange={(e)=>settxtStripePublic(e.target.value)} />
									</div>
								</div>
							</div>
						</div>
						<hr/>
						<h5 className="tab-title mx-5 mt-4 mb-4">Paypal</h5>
						<div className="modal-form">
							<div className="row">
								<label className="col-sm-2 col-form-label" htmlFor="switchPaypal">Live Mode </label>
								<div className="col-12 col-md-4">
									<div className="form-check form-switch">
									<input className="form-check-input" type="checkbox" name="switchPaypal" role="switch" id="switchPaypal"  checked={switchPaypal ? "checked" : ''} onChange={(e)=>setswitchPaypal(!switchPaypal)} />
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtPaypalClient">Client ID </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtPaypalClient" className="form-control" type="text" name="txtPaypalClient" defaultValue={txtPaypalClient} onChange={(e)=>settxtPaypalClient(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<label className="col-sm-2 col-form-label" htmlFor="txtPaypalSecret">Public Key </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtPaypalSecret" className="form-control" type="text" name="txtPaypalSecret" defaultValue={txtPaypalSecret} onChange={(e)=>settxtPaypalSecret(e.target.value)}/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="email" role="tabpanel" aria-labelledby="email-tab">
						<h5 className="tab-title mx-5 mt-4 mb-4">Email</h5>
						<div className="modal-form">
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtSmtpHost">SMTP Host </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtSmtpHost" className="form-control" type="text" name="txtSmtpHost" defaultValue={txtSmtpHost} onChange={(e)=>settxtSmtpHost(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<label className="col-sm-2 col-form-label" htmlFor="txtServerName">ServerName </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtServerName" className="form-control" type="text" name="txtServerName" defaultValue={txtServerName} onChange={(e)=>settxtServerName(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtSmtpUserName">SMTP Username</label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtSmtpUserName" className="form-control" type="text" name="txtSmtpUserName" defaultValue={txtSmtpUserName} onChange={(e)=>settxtSmtpUserName(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtSmtpPassword">SMTP Password</label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtSmtpPassword" className="form-control" type="password" name="txtSmtpPassword" defaultValue={txtSmtpPassword} onChange={(e)=>settxtSmtpPassword(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtPort">Port </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtPort" className="form-control" type="text" name="txtPort" defaultValue={txtPort} onChange={(e)=>settxtPort(e.target.value)} />
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<label className="col-sm-2 col-form-label" htmlFor="txtSenderEmail">Sender Email </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtSenderEmail" className="form-control" type="text" name="txtSenderEmail" defaultValue={txtSenderEmail} onChange={(e)=>settxtSenderEmail(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-2">
								<label className="col-sm-2 col-form-label" htmlFor="txtSupportEmail">Support Email </label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtSupportEmail" className="form-control" type="text" name="txtSupportEmail" defaultValue={txtSupportEmail} onChange={(e)=>settxtSupportEmail(e.target.value)} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="notification" role="tabpanel" aria-labelledby="notification-tab">
						<h5 className="tab-title mx-5 mt-4 mb-4">Notification</h5>
						<div className="modal-form ms-5">
							<div className="row">
								<div className="col-12 col-sm-5 col-md-4">
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchNewSubscription">New Subscription </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchNewSubscription" role="switch" id="switchNewSubscription" checked={switchNewSubscription ? "checked" : ''} onChange={(e)=>setswitchNewSubscription(!switchNewSubscription)} />
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchNewTrial">New Trial </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchNewTrial" role="switch" id="switchNewTrial" checked={switchNewTrial ? "checked" : '' } onChange={(e)=>setswitchNewTrial(!switchNewTrial)}/>
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchEndTrial">End of Trial </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchEndTrial" role="switch" id="switchEndTrial" checked={switchEndTrial ? "checked" : ''} onChange={(e)=>setswitchEndTrial(!switchEndTrial)} />
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchSubscriptionCancelled">Subscription Cancelled </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchSubscriptionCancelled" role="switch" id="switchSubscriptionCancelled" checked={switchSubscriptionCancelled ? "checked" : ''} onChange={(e)=>setswitchSubscriptionCancelled(!switchSubscriptionCancelled)} />
										</div>
									</div>
									</div>
								</div>
								<div className=" col-1">
								</div>
								<div className="col-12 col-sm-5 col-md-4">
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchPaymentFailure">Payment Failure </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchPaymentFailure" role="switch" id="switchPaymentFailure" checked={switchPaymentFailure ? "checked" : ''} onChange={(e)=>setswitchPaymentFailure(!switchPaymentFailure)} />
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchSuccessfulSubscriptionRenewal">Successful Subscription Renewal </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchSuccessfulSubscriptionRenewal" role="switch" id="switchSuccessfulSubscriptionRenewal" checked={switchSuccessfulSubscriptionRenewal ? "checked" :  ''} onChange={(e)=>setswitchSuccessfulSubscriptionRenewal(!switchSuccessfulSubscriptionRenewal)} />
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchUnsuccessfulSubscriptionRenewal">Unsuccessful Subscription Renewal </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchUnsuccessfulSubscriptionRenewal" role="switch" id="switchUnsuccessfulSubscriptionRenewal" checked={switchUnsuccessfulSubscriptionRenewal ? "checked" :  ''} onChange={(e)=>setswitchUnsuccessfulSubscriptionRenewal(!switchUnsuccessfulSubscriptionRenewal)}/>
										</div>
									</div>
									</div>
									<div className="row mt-3">
									<label className="col-8 col-form-label" htmlFor="switchAbandonedCart">Abandoned Cart </label>
									<div className="col-4">
										<div className="form-check form-switch">
											<input className="form-check-input" type="checkbox" name="switchAbandonedCart" role="switch" id="switchAbandonedCart" checked={switchAbandonedCart ? "checked" :  ''} onChange={(e)=>setswitchAbandonedCart(!switchAbandonedCart)}/>
										</div>
									</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="tab-pane" id="auto-responder" role="tabpanel" aria-labelledby="notification-tab">
						<h5 className="tab-title mx-5 mt-4 mb-4">Auto Responder</h5>
						<div className="modal-form">
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtSmtpHost">Name</label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtAutoResponderName" className="form-control" type="text" name="txtAutoResponderName" defaultValue={txtAutoResponderName} onChange={(e)=>settxtAutoResponderName(e.target.value)}/>
									</div>
								</div>
							</div>
							<div className="row mt-3">
								<label className="col-sm-2 col-form-label" htmlFor="txtSmtpHost">API Key</label>
								<div className="col-12 col-md-4">
									<div className="form-group">
									<input id="txtAutoResponderKey" className="form-control" type="text" name="txtAutoResponderKey" defaultValue={txtAutoResponderKey} onChange={(e)=>settxtAutoResponderKey(e.target.value)}/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</form>
			</div>
		</div>
	</section>
	</>
}