import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../assets/js/bootstrap-datepicker.min.js';
import Api from "../Api";
import * as constants from '../Constants'

export default function UserSettings(params){
	window.document.title = params.title;

	let navigate = useNavigate();
	
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [button_spinner, setButtonSpinner] = useState(false);

	let [txtCurrentPassword, settxtCurrentPassword] = useState('');
	let [txtNewPassword, settxtNewPassword] = useState('');
	let [txtConfirmPassword, settxtConfirmPassword] = useState('');

	useEffect(() => {
		if(is_logged_in === false){
			navigate('/signin');
		}
	}, [is_logged_in, navigate])

	const saveUserSettings = (e) => {
		if($("#form_settings").valid()){
			setButtonSpinner(true)
			let data = {
				txtCurrentPassword, txtNewPassword, txtConfirmPassword
			}

			Api("user-settings/save", constants.prepareData("POST", data)).then((res) => {
				setButtonSpinner(false);
				if(res.status !== 401){
					
					settxtCurrentPassword('');
					settxtNewPassword('');
					settxtConfirmPassword('');

				}else{
					localStorage.removeItem('auth');
					sessionStorage.removeItem('current_user');
					setIsLoggedIn(false);
				}
			});
		}
	}

	$(() => {
		$("#form_settings").validate({
			rules: {
				txtCurrentPassword : {
					required : true
				},
				txtNewPassword : {
					required : true
				},
				txtConfirmPassword : {
					required: true,
					equalTo : '#txtNewPassword'
				}
			},
		
			errorElement : 'div',
			errorPlacement: function(error, element) {
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},

			errorClass: "is-invalid",
		})
	});
	
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-12 col-sm-6 col-md-9 col-lg-10">
					<h5 className="page-title">User Settings</h5>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<ul className="nav nav-tabs" role="tablist">
						<li className="nav-item" role="presentation">
							<button className="nav-link active" id="change-password" data-bs-toggle="tab" data-bs-target="#change-password" type="button" role="tab" aria-controls="change-password" aria-selected="true">Change Password</button>
						</li>
					</ul>
					<form autoComplete="off" id="form_settings" method="post" acceptCharset="utf-8">
						<div className="tab-content">
							<div className="tab-pane active" id="change-password" role="tabpanel" aria-labelledby="change-password">
								<h5 className="tab-title mx-5 mt-4 mb-4">Change Password</h5>
								<div className="modal-form">
									<div className="row">
										<div className="col-md-6">
											<div className="mx-5">
												<label htmlFor="txtCurrentPassword" className="form-label">Current Password</label>
												<input type="password" className="form-control " id="txtCurrentPassword" name="txtCurrentPassword" placeholder="Current Password" value={txtCurrentPassword} onChange={(e)=>settxtCurrentPassword(e.target.value)}/>
											</div>
											<div className="mx-5 mt-4">
												<label htmlFor="txtNewPassword" className="form-label">New Password</label>
												<input type="password" className="form-control " id="txtNewPassword" name="txtNewPassword" placeholder="New Password" onChange={(e)=>settxtNewPassword(e.target.value)} value={txtNewPassword} />
											</div>
											<div className="mx-5 mt-4">
												<label htmlFor="txtConfirmPassword" className="form-label">Confirm Password</label>
												<input type="password" className="form-control " id="txtConfirmPassword" name="txtConfirmPassword" placeholder="Confirm Password" onChange={(e)=>settxtConfirmPassword(e.target.value)} value={txtConfirmPassword} />
											</div>
											<div className="mx-5 mt-4">
												<button className="btn btn-primary px-5" id="btnSave" type="button" onClick={(e) => saveUserSettings(e)}>{
													button_spinner && 
													<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
												}Save Changes</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</section>
	</>
	
}